import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, View } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import TertiaryButton from '@24i/nxg-sdk-gluons/src/components/buttons/TertiaryButton';
import getStyles from './styles';

interface Props {
    title: ReactNode;
    actionButton: {
        title?: string | null;
        onPress: () => void;
    };
    actionButtonTestID?: string;
    cancelButton?: {
        title?: string | null;
        onPress: () => void;
    };
    cancelButtonTestID?: string;
    children: React.ReactNode;
}

const StatusModalLayout: FC<Props> = ({
    title,
    actionButton,
    actionButtonTestID,
    cancelButton,
    cancelButtonTestID,
    children,
}) => {
    const { t } = useTranslation();
    const { theme } = useTheme();
    const styles = getStyles(theme);

    return (
        <View style={styles.wrapper}>
            <View style={styles.titleWrapper}>
                <Text style={styles.title}>{title}</Text>
            </View>
            <View style={styles.contentWrapper}>
                <Text style={styles.content}>{children}</Text>
            </View>
            <View style={styles.buttonsWrapper}>
                <ActionButton
                    testID={actionButtonTestID}
                    title={actionButton.title}
                    additionalTextStyles={styles.actionButtonText}
                    additionalContainerStyles={styles.actionButtonContainer}
                    onPress={actionButton.onPress}
                />
                {cancelButton && (
                    <View style={styles.cancelButtonWrapper}>
                        <TertiaryButton
                            testID={cancelButtonTestID}
                            title={cancelButton.title ?? t('common.cancel')}
                            onPress={cancelButton.onPress}
                            additionalTextStyles={styles.cancelButtonText}
                            additionalContainerStyles={styles.cancelButtonContainer}
                        />
                    </View>
                )}
            </View>
        </View>
    );
};

export default StatusModalLayout;
