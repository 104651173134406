import { useState, useCallback, useRef } from 'react';
import { TextInput } from 'react-native';
import { useTranslation } from 'react-i18next';
import { isFactorMobile, isPlatformWeb } from 'renative';
import { Storage } from '@24i/nxg-sdk-quantum';
import { PageSection } from '@24i/nxg-sdk-photon/src';
import { ASYNC_STORAGE_KEY_PREV_SEARCH_KEYS } from '@24i/nxg-core-utils/src/constants';
import { useDebounceFn } from '@24i/nxg-core-utils/src';
import { useContentData } from '@24i/nxg-sdk-smartott-shared/src/context/ContentData';
import { onAnalytics, ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics';
import { updateUrlWithSearchHash } from './utils';
import { SearchScreenProps, UseSharedProps } from '../types';

const useShared = ({
    initialSearchQuery,
    minSearchQuery = 3,
    maxSearchQuery = 30,
    recordError,
    prevSearchesLength = 5,
    customOnChangeText,
    suggestedSearchesLength = 4,
    debounceTime = 250,
    displayPastSearches = true,
    ...props
}: SearchScreenProps): UseSharedProps => {
    const { searchAssets, searchAutoSuggest } = useContentData();
    const { t } = useTranslation(['sott']);
    const [query, setQuery] = useState<string>(initialSearchQuery || '');
    const [items, setItems] = useState<PageSection[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearchBarFocused, setIsSearchBarFocused] = useState(false);
    const [pastSearches, setPastSearches] = useState<string[]>([]);
    const [suggestedQueries, setSuggestedQueries] = useState<string[]>([]);
    const searchBarRef = useRef<TextInput>(null);
    const canSearch = query.trim().length >= minSearchQuery;

    const getPrevSearches = useCallback(async () => {
        if (!displayPastSearches) return [];

        try {
            const value = await Storage.getItem(ASYNC_STORAGE_KEY_PREV_SEARCH_KEYS);
            if (value !== null) {
                setPastSearches([...JSON.parse(value)]);
                return JSON.parse(value);
            }
            return [];
        } catch (err) {
            recordError?.(err);
        }

        return [];
    }, []);

    const getAssets = async (searchQuery: string): Promise<PageSection[]> => {
        const sections = await searchAssets(searchQuery, t);
        onAnalytics(ANALYTICS_TRIGGERS.SEARCH, { searchQuery });
        return sections;
    };

    const updatePrevSearches = async (prevSearches: string[]): Promise<void> => {
        const uniquePrevSearches = new Set(prevSearches);
        try {
            await Storage.setItem(
                ASYNC_STORAGE_KEY_PREV_SEARCH_KEYS,
                JSON.stringify([...uniquePrevSearches])
            );
            setPastSearches([...uniquePrevSearches]);
        } catch (err) {
            recordError?.(err);
        }
    };

    const onDropdownItemRemovePress = useCallback(
        async (dropdownItem) => {
            const prevSearches = pastSearches.filter((pastSearch) => pastSearch !== dropdownItem);
            await updatePrevSearches(prevSearches);
        },
        [pastSearches, setPastSearches]
    );

    const onSearchBarFocus = useCallback(async () => {
        if (!isSearchBarFocused) {
            if (isFactorMobile || isPlatformWeb) {
                await getPrevSearches();
            }
            setIsSearchBarFocused(true);
        }
    }, [isSearchBarFocused, setIsSearchBarFocused, getPrevSearches]);

    const onSearchBarBlur = useCallback(async () => {
        if (isSearchBarFocused) setIsSearchBarFocused(false);

        if (canSearch && (isFactorMobile || isPlatformWeb)) {
            const updatedPastSearches = displayPastSearches ? [query, ...pastSearches] : [];
            updatedPastSearches.length = Math.min(updatedPastSearches.length, prevSearchesLength);

            await updatePrevSearches(updatedPastSearches);
        }
    }, [isSearchBarFocused, setIsSearchBarFocused, query, getPrevSearches]);

    const handleCustomQueryChange = async (enteredQuery, searchString) => {
        try {
            const searchedItems = await customOnChangeText?.(searchString);
            if (enteredQuery === searchString && searchedItems) {
                setItems([...searchedItems]);
            }
        } catch (error) {
            recordError?.(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleWebQueryUpdate = async (enteredQuery: string, searchString: string) => {
        if (searchString && window.location.hash.includes('search=')) {
            // Replace state doesn't create history entry or reloads the page.
            // this is the only way I have found how to update the url search query.
            window.history.replaceState(
                {
                    ...window.history.state,
                    url: updateUrlWithSearchHash(window.history.state.url, searchString),
                    as: updateUrlWithSearchHash(window.history.state.as, searchString),
                },
                '',
                `#search=${searchString}`
            );
        }

        if (enteredQuery.length > 0) {
            const autoSuggest = await searchAutoSuggest(enteredQuery);

            let arraySliced;
            if (autoSuggest.length > suggestedSearchesLength) {
                arraySliced = autoSuggest.slice(0, suggestedSearchesLength);
            } else {
                arraySliced = autoSuggest;
            }

            if (autoSuggest.length > 0) setSuggestedQueries(arraySliced);
        }
    };

    const handleQueryChange = async (enteredQuery = '', searchString = '') => {
        if (isPlatformWeb) handleWebQueryUpdate(enteredQuery, searchString);
        if (enteredQuery && enteredQuery?.trim()?.length >= minSearchQuery) {
            try {
                if (enteredQuery === searchString) {
                    const assets = await getAssets(searchString);
                    setItems([...assets]);
                }
            } catch (error) {
                recordError?.(error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const { run: debounceQueryChange } = useDebounceFn(handleQueryChange, { wait: debounceTime });
    const { run: debounceCustomQueryChange } = useDebounceFn(handleCustomQueryChange, {
        wait: debounceTime,
    });

    const onChangeText = useCallback(
        async (enteredQuery) => {
            setQuery(enteredQuery);

            if (!enteredQuery.length) {
                setItems([]);
                return;
            }

            setIsLoading(true);
            if (typeof customOnChangeText === 'function') {
                debounceCustomQueryChange(enteredQuery, enteredQuery);
            } else {
                debounceQueryChange(enteredQuery, enteredQuery);
            }
        },
        [customOnChangeText]
    );

    const onDropdownItemPress = useCallback(
        (enteredQuery) => {
            onChangeText(enteredQuery);
            if (
                !isPlatformWeb &&
                searchBarRef.current &&
                typeof searchBarRef.current.blur === 'function'
            ) {
                searchBarRef.current.blur();
            }
        },
        [onChangeText]
    );

    return {
        ...props,
        query,
        items,
        isSearchBarFocused,
        pastSearches,
        isLoading,
        canSearch,
        searchBarRef,
        debounceCustomQueryChange,
        debounceQueryChange,
        suggestedQueries,
        minSearchQuery,
        maxSearchQuery,
        prevSearchesLength,
        getAssets,
        onChangeText,
        setQuery,
        setItems,
        setIsLoading,
        onSearchBarBlur,
        onSearchBarFocus,
        onDropdownItemPress,
        onDropdownItemRemovePress,
        getPrevSearches,
        setPastSearches,
        setSuggestedQueries,
        updatePrevSearches,
    };
};

export default useShared;
