import React, { ReactElement } from 'react';
import { Platform } from 'react-native';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { Ratio } from '@24i/nxg-sdk-higgs';
import { View, Icon, Image } from '@24i/nxg-sdk-quarks';
import { IconContentProps } from './types';

const IconContent = (props: IconContentProps): ReactElement | null => {
    const {
        isFocused = false,
        iconContainerStyle = null,
        iconStyle: iconStyleProp = null,
        iconColor = null,
        iconFocusedColor = null,
        isSelected = true,
        iconSize = Ratio(80),
        icon = {
            iconFont: 'materialIcons',
            iconName: null,
            iconImage: null,
            iconStyle: null,
        },
    } = props;

    const { iconFont, iconName, iconImage, iconStyle } = icon;
    const selectedIcon = iconName && isSelected;

    if (iconImage) {
        return (
            <Image
                resizeMode="contain"
                // props defined as they are are not enough predictable to type properly
                // @ts-expect-error
                style={
                    iconStyle || {
                        width: iconSize || (Platform.isTV ? Ratio(40) : 24),
                        height: iconSize || (Platform.isTV ? Ratio(40) : 24),
                    }
                }
                source={typeof iconImage === 'number' ? iconImage : { uri: iconImage }}
            />
        );
    }

    if (selectedIcon) {
        return (
            <View style={iconContainerStyle}>
                <Icon
                    style={iconStyleProp}
                    color={isFocused ? iconFocusedColor : iconColor}
                    size={iconSize || (Platform.isTV ? Ratio(80) : 40)}
                    // @ts-ignore: inconsistency - icon@24i/nxg-sdk-quarks requires string of font
                    // IconButton.icon?.iconFont has string optional
                    font={iconFont}
                    name={iconName || ''}
                />
            </View>
        );
    }

    return null;
};

export default overridable(IconContent, 'IconContent');
