import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Interactable, Text, View } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import IconButton from '@24i/nxg-sdk-gluons/src/components/buttons/IconButton';
import { SINGLE_ROW_TEST_IDS } from '../test-utils';

const Title = ({
    onSeeAllItemPress,
    renderShowAllButton,
    styles,
    title,
    section,
    isGenre,
    textColor,
    getSingleRowTestID,
    rowIndex,
}) => {
    const [hovered, setHovered] = useState(false);
    const titleStyles = styles?.[`${section?.rowId || ''}Title`] || styles.title;
    const { theme } = useTheme();
    const { t } = useTranslation(['sott']);

    return renderShowAllButton ? (
        <Interactable
            activeOpacity={1}
            onPress={() => onSeeAllItemPress?.(section)}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            style={styles.titleContainer}
        >
            <Text
                style={
                    isGenre
                        ? styles.genreTitle
                        : [titleStyles, { color: textColor || theme.color.textPrimary }]
                }
                testID={getSingleRowTestID(SINGLE_ROW_TEST_IDS.TITLE, {
                    scopeIndex: rowIndex,
                })}
            >
                {title}
            </Text>
            <View>
                <IconButton
                    testID={getSingleRowTestID(SINGLE_ROW_TEST_IDS.SHOW_ALL_BUTTON)}
                    icon={{
                        iconFont: theme.icons.epg.chevronRight?.iconFont,
                        iconName: theme.icons.epg.chevronRight.iconName,
                    }}
                    onPress={() => onSeeAllItemPress?.(section)}
                    color={theme.color.textPrimary}
                    iconSize={22}
                    title={t('asset.showAll')}
                    additionalTitleStyles={styles.seeAllButtonTitle}
                    additionalContainerStyles={[
                        styles.seeAllButtonContainer,
                        hovered && styles.seeAllHovered,
                    ]}
                />
            </View>
        </Interactable>
    ) : (
        <View
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            style={styles.titleContainer}
        >
            <Text
                style={
                    isGenre
                        ? styles.genreTitle
                        : [titleStyles, { color: textColor || theme.color.textPrimary }]
                }
                testID={getSingleRowTestID(SINGLE_ROW_TEST_IDS.TITLE, {
                    scopeIndex: rowIndex,
                })}
            >
                {title}
            </Text>
        </View>
    );
};

export default Title;
