import React from 'react';
import { Icon, Text, View } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import Interactable from '@24i/nxg-sdk-quarks/src/components/Interactable';
import TertiaryButtonWithoutBg from '@24i/nxg-sdk-gluons/src/components/buttons/TertiaryButtonWithoutBg';
import { Platform } from '@24i/nxg-sdk-quantum';
import { useAppConfigQuery } from '@24i/nxg-sdk-smartott/src/hooks/query/useAppConfigQuery';
import getStyles from './styles';
import { PurchasableItemViewProps } from '../types';

const PurchasableItem = ({
    testID,
    onPress,
    title,
    description,
    tagText,
    itemInfoLines,
    showBorder,
    hasTransparentBackground,
    textAfterDescription,
    secondaryButtonText = 'secondary button',
    secondaryButtonTestID,
    onSecondaryButtonPress,
    wasCancelled,
}: PurchasableItemViewProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme, { showBorder, hasTransparentBackground });
    const isClickable = !!onPress;
    const { appSettings } = useAppConfigQuery();

    const Wrapper = isClickable ? Interactable : View;

    const inAppPurchases = appSettings?.features.inAppPurchases || {
        enabled: true,
    };
    const arePurchasesEnabled =
        inAppPurchases.enabled && !inAppPurchases.disabledPlatforms?.includes(Platform.OS);

    return (
        <Wrapper testID={testID} style={styles.wrapper} onPress={onPress}>
            <View style={styles.contentWrapper}>
                {!!tagText && (
                    <View style={styles.space}>
                        <View style={styles.tagWrapper}>
                            <Text style={styles.tag}>{tagText}</Text>
                        </View>
                    </View>
                )}
                <View style={styles.header}>
                    <Text style={styles.name}>{title}</Text>
                    {!wasCancelled && arePurchasesEnabled && onSecondaryButtonPress && (
                        <View>
                            <TertiaryButtonWithoutBg
                                testID={secondaryButtonTestID}
                                styles={() => ({
                                    text: styles.changeButtonText,
                                })}
                                title={secondaryButtonText}
                                onPress={onSecondaryButtonPress}
                            />
                        </View>
                    )}
                </View>
                <View style={styles.space}>
                    {itemInfoLines.map((line) => (
                        <Text key={line} style={styles.price}>
                            {line}
                        </Text>
                    ))}
                </View>
                <Text style={styles.description}>{description}</Text>
                {!!textAfterDescription && (
                    <View style={styles.nextBillingDateWrapper}>
                        <Text style={styles.nextBillingDate}>{textAfterDescription}</Text>
                    </View>
                )}
            </View>
            {isClickable && (
                <View style={styles.iconWrapper}>
                    <Icon
                        color={theme.color.textPrimary}
                        font="fontAwesome"
                        name="angle-right"
                        size={20}
                    />
                </View>
            )}
        </Wrapper>
    );
};

export default PurchasableItem;
