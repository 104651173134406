import { useRef } from 'react';

import { PlaybackEvent } from '@24i/nxg-sdk-photon/src/analytics/player';
import { onAnalytics, ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics';

import { CommonPlayerEventParams, useCommonPlayerEventParams } from '../useCommonPlayerEventParams';
import { usePlayerSessionId } from '../usePlayerSessionId';

export interface AdLoadedPlayerEventParams {
    adName: string;
    adPosition: number;
    adServerUrl?: string;
}

const eventType = 'AD_LOADED';

export const useTriggerOnAdLoaded = (params: CommonPlayerEventParams) => {
    const event = useCommonPlayerEventParams(params);
    const { setEvent } = usePlayerSessionId();
    const eventRef = useRef<Omit<PlaybackEvent, 'type'> | undefined>();

    if (event) {
        eventRef.current = event;
    }

    const trigger: (params: AdLoadedPlayerEventParams) => void = ({ adName, adPosition }) => {
        if (!eventRef.current) {
            return;
        }

        const eventWithData = {
            ...eventRef.current,
            adName,
            adPosition,
        };

        setEvent(eventWithData);
        onAnalytics(ANALYTICS_TRIGGERS[eventType], eventWithData);
    };

    return trigger;
};
