import { getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import type { TextLinkStylesGetter } from './types';

const getTextLinkStyles: TextLinkStylesGetter = (theme: Theme) => ({
    item: {
        paddingBottom: 15,
        alignSelf: 'flex-start',
        width: '25%',
        textAlign: 'left',
        wordBreak: 'break-word',
        '@media (max-width: 440px)': {
            textAlign: 'center',
            width: '100%',
        },
        '@media (min-width: 441px)': { paddingRight: 15 },
        '@media (min-width: 441px) and (max-width: 640px)': { width: '50%' },
        '@media (min-width: 641px) and (max-width: 864px)': { width: '33%' },
    },
    title: {
        ...getFont(theme, 'h5'),
        color: `${theme.color.menuItemActive}80`,
        ':hover': { color: theme.color.menuItemActive },
        transition: 'background-color 0.15s ease-out',
    },
});

export default getTextLinkStyles;
