import { getBorderRadius, getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { Breakpoint } from '@24i/nxg-sdk-smartott/src/utils/styles/constants';
import { ViewStyle } from 'react-native';
import { PackshotStylesWeb } from './types';

const Packshot = <TTheme extends Theme>(theme: TTheme): PackshotStylesWeb => ({
    labelContainer: {
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
    },
    label: {
        fontWeight: 'bold',
        color: theme.color.textPrimary,
        ...getFont(theme, 'h4'),
    },
    arrows: {
        paddingLeft: 8,
        marginTop: 2, // small offset to have better alignment
        height: getFont(theme, 'h4').fontSize + 20,
        width: getFont(theme, 'h4').fontSize + 20,
    },
    item: {
        backgroundColor: 'transparent',
        width: '100%',
    },
    itemImage: {
        width: '100%',
        height: '100%',
        borderRadius: 6,
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
        borderWidth: 0,
        boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.2)',
        top: 0,
        left: 0,
        position: 'absolute',
    },
    landscapeImageContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        height: 0,
        paddingTop: '56.25%',
        borderRadius: 6,
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
    },
    posterImageContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        height: 0,
        paddingTop: '150%',
        borderRadius: 6,
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
    },
    genreContainer: {
        marginBottom: 15,
        backgroundColor: theme.color.darker1,
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
    },
    gridContainer: {},
    // Packshot's component - PackshotTitle
    assetLabelContainer: {
        left: 8,
    },
    packshotHoverLandscape: {
        transition: '0.1s ease-in',
        ':hover': {
            marginTop: 'calc(-1.3%)',
            marginBottom: 'calc(1.3%)',
            transform: 'scale(1.05)',
        },
    } as ViewStyle,
    packshotHoverLandscapeHighlighted: {
        transition: '0.1s ease-in',
        ':hover': {
            marginTop: 'calc(-1%)',
            marginBottom: 'calc(1%)',
            transform: 'scale(1.04)',
        },
    } as ViewStyle,
    packshotHoverPortrait: {
        transition: '0.1s ease-in',
        ':hover': {
            marginTop: 'calc(-3.5%)',
            marginBottom: 'calc(3.5%)',
            transform: 'scale(1.05)',
        },
    } as ViewStyle,
    packshotHoverPortraitHighlighted: {
        transition: '0.1s ease-in',
        ':hover': {
            marginTop: 'calc(-3.5%)',
            marginBottom: 'calc(3.5%)',
            transform: 'scale(1.05)',
        },
    } as ViewStyle,
    obscuredImageContainer: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconStyle: {
        [`@media (max-width: ${Breakpoint.MD}px)`]: {
            fontSize: 23,
        },
        [`@media (min-width: ${Breakpoint.MD}px) and (max-width: ${Breakpoint.L}px)`]: {
            fontSize: 33,
        },
        [`@media (min-width: ${Breakpoint.L + 1}px)`]: {
            fontSize: 45,
        },
    },
    obscuredChannelContainer: { position: 'absolute', bottom: 18, right: '5%' },
    geoblockIconContainer: {
        position: 'absolute',
        top: 0,
        right: 8,
        backgroundColor: theme.color.labelPrimary,
        height: 'auto',
        width: 'auto',
        padding: 2,
        borderRadius: 4,
    },
    topContentContainer: {
        position: 'absolute',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        top: 8,
        width: '100%',
        flexDirection: 'row',
    },
});

export type PackshotStyles = ReturnType<typeof Packshot>;

export default Packshot;
