import { ReactNode } from 'react';

interface HTMLStyleElementProps extends HTMLStyleElement {
    styleSheet?: { cssText: any };
}

const fonts = {};
const registerIconFont = (name: string, component: ReactNode, ttf: string, fontFamily: string) => {
    fonts[name] = { name, component, ttf, fontFamily };
    const iconFontStyles = `@font-face {
        src: url(${ttf});
        font-family: ${fontFamily};
    }`;
    const id = `${name}FontFace`;
    if (!document.getElementById(id)) {
        const fontStyle: HTMLStyleElementProps = document.createElement('style');
        fontStyle.type = 'text/css';
        fontStyle.id = id;
        if (fontStyle.styleSheet) {
            fontStyle.styleSheet.cssText = iconFontStyles;
        } else {
            fontStyle.appendChild(document.createTextNode(iconFontStyles));
        }
        document.head.appendChild(fontStyle);
    }
};

export { fonts, registerIconFont };
