import React from 'react';

const SeeAllSVG = () => {
    return (
        <div>
            <svg width="41" height="41" viewBox="0 0 41 41" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd" opacity=".6">
                    <path opacity=".87" d="M.125.373h40v40h-40z" />
                    <path
                        d="m27.28 13.89 6.483 6.483-6.484 6.484c-.65.65-.65 1.7 0 2.35.65.65 1.7.65 2.35 0l7.65-7.65c.65-.65.65-1.7 0-2.35l-7.65-7.667a1.66 1.66 0 0 0-2.35 0c-.633.65-.633 1.717 0 2.35z"
                        fill="#FFF"
                    />
                    <path
                        d="m15.613 13.89 6.483 6.483-6.483 6.484c-.65.65-.65 1.7 0 2.35.65.65 1.7.65 2.35 0l7.65-7.65c.65-.65.65-1.7 0-2.35l-7.65-7.667a1.66 1.66 0 0 0-2.35 0c-.634.65-.634 1.717 0 2.35z"
                        opacity=".75"
                        fill="#FFF"
                    />
                    <path
                        d="m3.946 13.89 6.483 6.483-6.483 6.484c-.65.65-.65 1.7 0 2.35.65.65 1.7.65 2.35 0l7.65-7.65c.65-.65.65-1.7 0-2.35l-7.65-7.667a1.66 1.66 0 0 0-2.35 0c-.634.65-.634 1.717 0 2.35z"
                        opacity=".5"
                        fill="#FFF"
                    />
                </g>
            </svg>
        </div>
    );
};

export default SeeAllSVG;
