import { formatGenres } from '../../../../screens/DetailsScreen/viewModel/utils';
import { ExtendedAsset } from '../../../../navigation/types';

export const getSliderSubtitle = ({
    asset,
    isObscured,
    isPodcastSeriesOrEpisode,
    obscuredLocalizedMessage,
}: {
    asset: ExtendedAsset;
    isObscured: boolean;
    isPodcastSeriesOrEpisode: boolean;
    obscuredLocalizedMessage: string;
}) => {
    switch (true) {
        case isObscured:
            return obscuredLocalizedMessage;

        case isPodcastSeriesOrEpisode:
            return formatGenres(asset);

        default:
            return asset.subtitle || asset.description || '';
    }
};
