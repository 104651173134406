import { useTranslation } from 'react-i18next';
import { PLAYLIST_TYPE } from '@24i/nxg-sdk-photon';
import usePlaylistAssetsQuery from '@24i/nxg-sdk-smartott/src/hooks/query/purchases/usePlaylistAssetsQuery';
import useAssetActions from '@24i/nxg-sdk-smartott/src/hooks/useAssetActions';
import { UseSharedProps } from '../types';
import { getLabelForPlaylist } from '../../../utils/index';

const useShared = (props, playlistId: PLAYLIST_TYPE): UseSharedProps => {
    const { t } = useTranslation();
    const { isLoading, data } = usePlaylistAssetsQuery(playlistId);
    const { handlePackshotPress } = useAssetActions();

    return {
        assets: data,
        title: getLabelForPlaylist(playlistId, t),
        isLoading,
        handlePackshotPress,
        ...props,
    };
};

export default useShared;
