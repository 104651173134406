import React, { ReactElement } from 'react';
import { Asset, PageSection, Theme } from '@24i/nxg-sdk-photon';
import { TextStyleWeb, ViewStyleWeb } from '@24i/nxg-sdk-smartott/src/utils/styles/types';
import { EventType } from '@24i/nxg-sdk-gluons';
import { TextInput, TextStyle, ViewStyle } from 'react-native';
import { ScreenProps } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';

export type OnItemPress = <T>(item: Asset, section?: T, e?: EventType) => void;

export interface SearchScreenStyles {
    app: ViewStyle;
    holder: ViewStyle;
    inputWrap: ViewStyle;
    activityIndicator?: ViewStyle;
    loadingWrapper?: ViewStyle;
    searchIcon?: ViewStyleWeb;
    clearIcon?: ViewStyleWeb;
    textInput?: ViewStyleWeb;
    dropdownContainer?: ViewStyle;
    inputStyle?: TextStyleWeb;
    logoHolder: ViewStyle;
    logoImage: ViewStyle;
    suggestedSearchContainer?: ViewStyle;
    topContainer?: ViewStyle;
    subheaderText?: TextStyle;
    suggestedSearchText?: TextStyle;
    verticalBarAfterSuggestedItem?: TextStyle;
    indicator?: ViewStyle;
    searchWrapper?: ViewStyle;
    headerContainer?: ViewStyle;
    headerText?: TextStyle;
    searchContentContainer?: ViewStyle;
}

export interface SearchScreenProps extends ScreenProps {
    styles?: (theme: Theme) => SearchScreenStyles;
    initialSearchQuery?: string;
    minSearchQuery?: number;
    maxSearchQuery?: number;
    suggestedSearchesLength?: number;
    debounceTime?: number;
    displayPastSearches?: boolean;
    prevSearchesLength?: number;
    disableDefaultHeaderBehaviour?: boolean;
    overrideInitialDataLayout?: null | (() => ReactElement);
    closeModal?: () => void;
    customOnChangeText?: (searchString: string) => Promise<PageSection[]>;
    recordError?: (error: unknown) => void;
}

export interface UseSharedProps extends SearchScreenProps {
    query: string;
    items: PageSection[];
    pastSearches: string[];
    isSearchBarFocused: boolean;
    isLoading: boolean;
    canSearch: boolean;
    autoFocus?: boolean;
    minSearchQuery?: number;
    maxSearchQuery?: number;
    suggestedQueries: string[];
    searchBarRef: React.RefObject<TextInput>;
    updatePrevSearches: (prevSearches: string[]) => Promise<void>;
    getAssets: (searchQuery: string) => Promise<PageSection[]>;
    setPastSearches?: (query: string[]) => void;
    onDropdownItemPress: (enteredQuery: string) => void;
    onDropdownItemRemovePress: (enteredQuery: string) => void;
    setIsLoading: (loading: boolean) => void;
    onSearchBarBlur: () => void;
    onSearchBarFocus: () => void;
    onChangeText: (text: string) => void;
    debounceCustomQueryChange: (enteredQuery?: string, searchString?: string) => void;
    debounceQueryChange: (enteredQuery?: string, searchString?: string) => void;
    getPrevSearches: () => Promise<string[]>;
    setSuggestedQueries: (queries: string[]) => void;
    setQuery: (query: string) => void;
    setItems: (items: PageSection[]) => void;
}

export interface SearchViewProps extends SearchScreenProps, UseSharedProps {
    initialData?: PageSection[];
    gridItemWidth?: number;
    fixedGrid?: boolean;
    searchBarWrapperRef?: React.MutableRefObject<null>;
    onSubmitEditing?: () => void;
    onCancelPress?: () => void;
}

export enum SEARCH_TEST_IDS {
    SEARCH_SUGGESTED_CONTAINER = 'search_suggested_container',
    SEARCH_SUGGESTED_HEADER_TEXT = 'search_suggested_header_text',
    SEARCH_SUGGESTED_QUERY = 'search_suggested_query',
    SEARCH_SUGGESTED_QUERY_TEXT = 'search_suggested_query_text',
    SEARCH_CONTENT_CONTAINER = 'search_content_container',
}
