import React from 'react';
import { View, Text } from 'react-native';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Icon } from '@24i/nxg-sdk-quarks';
import { AssetLabelProps } from './types';
import getAssetLabelStyles from './styles';

const AssetLabel = (props: AssetLabelProps) => {
    const { text, iconFont, iconName, testID } = props;
    const { theme } = useTheme();
    const styles = getAssetLabelStyles(theme, props);

    return (
        <View style={styles.container} testID={testID}>
            {!!iconName && !!iconFont && (
                <Icon
                    color={styles.text.color}
                    style={styles}
                    size={styles.text.fontSize || 20}
                    font={iconFont}
                    name={iconName}
                />
            )}
            {!!text && (
                <Text numberOfLines={1} style={styles.text}>
                    {text.replace(/^(.{16}).{1,}/, '$1…')}
                </Text>
            )}
        </View>
    );
};

export default AssetLabel;
