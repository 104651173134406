import { Theme } from '@24i/nxg-sdk-photon';
import { ViewStyle } from 'react-native';
import { ListItemStyle } from './types';

const getListItemStyles = (theme: Theme): ListItemStyle => ({
    itemContainer: {
        borderColor: 'transparent',
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden',
        color: `${theme.color.menuItemInactive}99`,
        '@media (max-width: 800px)': {
            marginTop: 4,
            marginBottom: 4,
            flexDirection: 'column',
            marginRight: 0,
        },
        ':hover': {
            color: `${theme.color.menuItemActive}`,
        },
    } as ViewStyle,
    menuItemHover: {
        ':hover': {
            color: `${theme.color.menuItemActive}`,
            backgroundColor: `${theme.color.primary4}`,
        },
        transition: 'background-color 0.2s ease-in',
    } as ViewStyle,
    titleContainer: {
        flex: 1,
        alignItems: 'center',
        alignContent: 'center',
        flexDirection: 'row',
        paddingHorizontal: 16,
    } as ViewStyle,
    lineDivider: {
        width: '100%',
        height: 1,
        backgroundColor: theme.color.primary2,
        marginVertical: 8,
    } as ViewStyle,
});

export { getListItemStyles };
