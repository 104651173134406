import React from 'react';
import { Linking } from 'react-native';
import { View, Text } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import Modal from '@24i/nxg-sdk-gluons/src/components/containers/Modal';
import ParsedText from 'react-native-parsed-text';
import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import SubactionButton from '@24i/nxg-sdk-gluons/src/components/buttons/SubactionButton';
import TertiaryButtonWithoutBg from '@24i/nxg-sdk-gluons/src/components/buttons/TertiaryButtonWithoutBg';
import { useDimensions } from '@24i/nxg-sdk-quantum';
import { Breakpoint } from '@24i/nxg-sdk-smartott/src/utils/styles/constants';
import { GenericModalProps } from './types';
import getGenericModalStyles from './styles';

const GenericModal = ({
    requiresModalWrapper,
    hasUpperTitle,
    isVisible,
    closeModal,
    title,
    description,
    primaryButton,
    secondaryButton,
    tertiaryButton,
    children,
    customContainerStyle,
    defaultHorizontalButtonLayout,
}: GenericModalProps) => {
    const { theme } = useTheme();

    const { isLandscape } = useDimensions();
    const styles = getGenericModalStyles(theme, isLandscape);

    const onLinkPress = (url: string) => {
        Linking.openURL(url);
    };

    const defaultModalLayout = (): JSX.Element => (
        <View
            style={{
                flexDirection: defaultHorizontalButtonLayout ? 'row-reverse' : undefined,
                [`@media (max-width: ${Breakpoint.MD}px)`]: {
                    alignSelf: 'center',
                },
            }}
        >
            {primaryButton && (
                <ActionButton
                    testID="primary-button"
                    additionalContainerStyles={[
                        styles.primaryButton,
                        primaryButton.additionalContainerStyles,
                    ]}
                    {...primaryButton}
                />
            )}
            {secondaryButton && (
                <SubactionButton
                    testID="secondary-button"
                    additionalContainerStyles={[
                        styles.secondaryButton,
                        secondaryButton.additionalContainerStyles,
                    ]}
                    {...secondaryButton}
                />
            )}
            {tertiaryButton && (
                <TertiaryButtonWithoutBg
                    testID="tertiary-button"
                    additionalContainerStyles={[
                        styles.tertiaryButton,
                        tertiaryButton.additionalContainerStyles,
                    ]}
                    {...tertiaryButton}
                />
            )}
        </View>
    );

    const content = (): JSX.Element => (
        <View style={styles.contentContainer}>
            <View style={{ ...styles.webContentContainer, ...customContainerStyle }}>
                {!hasUpperTitle && title && (
                    <Text
                        testID={title.testID}
                        style={{ ...styles.titleText, ...title.customStyle }}
                    >
                        {title.text}
                    </Text>
                )}
                {description && (
                    <ParsedText
                        testID={description.testID}
                        style={{ ...styles.descriptionText, ...description.customStyle }}
                        parse={[{ type: 'url', style: styles.linkText, onPress: onLinkPress }]}
                    >
                        {description.text}
                    </ParsedText>
                )}
                {children || defaultModalLayout()}
            </View>
        </View>
    );

    return (
        <>
            {requiresModalWrapper ? (
                <Modal
                    visible={isVisible}
                    onRequestClose={closeModal}
                    modalTitle={hasUpperTitle ? title?.text : undefined}
                >
                    {content()}
                </Modal>
            ) : (
                content()
            )}
        </>
    );
};

export default GenericModal;
