import { createGuard } from '@24i/nxg-core-utils/src/guards';
import { ElementsGuard, FontsGuard, IconsGuard } from '@24i/nxg-sdk-photon';
import * as t from 'io-ts';

const ColorGuard = createGuard('Color', undefined, {
    color: t.string,
    type: t.string,
    blur: t.number,
});

export const ThemeIconGuard = createGuard('Icon', {
    height: t.number,
    width: t.number,
    svg: t.array(
        createGuard('Svg', {
            density: t.number,
            path: t.string,
        })
    ),
});

export const ThemeIconsGuard = createGuard('ThemeIcons', undefined, {
    'onButtonPrimary-locked': ThemeIconGuard,
    'onButtonPrimary-play': ThemeIconGuard,
    'onButtonPrimary-purchase': ThemeIconGuard,
    'onButtonPrimary-purchase_multipleOptions': ThemeIconGuard,
    'onButtonPrimary-record': ThemeIconGuard,
    'onButtonPrimary-recordMultiple': ThemeIconGuard,
    'onButtonPrimary-recordMultiple_cancel': ThemeIconGuard,
    'onButtonPrimary-record_cancel': ThemeIconGuard,
    'onButtonPrimary-rent': ThemeIconGuard,
    'onButtonPrimary-upsell': ThemeIconGuard,
    'onButtonSecondary-download': ThemeIconGuard,
    'onButtonSecondary-info': ThemeIconGuard,
    'onButtonSecondary-record': ThemeIconGuard,
    'onButtonSecondary-recordMultiple': ThemeIconGuard,
    'onButtonSecondary-recordMultiple_cancel': ThemeIconGuard,
    'onButtonSecondary-record_cancel': ThemeIconGuard,
    'onButtonSecondary-reminder_add': ThemeIconGuard,
    'onButtonSecondary-reminder_added': ThemeIconGuard,
    'onButtonSecondary-share': ThemeIconGuard,
    'onButtonSecondary-startOver': ThemeIconGuard,
    'onButtonSecondary-watchlist_add': ThemeIconGuard,
    'onButtonSecondary-watchlist_added': ThemeIconGuard,
    'onButtonTertiary-download': ThemeIconGuard,
    'onButtonTertiary-info': ThemeIconGuard,
    'onButtonTertiary-record': ThemeIconGuard,
    'onButtonTertiary-recordMultiple': ThemeIconGuard,
    'onButtonTertiary-recordMultiple_cancel': ThemeIconGuard,
    'onButtonTertiary-record_cancel': ThemeIconGuard,
    'onButtonTertiary-reminder_add': ThemeIconGuard,
    'onButtonTertiary-reminder_added': ThemeIconGuard,
    'onButtonTertiary-share': ThemeIconGuard,
    'onButtonTertiary-startOver': ThemeIconGuard,
    'onButtonTertiary-watchlist_add': ThemeIconGuard,
    'onButtonTertiary-watchlist_added': ThemeIconGuard,
    'onDark-airplay': ThemeIconGuard,
    'onDark-allChannels': ThemeIconGuard,
    'onDark-allEpisodes': ThemeIconGuard,
    'onDark-back': ThemeIconGuard,
    'onDark-backToLive': ThemeIconGuard,
    'onDark-back_rtl': ThemeIconGuard,
    'onDark-catchup': ThemeIconGuard,
    'onDark-chromecast': ThemeIconGuard,
    'onDark-chromecast_connected': ThemeIconGuard,
    'onDark-close': ThemeIconGuard,
    'onDark-confirm': ThemeIconGuard,
    'onDark-control_fastForward': ThemeIconGuard,
    'onDark-control_forward10': ThemeIconGuard,
    'onDark-control_info': ThemeIconGuard,
    'onDark-control_pause': ThemeIconGuard,
    'onDark-control_play': ThemeIconGuard,
    'onDark-control_replay10': ThemeIconGuard,
    'onDark-control_rewind': ThemeIconGuard,
    'onDark-control_skipNext': ThemeIconGuard,
    'onDark-control_skipPrevious': ThemeIconGuard,
    'onDark-control_stop': ThemeIconGuard,
    'onDark-control_upsell': ThemeIconGuard,
    'onDark-control_volume_down': ThemeIconGuard,
    'onDark-control_volume_mute': ThemeIconGuard,
    'onDark-control_volume_off': ThemeIconGuard,
    'onDark-control_volume_up': ThemeIconGuard,
    'onDark-filter': ThemeIconGuard,
    'onDark-fullscreen': ThemeIconGuard,
    'onDark-fullscreen_exit': ThemeIconGuard,
    'onDark-overflow': ThemeIconGuard,
    'onDark-pictureInPicture': ThemeIconGuard,
    'onDark-rating': ThemeIconGuard,
    'onDark-record': ThemeIconGuard,
    'onDark-recordMultiple': ThemeIconGuard,
    'onDark-recordMultiple_cancel': ThemeIconGuard,
    'onDark-record_cancel': ThemeIconGuard,
    'onDark-select': ThemeIconGuard,
    'onDark-settings': ThemeIconGuard,
    'onDark-share': ThemeIconGuard,
    'onDark-subtitles': ThemeIconGuard,
    'onDark-tvGuide': ThemeIconGuard,
    'onLabel-catchup': ThemeIconGuard,
    'onLabel-locked': ThemeIconGuard,
    'onLabel-record': ThemeIconGuard,
    'onLabel-recordMultiple': ThemeIconGuard,
    'onLabel-reminder': ThemeIconGuard,
    'onPrimary-airplay': ThemeIconGuard,
    'onPrimary-back': ThemeIconGuard,
    'onPrimary-back_rtl': ThemeIconGuard,
    'onPrimary-chromecast': ThemeIconGuard,
    'onPrimary-chromecast_connected': ThemeIconGuard,
    'onPrimary-clear': ThemeIconGuard,
    'onPrimary-close': ThemeIconGuard,
    'onPrimary-confirm': ThemeIconGuard,
    'onPrimary-delete': ThemeIconGuard,
    'onPrimary-device_family': ThemeIconGuard,
    'onPrimary-device_gameConsole': ThemeIconGuard,
    'onPrimary-device_laptop': ThemeIconGuard,
    'onPrimary-device_smartphone': ThemeIconGuard,
    'onPrimary-device_smartwatch': ThemeIconGuard,
    'onPrimary-device_tablet': ThemeIconGuard,
    'onPrimary-device_tv': ThemeIconGuard,
    'onPrimary-error': ThemeIconGuard,
    'onPrimary-expiration': ThemeIconGuard,
    'onPrimary-field_clear': ThemeIconGuard,
    'onPrimary-field_search': ThemeIconGuard,
    'onPrimary-filter': ThemeIconGuard,
    'onPrimary-overflow': ThemeIconGuard,
    'onPrimary-rating': ThemeIconGuard,
    'onPrimary-scroll_down': ThemeIconGuard,
    'onPrimary-scroll_up': ThemeIconGuard,
    'onPrimary-seeAll': ThemeIconGuard,
    'onPrimary-select': ThemeIconGuard,
    'onPrimary-share': ThemeIconGuard,
    'onPrimary-signOut': ThemeIconGuard,
    'onPrimary-spinner': ThemeIconGuard,
    'onPrimary-subpage': ThemeIconGuard,
    'onPrimary-subpage_rtl': ThemeIconGuard,
    'onTileMainButton-edit': ThemeIconGuard,
    'onTileMainButton-play': ThemeIconGuard,
});

const ColorsGuard = createGuard('Colors', undefined, {
    background1: ColorGuard,
    'progressBar-background': ColorGuard,
    primary1: ColorGuard,
    primaryBlur1: ColorGuard,
    primaryBlur1Fallback: ColorGuard,
    primaryBlur2: ColorGuard,
    primaryBlur2Fallback: ColorGuard,
    primary2: ColorGuard,
    primary3: ColorGuard,
    primary4: ColorGuard,
    lighter1: ColorGuard,
    lighterBlur1: ColorGuard,
    lighterBlur1Fallback: ColorGuard,
    lighterBlur2: ColorGuard,
    lighterBlur2Fallback: ColorGuard,
    lighter2: ColorGuard,
    lighter3: ColorGuard,
    lighter4: ColorGuard,
    darker1: ColorGuard,
    darkerBlur1: ColorGuard,
    darkerBlur1Fallback: ColorGuard,
    darkerBlur2: ColorGuard,
    darkerBlur2Fallback: ColorGuard,
    darker2: ColorGuard,
    darker3: ColorGuard,
    darker4: ColorGuard,
    contrast1: ColorGuard,
    contrastBlur1: ColorGuard,
    contrastBlur1Fallback: ColorGuard,
    contrastBlur2: ColorGuard,
    contrastBlur2Fallback: ColorGuard,
    contrast2: ColorGuard,
    contrast3: ColorGuard,
    contrast4: ColorGuard,
    contrast5: ColorGuard,
    gradient1: ColorGuard,
    gradient2: ColorGuard,
    gradient3: ColorGuard,
    error: ColorGuard,
    warning: ColorGuard,
    success: ColorGuard,
    textPrimary: ColorGuard,
    textSecondary: ColorGuard,
    textButtonPrimary: ColorGuard,
    textButtonSecondary: ColorGuard,
    textButtonTertiary: ColorGuard,
    'textMenuItem-active': ColorGuard,
    'textMenuItem-inactive': ColorGuard,
    buttonPrimary: ColorGuard,
    buttonSecondary: ColorGuard,
    buttonTertiary: ColorGuard,
    labelPrimary: ColorGuard,
    labelSecondary: ColorGuard,
    'progressBar-accent': ColorGuard,
    'progressBar-live': ColorGuard,
    menuFocus: ColorGuard,
    'epgItem-active': ColorGuard,
    'epgItem-inactive': ColorGuard,
    tileMainButton: ColorGuard,
    statusBarTheme: t.string,
});

export const MergedThemeGuard = createGuard('MergedTheme', {
    id: t.string,
    name: t.string,
    colors: ColorsGuard,
    fonts: FontsGuard,
    icons: IconsGuard,
    elements: ElementsGuard,
});

export const ThemeResponseItemGuard = createGuard('ThemeResponseItem', {
    id: t.string,
    name: t.string,
    colors: ColorsGuard,
    fonts: FontsGuard,
    icons: ThemeIconsGuard,
    elements: ElementsGuard,
});

export const ThemeResponseGuard = createGuard('ThemeResponse', {
    items: t.array(ThemeResponseItemGuard),
    total: t.number,
    size: t.number,
    offset: t.number,
});
