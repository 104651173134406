/* eslint-disable react/display-name */
import React from 'react';

import View from './View';
import { useViewModel } from './viewModel';

export { default as View, getSubscriptionButtonStyles } from './View';
export { useViewModel } from './viewModel';

const Main = (props) => {
    const viewModelProps = useViewModel(props);
    return <View {...viewModelProps} />;
};

export default Main;
