import { getBorderRadius, getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import type { ModalStyles, AnimationTypes } from '../types';

const Modal = (
    theme: Theme,
    transparent: boolean,
    animationType?: AnimationTypes
): ModalStyles => ({
    overlay: {
        // @ts-ignore
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: transparent ? theme?.color?.darker3 : theme?.color?.darker1,
        transition: animationType === 'none' ? 'none' : undefined,
    },
    content: {
        display: 'flex',
        height: '100%',
        backgroundColor: 'transparent',
        borderWidth: 0,
        padding: 0,
        // @ts-ignore
        position: 'static',
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
    },
    closeBtn: {
        top: 26,
        right: '4%',
        // @ts-ignore
        '@media (max-width: 800px)': {
            right: 20,
        },
        '@media (min-width: 801px) and (max-width: 1200px)': { right: 32 },
        '@media (min-width: 1201px) and (max-width: 1600px)': { right: 48 },
        position: 'absolute',
        zIndex: 999,
    },
    closeIcon: {
        fontSize: 32,
    },
    title: {
        ...getFont(theme, 'h1'),
        color: theme?.color?.textPrimary,
        zIndex: 999,
        height: 50,
        alignSelf: 'center',
        marginLeft: '4%',
    },
    container: {
        padding: 20,
        backgroundColor: theme?.color?.background1,
        alignSelf: 'center',
        borderRadius: 8,
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
    },
    actionButtonContainer: {
        marginRight: 0,
        marginTop: 42,
        alignSelf: 'center',
        backgroundColor: theme?.color?.buttonPrimary,
    },
    subtitle: {
        ...getFont(theme, 'h4'),
        color: `${theme?.color?.textPrimary}60`,
        textAlign: 'center',
        marginLeft: 30,
        marginRight: 30,
        alignSelf: 'center',
    },
    tertiaryButtonContainer: {
        marginRight: 0,
        marginTop: 20,
        alignSelf: 'center',
        backgroundColor: theme?.color?.buttonTertiary,
    },
    actionButtonText: {
        color: theme?.color?.textButtonPrimary,
        marginLeft: 0,
    },
    contentContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export default Modal;
