import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Text, View } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { getStyles } from './styles';

const NoPurchases = () => {
    const { t } = useTranslation();
    const { theme } = useTheme();
    const styles = getStyles(theme);

    return (
        <View style={styles.wrapper}>
            <View style={styles.cartIconWrapper}>
                <Icon
                    color={theme.color.primary1}
                    name="shopping-cart"
                    font="materialIcons"
                    size={64}
                />
            </View>
            <View style={styles.infoWrapper}>
                <Text style={styles.noPurchasesTitle}>{t('tvod.manage.noData.title')}</Text>
                <View style={styles.purchasesShowUpHereLabelWrapper}>
                    <Text style={styles.purchasesShowUpHereLabel}>
                        {t('tvod.manage.noData.description')}
                    </Text>
                </View>
            </View>
        </View>
    );
};

export default NoPurchases;
