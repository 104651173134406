import React, { useRef } from 'react';
import { Interactable, Text, View, Icon } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import useOutsideClick from '@24i/nxg-sdk-smartott/src/hooks/useOutsideClick';
import getDropdownButtonStyle from '../styles';
import { DropdownButtonProps } from '../../../types';

const DropdownButton = (props: DropdownButtonProps) => {
    const {
        title,
        onPress,
        buttonTestID,
        buttonKey,
        additionalButtonStyles,
        additionalTextStyles,
        icon,
        iconSize,
        handleClickOutside,
        styles: getStyles = getDropdownButtonStyle,
    } = props;

    const { theme } = useTheme();
    const styles = getStyles(theme);
    const buttonRef = useRef(null);

    useOutsideClick(buttonRef, handleClickOutside);

    return (
        <Interactable testID={buttonTestID} key={buttonKey} onPress={onPress} ref={buttonRef}>
            <View style={[styles.buttonContainer, additionalButtonStyles]}>
                <View style={styles.innerButtonContainer}>
                    {icon && (
                        <Icon
                            font={icon?.iconFont}
                            name={icon.iconName}
                            color={theme.color.textPrimary}
                            size={iconSize || 22}
                        />
                    )}
                    <Text style={[styles.dropdownButtonText, additionalTextStyles]}>{title}</Text>
                </View>
                <Icon
                    font={theme.icons.common.dropdownExpand?.iconFont}
                    name={theme.icons.common.dropdownExpand.iconName}
                    color={theme.color.textPrimary}
                    size={22}
                    style={styles.secondaryIcon}
                />
            </View>
        </Interactable>
    );
};

export { getDropdownButtonStyle };
export default DropdownButton;
