import {
    AppNavigationContextParams,
    NavScreenConfig,
    NavScreenConfigNative,
    NavScreenConfigWeb,
} from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { TFunction } from 'i18next';
import {
    ACCESS_MODE,
    AppSettingsFeatures,
    MenuItem,
    RECORDING_STATUS,
    User,
} from '@24i/nxg-sdk-photon';

export enum PlacementNative {
    MAIN_MENU,
    // TODO: Check for better solution? (Original RS implementation)
    /**
     * This option is added because of how the react-navigation handles navigation inside menu pages.
     * If you wish that your screen is embedded in all the menu screens (you can navigate to it while retaining the current menu screen as selected in the main menu),
     * then you can add this placement option to your screen.
     */
    SUB_SCREENS,
    /**
     * The screen will be placed in a special non-menu navigator
     */
    OTHER_SCREENS,
}

export enum PlacementWeb {
    TOP_BAR_LEFT,
    TOP_BAR_RIGHT,
    BOTTOM_BAR,
    FOOTER,
    PROFILE_DROPDOWN,
}

// We are adding the placement options because it was suggested that it is better to have it declared in the screen builders
// We can get rid of this by moving the related logic to the static screens and structure functions
export interface SottNavScreenConfNative extends NavScreenConfigNative {
    /**
     * These are used to determine the final screen placement in the navigation structure
     * Use these to move screen in/out of menus or to duplicate a screen into multiple navigators without having to hardcode the logic into the config builder
     */
    placementNative: PlacementNative[];
}

export interface SottNavScreenConfWeb extends NavScreenConfigWeb {
    /**
     * These are used to determine the final screen placement in the navigation structure
     * Use these to move screen in/out of menus or to duplicate a screen into multiple navigators without having to hardcode the logic into the config builder
     */
    placementWeb: PlacementWeb[];
}

// We are combining both variants to provide single implementation for all platforms
export type SottNavScreenConfs = SottNavScreenConfNative & SottNavScreenConfWeb;

export interface SottNavScreenConfNativeExtended extends NavScreenConfigNative {
    /**
     * These are used to determine the final screen placement in the navigation structure
     * Use these to move screen in/out of menus or to duplicate a screen into multiple navigators without having to hardcode the logic into the config builder
     */
    placementNative: {
        /** Placement that will be used for screens constructed using dynamic menu parameters */
        menu?: PlacementNative[];
        /** Placement that is used for screen constructed automatically without parameters */
        static?: PlacementNative[];
    };
}

export interface SottNavScreenConfWebExtended extends NavScreenConfigWeb {
    /**
     * These are used to determine the final screen placement in the navigation structure
     * Use these to move screen in/out of menus
     */
    placementWeb: {
        /** Placement that will be used for screens constructed using dynamic menu parameters */
        menu?: PlacementWeb[];
        /** Placement that is used for screen constructed automatically without parameters */
        static?: PlacementWeb[];
    };
}

export type SottNavScreenConfsExtended = SottNavScreenConfNativeExtended &
    SottNavScreenConfWebExtended;

export type ScreenBuilderMap<TScreens extends SottScreenBuilderNames> = Record<
    TScreens,
    ScreenConfigBuilder
>;

export type ScreenConfigBuilderResult =
    | SottNavScreenConfsExtended
    | (ScreenConfigBuilderDisableResult & {
          accessMode?: ACCESS_MODE;
      });

export type ScreenConfigBuilderDisableResult = { disable: true };

export interface MenuItemWithSubScreens extends MenuItem {
    embeddedScreens: NavScreenConfig[];
    // Recording screen parameters
    status?: RECORDING_STATUS;
    fileId?: string;
}

export type ScreenConfigBuilder = (
    parameters: MenuItemWithSubScreens | null,
    context: ScreenConfBuilderContext
) => ScreenConfigBuilderResult;

export interface ScreenConfBuilderContext {
    t: TFunction;
    user: User | null | undefined;
    features?: AppSettingsFeatures;
    params: AppNavigationContextParams;
}

// This is desired!
// eslint-disable-next-line @typescript-eslint/ban-types
export type SottScreenConfigBuilders<TOtherScreens extends string | void = void> =
    ScreenBuilderMap<SottScreenBuilderNames> &
        (TOtherScreens extends string ? Record<TOtherScreens, ScreenConfigBuilder> : any);

export type SottScreenBuilderNames =
    | 'buildUpsertProfileScreen'
    | 'buildIndexScreen'
    | 'buildDynarowScreen'
    | 'buildPlaybackScreen'
    | 'buildPlaylistScreen'
    | 'buildDetailsScreen'
    | 'buildGenreScreen'
    | 'buildManageProfilesScreen'
    | 'buildWhoIsWatchingScreen'
    | 'buildMyListScreen'
    | 'buildRecentlyWatchedScreen'
    | 'buildForgotPasswordScreen'
    | 'buildPageScreen'
    | 'buildSignInScreen'
    | 'buildSignUpScreen'
    | 'buildSignUpSuccessScreen'
    | 'buildMoreScreen'
    | 'buildDeleteAccountSuccessScreen'
    | 'buildDeleteAccountScreen'
    | 'buildAccountScreen'
    | 'buildMyContentScreen'
    | 'buildLandingScreen'
    | 'buildExternalScreen'
    | 'buildSettingsScreen'
    | 'buildEpgScreen'
    | 'buildSearchScreen'
    | 'buildNotFoundScreen'
    | 'buildWelcomeScreen'
    | 'buildSignOutScreen'
    | 'buildCustomMenuScreen'
    | 'buildMyPurchaseScreen'
    | 'buildSubMenuScreen'
    | 'buildEnterPinScreen'
    | 'buildHomeScreen'
    | 'buildRecordingsScreen'
    | 'buildTextPageScreen'
    | 'buildManageDevicesScreen'
    | 'buildRemoveDeviceScreen'
    | 'buildResetPasswordScreen'
    | 'buildSeeAllScreen'
    | 'buildAddSubscriptionScreen'
    | 'buildOnboardingIntroScreen'
    | 'buildAdditionalInfoScreen'
    | 'buildCreatePinScreen'
    | 'buildTermsAndConditionsScreen'
    | 'buildFallbackScreen'
    | 'buildAccountDetailsScreen'
    | 'buildAccountSubscriptionScreen'
    | 'buildAccountParentalControlsScreen'
    | 'buildMySubscriptionsScreen'
    | 'buildFallbackScreen'
    | 'buildLanguageScreen'
    | 'buildTechnicalInfoScreen'
    | 'buildStreamingScreen'
    | 'buildThemeScreen'
    | 'buildServiceScreen';
