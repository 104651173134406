import { Theme } from '@24i/nxg-sdk-photon';
import { hexToRgb } from '@24i/nxg-core-utils';
import { StyleSheet, ViewStyle } from 'react-native';

export type TopBarMenuStyles = {
    menuScroll: ViewStyle;
    menuContainer: ViewStyle;
    section: ViewStyle;
    rightSection: ViewStyle;
    sectionContainer: ViewStyle;
    menuContainerTransparent: ViewStyle;
    topBarMenuItemWrapper: ViewStyle;
    menuContainerSticky: ViewStyle;
};

// TODO: Refactor hardcoded breakpoints?
const getTopBarMenuStyles = (theme: Theme): TopBarMenuStyles => {
    const bg = hexToRgb(theme.color.background1);
    const rgbBC = `rgba(${bg?.r},${bg?.g},${bg?.b}`;
    return StyleSheet.create({
        menuScroll: {
            flex: 0,
            backgroundColor: theme.color.primaryBlur1Fallback,
        },
        sectionContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            flex: 1,
        },
        section: {
            flexDirection: 'row',
            alignSelf: 'center',
            justifyContent: 'space-between',
        },
        rightSection: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        },
        menuContainer: {
            width: '100%',
            position: 'absolute',
            top: 0,
            flexDirection: 'row',
            display: 'flex',
            paddingHorizontal: '4%',
            height: 70,
            zIndex: 99,
            transition: '500ms linear',
            '@media (max-width: 800px)': {
                display: 'none',
            },
            '@media (min-width: 1025px) and (max-width: 1440px)': {
                padding: '0 48px',
            },
            '@media (min-width: 801px) and (max-width: 1024px)': {
                padding: '0 32px',
            },
        },
        menuContainerTransparent: {
            width: '100%',
            position: 'absolute',
            top: 0,
            flexDirection: 'row',
            display: 'flex',
            paddingHorizontal: '4%',
            height: 70,
            zIndex: 99,
            backgroundImage: `linear-gradient(to top, ${rgbBC}, 0.0), ${rgbBC}, 0.8))`,
            backgroundColor: 'transparent',
            transition: 'background-color .3s linear',
        },
        topBarMenuItemWrapper: {
            marginRight: 40,
        },
        menuContainerSticky: {
            backgroundColor: theme.color.background1,
            transition: 'background-color 0.3s linear',
        },
    });
};

export default getTopBarMenuStyles;
