import React, { FC, createContext } from 'react';
import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import { ModularSubscriptionsElements, isSupportedProvider } from './types';
import { subscriptionProviderElementsMap } from './data';

export const SubscriptionsHandlerContext = createContext<ModularSubscriptionsElements | null>(null);

/*
This context checks the subscriptions-provider service defined on Backstage' custom JSON,
to allow using different purchase, payment-method and payment-history screens if a different provider is
ever needed. If the subscription-provider is badly defined, this context Provider returns null and subsequent calls to the hooks
consuming this context-provider will throw an explanatory error.
*/
export const SubscriptionsHandlerProvider: FC = ({ children }) => {
    const subscriptionsProvider = useFeature('subscriptionsProvider')?.name;

    if (!subscriptionsProvider || !isSupportedProvider(subscriptionsProvider)) {
        return <>{children}</>;
    }

    const subscriptionsProviderComponents: ModularSubscriptionsElements =
        subscriptionProviderElementsMap[subscriptionsProvider];

    const actualProvider = (
        <SubscriptionsHandlerContext.Provider value={subscriptionsProviderComponents}>
            {children}
        </SubscriptionsHandlerContext.Provider>
    );

    // In the case of Cleeng, there is another specific context that is required for its components to function properly
    const ExtraConfigProvider = subscriptionsProviderComponents.ConfigProvider;

    if (ExtraConfigProvider != null) {
        return <ExtraConfigProvider>{actualProvider}</ExtraConfigProvider>;
    }

    return actualProvider;
};

const useSubscriptionsModularElements = () => {
    const subscriptionsProviderComponents = React.useContext(SubscriptionsHandlerContext);

    if (!subscriptionsProviderComponents) {
        throw new Error(
            `It looks like the useSubscriptionsModularElements hook was called without the SubscriptionsHandlerProvider wrapped around it.

This might be caused by the fact that there is no subscriptionsProvider set up in the Backstage custom JSON file
(https://24imedia.atlassian.net/wiki/spaces/PRJ001NXG/pages/3183411256/Custom+JSON+Feature+Configuration), and this screen relies on it.

If a subscriptionsProvider is defined in Backstage custom JSON file, this error might be due to the fact that
there are no modular subscription screens set up for your subscriptions-provider.
If this is the case, add these modular screens to the map inside packages/sdk@smartott/src/context/SubscriptionsHandlerProvider/data.ts`
        );
    }

    return {
        subscriptionsProviderComponents,
    };
};

const handleScreenAbsence = (element: unknown) => {
    if (
        !element ||
        typeof element !== 'object' ||
        (element != null && !('Main' in element)) ||
        (element != null && !('View' in element))
    ) {
        throw new Error('No modular element for this screen!');
    }
};

export const useSubscriptionPurchaseScreen = () => {
    const {
        subscriptionsProviderComponents: { PurchaseScreen },
    } = useSubscriptionsModularElements();
    handleScreenAbsence(PurchaseScreen);
    return { Main: PurchaseScreen.Main, View: PurchaseScreen.View };
};

export const usePaymentMethodScreen = () => {
    const {
        subscriptionsProviderComponents: { PaymentMethodScreen },
    } = useSubscriptionsModularElements();
    handleScreenAbsence(PaymentMethodScreen);
    return { Main: PaymentMethodScreen.Main, View: PaymentMethodScreen.View };
};

export const usePaymentHistoryScreen = () => {
    const {
        subscriptionsProviderComponents: { PaymentHistoryScreen },
    } = useSubscriptionsModularElements();
    handleScreenAbsence(PaymentHistoryScreen);
    return { Main: PaymentHistoryScreen.Main, View: PaymentHistoryScreen.View };
};
