import { getFont } from '@24i/nxg-sdk-higgs/src';
import { Theme } from '@24i/nxg-sdk-photon/src';
import { TierOneMvpdItemContentStyles } from '../types';

export const getTierOneItemContentStyles = (theme: Theme): TierOneMvpdItemContentStyles => {
    return {
        imageStyle: {
            resizeMode: 'contain',
        },
        textContainer: {
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
        },
        textStyle: {
            ...getFont(theme, 'h4'),
            color: theme.color.contrast1,
        },
        webSvgContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        webSvg: {
            width: 167,
        },
    };
};
