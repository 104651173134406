import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, SafeAreaView, Icon, View } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import SubactionButton from '@24i/nxg-sdk-gluons/src/components/buttons/SubactionButton';
import getUnavailableSubscriptionsStyles from './styles';
import { SubscriptionsUnavailableProps } from './types';

const SubscriptionsUnavailable = ({
    onBackPress,
    styles: getStyles = getUnavailableSubscriptionsStyles,
}: SubscriptionsUnavailableProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const { t } = useTranslation();

    return (
        <SafeAreaView style={styles.container}>
            <View style={styles.wrapper}>
                <View style={styles.icon}>
                    <Icon
                        color={theme.color.primary1}
                        name="payment"
                        font="materialIcons"
                        size={64}
                    />
                </View>
                <Text style={styles.title}>{t('svod.add.noData.title')}</Text>
                <Text style={styles.description}>{t('svod.add.noData.description')}</Text>
                <SubactionButton
                    onPress={onBackPress}
                    title={t('common.back')}
                    additionalContainerStyles={{ marginHorizontal: 0, ...styles.backButtonWrapper }}
                    additionalTextStyles={styles.backButtonText}
                />
            </View>
        </SafeAreaView>
    );
};

export default SubscriptionsUnavailable;
