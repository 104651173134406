import React, { useEffect } from 'react';
import { Interactable, Text, Icon, View } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { useTranslation } from 'react-i18next';
import uniqBy from 'lodash/uniqBy';

import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import type { Device } from '@24i/react-native-google-cast/src';
import { useModal } from '@24i/nxg-sdk-gluons/src/context/Modal';

import { useDevices, CastContext } from '../../../../context/Cast';

import getChromecastStartCastingStyles from '../styles';

const ChromecastStartCasting = ({ styles: getStyles = getChromecastStartCastingStyles }) => {
    const { t } = useTranslation(['sott']);
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const devices = useDevices();
    const { closeModal, setModalProps } = useModal();
    const sessionManager = CastContext?.getSessionManager();

    const uniqDevices = uniqBy(devices, 'modelName');

    const onSelect = (device: Device) => {
        sessionManager?.startSession(device.deviceId);
        closeModal();
    };

    useEffect(() => {
        setModalProps({
            modalTitle: t('chromecast.castTo'),
        });
    }, []);

    return (
        <View style={styles.container}>
            {uniqDevices.map((device: Device) => (
                <Interactable
                    key={device.deviceId}
                    style={styles.deviceContainer}
                    onPress={() => onSelect(device)}
                >
                    <View style={styles.additionalDeviceContainer}>
                        <Icon
                            font={theme.icons.chromecast.tv?.iconFont}
                            name={theme.icons.chromecast.tv.iconName}
                            size={24}
                            color={theme.color.contrast3}
                        />
                        <Text style={styles.deviceText}>{device.friendlyName}</Text>
                    </View>
                </Interactable>
            ))}
        </View>
    );
};

export { getChromecastStartCastingStyles };
export default overridable(ChromecastStartCasting, 'ChromecastStartCasting');
