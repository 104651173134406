import React, { FC } from 'react';
import { Icon, View } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import Interactable from '@24i/nxg-sdk-quarks/src/components/Interactable';
import getStyles from './styles';

interface Props {
    isBackButtonVisible?: boolean;
    onBackPress?: () => void;
    children: React.ReactNode;
}

const ModalLayoutWithBackButton: FC<Props> = ({ isBackButtonVisible, onBackPress, children }) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);

    return (
        <View style={styles.wrapper}>
            <View style={styles.titleWrapper} pointerEvents="box-none">
                {isBackButtonVisible && onBackPress && (
                    <Interactable style={styles.icon} onPress={onBackPress}>
                        <Icon
                            color={theme.color.textPrimary}
                            font="fontAwesome"
                            name="angle-left"
                            size={36}
                        />
                    </Interactable>
                )}
            </View>
            <View style={styles.content}>{children}</View>
        </View>
    );
};

export default ModalLayoutWithBackButton;
