import { getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { ColorValue } from 'react-native';
import { isPlatformAndroid } from 'renative';
import { ViewingRestrictionStyle } from './types';

interface Context {
    theme: Theme;
    isRatingSelectionVisible: boolean;
}

const getViewingRestrictionsStyles = ({
    theme,
    isRatingSelectionVisible,
}: Context): ViewingRestrictionStyle => {
    return {
        container: {
            paddingBottom: 6,
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
        },
        descriptionContainer: {
            flexDirection: 'row',
            paddingTop: isPlatformAndroid ? 6 : 0,
            marginBottom: isRatingSelectionVisible ? 26 : 0,
        },
        activeRateLabel: {
            borderRadius: 4,
            paddingTop: 2,
            paddingBottom: 2,
            paddingLeft: 6,
            paddingRight: 6,
            backgroundColor: theme.color.lighter4,
        },
        rateLabel: {
            opacity: 0.5,
            borderRadius: 4,
            paddingTop: 2,
            paddingBottom: 2,
            paddingLeft: 6,
            paddingRight: 6,
            backgroundColor: theme.color.lighter4,
        },
        activeRate: {
            paddingLeft: 6,
            paddingRight: 6,
            backgroundColor: theme.color.lighter4,
            borderRadius: 4,
            color: <ColorValue>theme.color.textPrimary,
            fontWeight: 'bold',
            ...getFont(theme, 'body2'),
        },
        rate: {
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 2,
            paddingBottom: 2,
            backgroundColor: theme.color.lighter4,
            opacity: 0.5,
            color: <ColorValue>theme.color.textPrimary,
            fontWeight: 'bold',
            ...getFont(theme, 'body2'),
        },
        rectangle: {
            width: 3,
            height: 45,
            backgroundColor: <ColorValue>theme.color.buttonPrimary,
        },
        rectangleDisabled: {
            width: 3,
            height: 45,
            backgroundColor: <ColorValue>theme.color.contrast4,
        },
        firstRectangleDisabled: {
            width: 3,
            height: 45,
            backgroundColor: <ColorValue>theme.color.contrast4,
        },
        circle: {
            width: 8,
            height: 8,
            borderRadius: 8 / 2,
            backgroundColor: <ColorValue>theme.color.buttonPrimary,
        },
        circleDisabled: {
            width: 8,
            height: 8,
            borderRadius: 8 / 2,
            backgroundColor: <ColorValue>theme.color.contrast4,
        },
        iconContainer: {
            justifyContent: 'center',
            alignItems: 'center',
        },
        icon: {
            color: <ColorValue>theme.color.textPrimary,
        },
        rectangleContainer: {
            position: 'absolute',
        },
        firstItemRectangle: {
            left: 2.5,
            top: 7,
            width: 3,
            height: 20,
            backgroundColor: <ColorValue>theme.color.buttonPrimary,
        },
        lastItemRectangle: {
            left: 2.5,
            top: -18.5,
            width: 3,
            height: 18.5,
            backgroundColor: <ColorValue>theme.color.contrast4,
        },
        ratingContainer: {
            flexDirection: 'row',
            justifyContent: 'space-around',
            height: 45,
        },
        ratingIconContainer: {
            width: 20,
            justifyContent: 'center',
            alignItems: 'center',
        },
        labelContainer: {
            paddingLeft: 16,
            justifyContent: 'center',
            alignItems: 'flex-start',
        },
        restrictionTextWrapper: {
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: '100%',
        },
        restrictionTextAgeWrapper: { marginLeft: 5, marginRight: 5 },
    };
};

export default getViewingRestrictionsStyles;
