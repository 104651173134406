import { isPlatformAndroid } from 'renative';
import { transformColorToArgb as utilsTransformColorToArgb, rgbaToHex } from '@24i/nxg-core-utils';
import { getFont, getBorderRadius } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import {
    LandscapeContainerStyles,
    LandscapeCellStyles,
    LandscapeSidebarStyles,
    LandscapeTimelineStyles,
    LandscapeCurrentTimeStyles,
    LandscapeStyles,
} from '@24i/nxg-sdk-epg/src/types';
import { LandscapeStylesGetterProps } from './types';

const transformColorToArgb = (color: string) => {
    if (isPlatformAndroid) return utilsTransformColorToArgb(color);
    return color;
};

export const defaultGetContainerStyles = (theme: Theme): LandscapeContainerStyles => ({
    grid: {
        backgroundColor: isPlatformAndroid ? '#00ffffff' : 'transparent',
    },
    divider: {
        height: 1,
        top: -6,
        position: 'relative',
        backgroundColor: transformColorToArgb(theme.color.lighter3),
        zIndex: 10,
    },
});

export const defaultGetCellStyles = (theme: Theme): LandscapeCellStyles => ({
    container: {
        backgroundColor: transformColorToArgb(theme.color.epgRowInactive),
        borderRadius: 6,
        ...getBorderRadius(theme.elements, 'epgItem-cornerRadius'),
        height: 72,
        paddingHorizontal: 15,
        borderStyle: 'solid',
        borderWidth: isPlatformAndroid ? 3 : 2,
        borderColor: transformColorToArgb(theme.color.epgBackground),
    },
    activeContainer: {
        backgroundColor: transformColorToArgb(`${rgbaToHex(theme.color.epgRowActiveWhole)}33`),
    },
    activePassedContainer: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        height: '100%',
        backgroundColor: transformColorToArgb(`${rgbaToHex(theme.color.epgRowActiveWhole)}19`),
    },
    title: {
        ...getFont(theme, 'h4'),
        color: transformColorToArgb(theme.color.textPrimary),
        width: '90%',
    },
    infoContainer: {
        flexDirection: 'column',
        width: '100%',
        paddingHorizontal: 18,
    },
    titleContainerWithIcon: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
    },
    icon: {
        width: 15,
        height: 15,
    },
    lockedTitle: {
        color: theme.color.textPrimary, // Color not added in Backstage theme section
    },
    description: {
        color: transformColorToArgb(`${theme.color.textPrimary}99`),
        ...getFont(theme, 'h6'),
    },
});

export const defaultGetSidebarStyles = (theme: Theme): LandscapeSidebarStyles => ({
    container: {
        width: 144,
        backgroundColor: transformColorToArgb(theme.color.darker1),
    },
    logo: {
        borderRadius: 4,
        width: 48,
        height: 48,
    },
});

export const defaultGetTimelineStyles = (theme: Theme): LandscapeTimelineStyles => ({
    container: {
        backgroundColor: transformColorToArgb(theme.color.background1),
        borderBottomWidth: 0.7,
        borderStyle: 'solid',
        borderBottomColor: transformColorToArgb(theme.color.lighter3),
        height: 40,
    },
    text: {
        ...getFont(theme, 'h6'),
        color: transformColorToArgb(theme.color.textPrimary),
    },
    hoursLine: {
        fontSize: 6,
        color: transformColorToArgb(theme.color.textPrimary),
        opacity: 0.3,
        top: 23,
    },
});

export const defaultGetCurrentTimeStyles = (theme: Theme): LandscapeCurrentTimeStyles => ({
    line: {
        zIndex: 99,
        position: 'absolute',
        top: 0,
        opacity: 0.6,
        width: 2,
        backgroundColor: transformColorToArgb(theme.color.progressBarLive),
    },
    arrow: {
        width: 0,
        height: 0,
        zIndex: 99,
        position: 'absolute',
        borderWidth: 6,
        borderStyle: 'solid',
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        borderTopColor: transformColorToArgb(theme.color.progressBarLive),
        borderBottomWidth: 0,
    },
    label: {
        backgroundColor: transformColorToArgb(theme.color.progressBarLive),
        borderRadius: 4,
        ...getBorderRadius(theme.elements, 'label-cornerRadius'),
        height: 20,
        bottom: 0,
        width: 40,
        shadowColor: 'rgba(0,0,0, 0.36)',
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowRadius: 6.68,
        elevation: 11,
    },
    labelText: {
        color: transformColorToArgb(theme.color.textPrimary),
        ...getFont(theme, 'h6'),
        textAlign: 'center',
        width: '100%',
    },
});

export const defaultGetLandscapeStyles = (
    theme: Theme,
    {
        getLandscapeCellStyles = defaultGetCellStyles,
        getLandspaceSidebarStyles = defaultGetSidebarStyles,
        getLandscapeTimelineStyles = defaultGetTimelineStyles,
        getLandscapeCurrentTimeStyles = defaultGetCurrentTimeStyles,
        getLandscapeContainerStyles = defaultGetContainerStyles,
    }: LandscapeStylesGetterProps
): LandscapeStyles => ({
    ...getLandscapeContainerStyles(theme),
    cell: getLandscapeCellStyles(theme),
    sidebar: getLandspaceSidebarStyles(theme),
    timeline: getLandscapeTimelineStyles(theme),
    currentTime: getLandscapeCurrentTimeStyles(theme),
});
