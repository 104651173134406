import { CleengTokenData } from '@24i/nxg-sdk-photon';
import { decodedCleengToken } from './decodedCleengToken';
import { decodedToken } from './decodedToken';

export const extractCleengDataFromBackstageToken = (backstageToken: string): CleengTokenData => {
    const backstageDecodedToken = decodedToken(backstageToken);
    const cleengToken = backstageDecodedToken.externalToken;
    const cleengDecodedToken = decodedCleengToken(cleengToken);
    return {
        cleengToken,
        expirationTimestamp: cleengDecodedToken.exp,
        email: backstageDecodedToken.username,
    };
};
