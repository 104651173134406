import React, { useEffect, useState } from 'react';
import { isUser } from '@24i/nxg-sdk-photon';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { useStore } from '@24i/nxg-sdk-smartott/src/context/ApplicationStore';
import { View, Text, SafeAreaView } from 'react-native';
import { withWebHeader } from '@24i/nxg-sdk-smartott/src/navigation/components/withWebHeader';
import { useAppSettingsData } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import { Toggle } from '@24i/nxg-sdk-gluons';
import { getStyles } from '../styles';

const StreamingScreen = () => {
    const [streamOverWifiOnly, setStreamOverWifiOnly] = useState(false);
    const { theme } = useTheme();
    const appSettings = useAppSettingsData();
    const { userData } = useStore();
    const styles = getStyles(theme);
    const { t } = useTranslation(['sott']);

    const getStreamOverWifi = async () => {
        if (userData && isUser(userData) && userData.id) {
            const stream = await appSettings.client.shouldStreamOverWifiOnly(userData.id);
            setStreamOverWifiOnly(stream);
        }
        return false;
    };

    useEffect(() => {
        getStreamOverWifi();
    }, []);

    const toggleStreamOverWifiOnly = (value: boolean): void => {
        if (userData && isUser(userData) && userData.id) {
            appSettings.client.setStreamOverWifiOnly(userData.id, value);
            setStreamOverWifiOnly(value);
        }
    };
    return (
        <SafeAreaView style={styles.safeArea}>
            <View style={styles.container}>
                <View style={styles.titleContainer}>
                    <Text style={styles.title}>{t('settings.streaming.wifi.label')}</Text>
                    <Toggle
                        value={streamOverWifiOnly}
                        onValueChange={toggleStreamOverWifiOnly}
                        trackColor={{ false: theme.color.lighter2, true: theme.color.success }}
                        thumbColor={theme.color.contrast1}
                    />
                </View>
                <View style={styles.descriptionContainer}>
                    <Text style={styles.description}>
                        {t('settings.streaming.wifi.labelTopic')}
                    </Text>
                </View>
            </View>
        </SafeAreaView>
    );
};

export default withWebHeader(StreamingScreen);
