import { getFont, getBorderRadius } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { ViewStyle } from 'react-native';
import { SliderStyles } from './types';

const BannerCarousel = (theme: Theme): SliderStyles => ({
    item: {
        backgroundColor: 'transparent',
        alignSelf: 'stretch',
        width: '100%',
        height: '66%',
    },
    image: {
        width: '100%',
        height: '66%',
    },
    dynamicLandscapeImage: {
        // should match slideContainer width when slide becomes portrait
        '@media (max-width: 743px)': {
            display: 'none',
        },
    },
    dynamicPosterImage: {
        '@media (min-width: 743px)': {
            display: 'none',
        },
    },
    slideHolder: {
        width: '100%',
        alignSelf: 'center',
        marginTop: 53,
    },
    slideContainer: {
        flex: 1,
        paddingTop: '54.75%',
        flexDirection: 'column-reverse',
        marginLeft: '1%',
        marginTop: 12,
        '@media (max-width: 743px)': {
            marginRight: 8,
            maxHeight: '55vh',
            height: '55vh',
            //  @ts-ignore
            transition: 'height 999s, max-height 999s',
        },
        '@media (min-width: 800px) and (max-width: 1200px)': {
            marginRight: 8,
        },
        '@media (min-width: 1201px) and (max-width: 1600px)': {
            marginRight: 10,
        },
        '@media (min-width: 1600px)': {
            marginLeft: 12,
            marginRight: 10,
        },
        transition: '0.1s ease-in',
        ':hover': {
            marginTop: 'calc(0.4%)',
            marginBottom: 'calc(0.4%)',
            transform: 'scale(1.02)',
        },
    } as ViewStyle,
    imageStyle: {
        borderRadius: 6,
        ...getBorderRadius(theme.elements, 'headerTile-cornerRadius'),
        position: 'absolute',
        alignSelf: 'auto',
        overflow: 'hidden',
        zIndex: 0,
        height: '100%',
        width: '100%',
    },
    itemText: {
        textAlign: 'left',
        ...getFont(theme, 'h2'),
        color: '#fff',
    },
    textHolder: {
        position: 'absolute',
        bottom: 40,
        '@media (max-width: 1200px)': {
            bottom: 30,
        },
        '@media (max-width: 768px)': {
            bottom: 20,
        },
        left: 32,
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        zIndex: 2,
    },
    buttonsHolder: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 17,
    },
    titleViewHolder: {
        width: '85%',
    },
    gradient: {
        position: 'absolute',
        zIndex: 1,
        //  @ts-ignore
        background: theme.color.gradient2.color,
    },
    primaryButtonContainerWithTitle: {
        height: 52,
        width: 'auto',
        paddingLeft: 20,
        paddingRight: 25,
    },
    additionalSubactionButtonContainerStyles: {
        width: 42,
        paddingLeft: 0,
        paddingRight: 0,
        '@media (max-width: 1200px)': {
            width: 40,
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    assetLabelContainer: { alignSelf: 'flex-start' },
    itemContainer: {
        transition: '0.1s ease-in',
        ':hover': {
            marginTop: 'calc(-0.4%)',
            marginBottom: 'calc(0.4%)',
            transform: 'scale(1.02)',
        },
    } as ViewStyle,
    geoBlockIconContainer: {
        position: 'absolute',
        top: 34,
        right: 34,
        backgroundColor: theme.color.labelPrimary,
        height: 'auto',
        width: 'auto',
        padding: 2,
        borderRadius: 2,
    },
});
export default BannerCarousel;
