/* eslint-disable no-extra-boolean-cast */
import { AppSettings, AppSettingsDataClient, getDummyClient } from '@24i/nxg-sdk-photon';
import deepmerge from 'deepmerge';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import { getRuntimeConfig } from '@24i/nxg-sdk-smartott/src/Application/initApp';

type ServiceConfigDataWrapper = { data: AppSettings | null; isLoading: boolean };

const defaultClient = getDummyClient<AppSettingsDataClient>();

type ContextType = {
    client: AppSettingsDataClient;
    serviceConfigData: ServiceConfigDataWrapper;
    setServiceConfigData: (newValue: ServiceConfigDataWrapper) => void;
};

export const AppSettingsDataContext = createContext<ContextType>({
    client: defaultClient,
    serviceConfigData: { data: null, isLoading: false },
    setServiceConfigData: () => {
        throw new Error(
            `You are trying to use the AppSettingsDataContext but there is no provider available!`
        );
    },
});
interface AppSettingsDataProviderProps {
    client: AppSettingsDataClient;
    children: ReactNode;
}

export const AppSettingsDataProvider = ({
    client = defaultClient,
    children,
}: AppSettingsDataProviderProps) => {
    const [serviceConfigData, setServiceConfigData] = useState<ContextType['serviceConfigData']>({
        data: null,
        isLoading: false,
    });

    const fetchServiceConfig = async () => {
        const result = await client.fetchServiceConfig();
        const runtimeFeatures = getRuntimeConfig('features');

        return deepmerge<AppSettings>({ features: runtimeFeatures }, result, {
            arrayMerge: (d, s) => s,
        });
    };

    return (
        <AppSettingsDataContext.Provider
            value={{
                client: { ...client, fetchServiceConfig },
                serviceConfigData,
                setServiceConfigData,
            }}
        >
            {children}
        </AppSettingsDataContext.Provider>
    );
};

export const useAppSettingsData = () => useContext(AppSettingsDataContext);

export const useSettings = <T extends keyof AppSettings>(key: T): AppSettings[T] | never => {
    const appSettings = useAppSettingsData();

    const settings = appSettings.serviceConfigData.data?.[key];

    if (!settings) {
        throw new Error(
            `Settings '${key}' is missing. Ensure the settings is provided by the Backstage or set in the renative configuration file.`
        );
    }

    return settings;
};

export const useFeature = <T extends keyof AppSettings['features']>(
    name: T,
    defaultValue?: AppSettings['features'][T]
): AppSettings['features'][T] | undefined => {
    const appSettings = useAppSettingsData();

    const feature = appSettings.serviceConfigData.data?.features[name] ?? defaultValue;

    return feature;
};
