import { Theme } from '@24i/nxg-sdk-photon';
import { getFont } from '@24i/nxg-sdk-higgs';
import { TextWithSepratorStyles } from '../types';

const getStyles = (theme: Theme): TextWithSepratorStyles => ({
    separator: {
        color: `${theme.color.textPrimary}99`,
        ...getFont(theme, 'h4'),
    },
    subtitle: {
        color: `${theme.color.textPrimary}99`,
        fontWeight: 'bold',
        ...getFont(theme, 'h4'),
        alignItems: 'flex-start',
        lineHeight: 24,
    },
});

export default getStyles;
