import { useThrottleFn } from '@24i/nxg-core-utils/src';
import { useMonitoring } from '@24i/nxg-sdk-smartott-shared/src/context/MonitoringData';
import { onAnalytics, ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics';
import { useAppConfigQuery } from '../../../hooks/query/useAppConfigQuery';
import {
    CommonPlayerEventParams,
    useCommonPlayerEventParams,
    CommonPlayerEventTriggerParams,
} from '../useCommonPlayerEventParams';

const DEFAULT_HEARTBEAT_INTERVAL = 5000;

export const useTriggerOnPlaybackHeartbeat = (params: CommonPlayerEventParams) => {
    const event = useCommonPlayerEventParams(params);
    const { triggerMonitoringEvent } = useMonitoring();
    const { appSettings } = useAppConfigQuery();

    const { run: onPlaybackProgress } = useThrottleFn(
        ({
            percentOfPlayback,
            currentPlaybackTimeMs,
            duration,
        }: CommonPlayerEventTriggerParams) => {
            if (!event) {
                return;
            }
            const currentEvent = {
                ...event,
                timestamp: Date.now(),
                type: 'PLAYBACK_HEARTBEAT' as const,
                percentOfPlayback,
                currentPlaybackTimeMs,
                duration,
            };
            onAnalytics(ANALYTICS_TRIGGERS.HEARTBEAT_5, currentEvent);
            triggerMonitoringEvent({ ...currentEvent });
        },
        {
            wait:
                appSettings?.features.monitoringPlayback?.heartbeatIntervalMs ||
                DEFAULT_HEARTBEAT_INTERVAL,
            leading: true,
        }
    );

    return onPlaybackProgress;
};
