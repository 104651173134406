import { Theme } from '@24i/nxg-sdk-photon';
import { getFont } from '@24i/nxg-sdk-higgs';
import { NoPurchasesStyles } from './types';

export const getStyles = (theme: Theme): NoPurchasesStyles => ({
    wrapper: {
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    cartIconWrapper: {
        borderRadius: 50,
        backgroundColor: theme.color.darker1,
        padding: 16,
    },
    infoWrapper: {
        marginTop: 24,
        alignItems: 'center',
    },
    noPurchasesTitle: {
        fontSize: 16,
        color: theme.color.textPrimary,
    },
    purchasesShowUpHereLabelWrapper: {
        marginTop: 8,
    },
    purchasesShowUpHereLabel: {
        ...getFont(theme, 'body2'),
        opacity: 0.4,
        color: theme.color.textPrimary,
    },
});
