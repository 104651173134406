import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppConfigQuery } from '@24i/nxg-sdk-smartott/src/hooks/query/useAppConfigQuery';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { LandingScreenConfig } from '@24i/nxg-sdk-photon';
import { LandingScreenView } from '../../screens/LandingScreen';

const GeoblockCheck = ({ children }) => {
    const { isLoading, appSettings } = useAppConfigQuery();
    const { t } = useTranslation();
    const isBlocked = appSettings?.features.geoblock?.global?.blocked;

    const { theme } = useTheme();

    const getLandingScreenConfigFromTheme = (): LandingScreenConfig | null => {
        const {
            elements: { landingImageLandscape, landingImagePortrait },
        } = theme;

        return {
            ...(appSettings?.features?.landingScreen || {}),
            ...(landingImagePortrait?.value &&
                landingImageLandscape?.value && {
                    backgroundImage: {
                        landscape: landingImageLandscape?.value?.toString() || '',
                        portrait: landingImagePortrait?.value?.toString() || '',
                    },
                }),
        };
    };

    if (isLoading || isBlocked) {
        return (
            <LandingScreenView
                isLoading={isLoading}
                landingScreenConfig={getLandingScreenConfigFromTheme() || null}
                headlineText={t('error.E04.title') as string}
                subtitleText={t('error.E04.body') as string}
            />
        );
    }
    return children;
};

export default GeoblockCheck;
