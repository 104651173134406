import React from 'react';
import IconButton from '@24i/nxg-sdk-gluons/src/components/buttons/IconButton';
import { View, Text, Image } from '@24i/nxg-sdk-quarks';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useTheme } from '@24i/nxg-sdk-higgs';
import getCastMediaDetailStyles from '../styles';
import { CAST_MEDIA_DETAIL_TEST_IDS } from '../types';

const CastMediaDetail = ({
    styles: getStyles = getCastMediaDetailStyles,
    metaData,
    customData,
    onPlay,
    isPlaying,
}) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const { icons } = theme;
    return (
        <View style={styles?.mediaContainer}>
            {customData?.logo && (
                <Image
                    resizeMode="contain"
                    style={styles?.logoStyles}
                    source={{
                        uri: customData?.logo,
                    }}
                />
            )}
            <View style={styles?.titleAndSubtitleContainer}>
                <Text
                    style={styles?.titleStyles}
                    numberOfLines={metaData?.subtitle !== null ? 1 : 2}
                >
                    {metaData?.title}
                </Text>

                {metaData?.subtitle !== null && (
                    <Text style={styles?.subtitleStyles} numberOfLines={1}>
                        {metaData?.subtitle}
                    </Text>
                )}
            </View>
            <IconButton
                color={theme.color.textPrimary}
                icon={isPlaying ? icons.player.pause : icons.player.play}
                iconSize={34}
                additionalContainerStyles={styles.additionalDisconnectButtonStyles}
                onPress={onPlay}
                testID={CAST_MEDIA_DETAIL_TEST_IDS.ACTION_BUTTON}
            />
        </View>
    );
};

export { getCastMediaDetailStyles };
export default overridable(CastMediaDetail, 'CastMediaDetail');
