import React from 'react';
import { Text } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import getStyles from './styles';
import { TextWithSeparatorProps, SEPARATORS } from './types';

const TextWithSeparator = ({ children, separator }: TextWithSeparatorProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);

    const usedSeparator = SEPARATORS[separator] ?? SEPARATORS.DOT;

    const notEmptyChildren = children.filter(Boolean);
    const updatedChildren = notEmptyChildren.map((child, index) => {
        return [
            child,
            notEmptyChildren.length > 1 && index !== notEmptyChildren.length - 1 && (
                <Text style={styles.separator}> {usedSeparator} </Text>
            ),
        ];
    });

    return (
        <Text style={styles.subtitle} numberOfLines={2}>
            {updatedChildren}
        </Text>
    );
};

export { SEPARATORS };
export default TextWithSeparator;
