import { useRef } from 'react';
import { PlaybackMilestoneReached, PlaybackEvent } from '@24i/nxg-sdk-photon/src/analytics/player';
import { useMonitoring } from '@24i/nxg-sdk-smartott-shared/src/context/MonitoringData';
import {
    onAnalytics,
    ANALYTICS_TRIGGERS,
    ANALYTICS_PREFIXES,
} from '@24i/nxg-sdk-smartott-shared/src/analytics';
import { useTriggerOnce } from '../../hooks/useTriggerOnce';
import {
    CommonPlayerEventParams,
    useCommonPlayerEventParams,
    CommonPlayerEventTriggerParams,
} from '../useCommonPlayerEventParams';

// I don't know why but on mobiles storing event into constant is not persisting value
// but tries to call useCommonPlayerEventParams hook with every triggerMilestones() calls
// what results in undefined value and not sending Playback Milestones
let event: Omit<PlaybackEvent, 'type'> | undefined;

export const useTriggerOnPlaybackMilestone = ({
    asset,
    source,
    currentTime,
    duration,
    milestones = [10, 25, 50, 75, 90],
}: CommonPlayerEventParams & {
    milestones?: Array<PlaybackMilestoneReached['value']>;
}) => {
    event = useCommonPlayerEventParams({ asset, source, currentTime, duration });
    const { triggerMonitoringEvent } = useMonitoring();
    const previousValue = useRef(100);

    const { triggerOnce } = useTriggerOnce<PlaybackMilestoneReached>(
        (e) => `${e.asset.id}${e.value}`,
        triggerMonitoringEvent,
        undefined
    );

    const triggerMilestones = (
        currentValuePercent: number,
        currentValueTime: number,
        currentDuration: number
    ) => {
        if (!event) {
            return;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const milestone of milestones) {
            if (currentValuePercent >= milestone && previousValue.current < milestone) {
                onAnalytics(ANALYTICS_TRIGGERS[`${ANALYTICS_PREFIXES.PLAYBACK}${milestone}`], {
                    ...event,
                    currentPlaybackTimeMs: currentValueTime,
                    duration: currentDuration,
                });
                triggerOnce({
                    ...event,
                    value: milestone,
                    currentPlaybackTimeMs: currentValueTime,
                    type: 'PLAYBACK_MILESTONE_REACHED',
                });
                return;
            }
        }
    };

    const trigger: (params: CommonPlayerEventTriggerParams) => void = ({
        percentOfPlayback,
        currentPlaybackTimeMs,
        duration: currentDuration,
    }) => {
        triggerMilestones(percentOfPlayback, currentPlaybackTimeMs, currentDuration);
        previousValue.current = percentOfPlayback;
    };

    return trigger;
};
