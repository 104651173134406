import { GestureResponderEvent, TouchableOpacity, ViewStyle } from 'react-native';
import { ForwardedRef } from 'react';
import {
    Asset,
    Rating,
    RecordingStatus,
    RECORDING_STATUS,
    Season,
    Theme,
} from '@24i/nxg-sdk-photon';
import { IconButtonProps } from '@24i/nxg-sdk-gluons/src/components/buttons/IconButton/types';
import { GradientParams } from '@24i/nxg-sdk-quarks/src/types';
import {
    PageDetailsContentMobileStyles,
    PageDetailsContentTvStyles,
    PageDetailsContentWebStyles,
} from './styles/types';
import { ButtonRefWithNode } from '../EpisodePicker/types';

export enum PrimaryButtonType {
    LOADING = 'Loading',
    PLAY = 'Play',
    UNAVAILABLE = 'Unavailable',
    PURCHASE = 'Purchase',
    NONE = 'None',
}

export type Props = {
    asset: Asset;
};

export interface DetailsContentProps {
    asset: Asset;
    title?: string | null;
    subtitle: string;
    description?: string | null;
    isAddedToMyList?: boolean;
    gradientParams?: GradientParams;
    seasons?: Season[];
    recordingState: RecordingStatus;
    ref?: ForwardedRef<TouchableOpacity>;
    image?: string | null;
    fadeColors?: string[];
    nextFocusDown?: string | ButtonRefWithNode | null;
    reminderIsSet: boolean;
    purchaseButtonTitle?: boolean;
    styles?: (
        theme: Theme
    ) => PageDetailsContentMobileStyles | PageDetailsContentWebStyles | PageDetailsContentTvStyles;
    mainButtonsOverride?: ({
        DefaultActionButton,
        DefaultSubactionButton,
        asset,
    }: {
        DefaultActionButton?: (props: IconButtonProps) => JSX.Element;
        DefaultSubactionButton?: (props: IconButtonProps) => JSX.Element;
        asset?: Asset;
    }) => JSX.Element;
    actionButtonStyles: (
        stylesObj:
            | PageDetailsContentMobileStyles
            | PageDetailsContentTvStyles
            | PageDetailsContentWebStyles,
        asset: Asset
    ) => ViewStyle | undefined;
    onFocus?: () => void;
    onPlayPress: (event?: GestureResponderEvent) => Promise<void> | undefined;
    onRemindPress: () => void;
    onSharePress: () => void;
    onMyListPress?: () => void;
    onRecordPress: (status?: RECORDING_STATUS) => void;
    onReplayPress: () => Promise<void> | undefined;
    fetchReminder: () => Promise<void>;
    fetchingReminder: boolean;
    openPurchaseModal: (asset: Asset) => void;
    isPurchaseModalVisible: boolean;
    closePurchaseModal: () => void;
}

export interface DetailsContentModelProps {
    profileId: string | undefined;
    assetRating?: Rating;
    fetchingRatingInfo?: boolean;
    isRatingModalVisible?: boolean;
    shouldDisplayRecordButton?: boolean;
    shouldDisplayRatingButton?: boolean;
    shouldDisplayRestartButton?: boolean;
    shouldDisplayListableButton?: boolean;
    shouldDisplayShareButton?: boolean;
    shouldDisplayPrimaryButton?: boolean;
    shouldDisplayEpisodeInfo?: boolean;
    hasPlayableAction?: boolean;
    shouldDisplayReminderButton?: boolean;
    isShareDropdownVisible?: boolean;
    fetchingButtonData?: boolean;
    isAssetInProgress?: boolean;
    episodeToWatch?: Asset;
    isLiveBroadcast: boolean;
    primaryButtonType: PrimaryButtonType;
    actionButtonTitle: string;
    onRatingAction: (rating?: Rating) => void;
    onRemoveRatingAction: () => void;
    setRatingModalVisibility?: (isVisible: boolean) => void;
    setShareDropdownVisibility?: (isVisible: boolean) => void;
    onShare: () => void;
}

export type DetailContentViewProps = DetailsContentProps & DetailsContentModelProps;
