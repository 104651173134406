import React, { ComponentType } from 'react';
import useErrorModal from '../../hooks/useErrorModal';

export interface WithErrorModalProps {
    openErrorModal: (
        title: string,
        description: string,
        defaultHorizontalButtonLayout?: boolean,
        closeButtonLabel?: string
    ) => void;
    onCloseErrorModal?: () => void;
}

const withErrorModal =
    <P extends WithErrorModalProps>(Component: ComponentType<P>) =>
    // eslint-disable-next-line react/display-name
    (props: Omit<P, 'openErrorModal'>) => {
        const { onCloseErrorModal } = props;
        const { openErrorModal } = useErrorModal({ onCloseErrorModal });

        return <Component {...(props as P)} openErrorModal={openErrorModal} />;
    };

export default withErrorModal;
