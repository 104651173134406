import { useEffect, useRef } from 'react';
import { PageSection, PRODUCT_SCREENS, Asset } from '@24i/nxg-sdk-photon';
import {
    onAnalytics,
    ANALYTICS_TRIGGERS,
    ANALYTICS_EVENTS,
} from '@24i/nxg-sdk-smartott-shared/src/analytics';
import { isPlatformWeb } from 'renative';
import { useSessionId } from '@24i/nxg-sdk-smartott-shared/src/analytics/hooks/useSessionId';
import { SOTT_DEFAULT_SCREENS } from '@24i/nxg-sdk-smartott/src/navigation/constants';

type TrackBufferingProps = {
    asset?:
        | Asset
        | {
              id: string;
              title?: string;
          };
    sections?: PageSection[];
    screen: PRODUCT_SCREENS;
};

export const useTrackBuffering = ({ asset, sections, screen }: TrackBufferingProps) => {
    const bufferingStarted = useRef(false);
    const enableAnalytics = useRef(true);
    const { sessionId } = useSessionId();

    if (!bufferingStarted.current) {
        bufferingStarted.current = true;
        onAnalytics(ANALYTICS_TRIGGERS.SCREEN_BUFFERING_START, {
            screen:
                screen === PRODUCT_SCREENS.DETAILS
                    ? ANALYTICS_EVENTS.DETAILS
                    : ANALYTICS_EVENTS.HOME,
            asset,
            sessionId,
        });
    }

    useEffect(() => {
        if (asset?.title) {
            onAnalytics(ANALYTICS_TRIGGERS.SCREEN_BUFFERING_FINISHED, {
                screen: ANALYTICS_EVENTS.DETAILS,
                asset,
                sessionId,
            });
            if (isPlatformWeb)
                onAnalytics(ANALYTICS_TRIGGERS.SCENE_VIEW, {
                    screen: SOTT_DEFAULT_SCREENS.DETAILS,
                    asset,
                    sessionId,
                });
        }
        if (sections?.length && enableAnalytics.current) {
            onAnalytics(ANALYTICS_TRIGGERS.SCREEN_BUFFERING_FINISHED, {
                screen: ANALYTICS_EVENTS.HOME,
                sessionId,
            });
            enableAnalytics.current = false;
        }
    }, [asset?.title, sections]);
};
