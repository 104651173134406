import { FlatList } from '@24i/nxg-sdk-quarks';
import React, { ReactElement } from 'react';
import { View } from 'react-native';
import { MvpdGridProps } from '../types';
import { getMvpdGridStyles } from '../styles';
import { AllMvpdsItemContent } from '../../AllMvpdsItemContent';
import { TierOneMvpdItemContent } from '../../TierOneMvpdItemContent';

export function MvpdGrid(props: MvpdGridProps): ReactElement {
    const { mvpds, seeAllProviders, theme, handleSelectProvider } = props;
    const styles = getMvpdGridStyles(theme, mvpds.length);
    const renderMvpdItem = ({ item, index }): ReactElement => {
        return (
            <View
                style={seeAllProviders ? styles.allMvpdsGridItem : styles.tierOneGridItem}
                key={`${item.id}${String(index)}`}
                // @ts-expect-error
                onClick={() => handleSelectProvider(item.id)}
            >
                {seeAllProviders ? (
                    <AllMvpdsItemContent mvpd={item} theme={theme} />
                ) : (
                    <TierOneMvpdItemContent mvpd={item} theme={theme} />
                )}
            </View>
        );
    };

    return (
        <View style={styles.mvpdGrid}>
            <style jsx>{`
                /* Chrome implementation */
                /* Width */
                :global(#mvpdGrid)::-webkit-scrollbar {
                    width: 4px;
                    background-color: transparent;
                }

                /* Track */
                :global(#mvpdGrid)::-webkit-scrollbar-track {
                    background-color: transparent;
                }

                /* Handle */
                :global(#mvpdGrid)::-webkit-scrollbar-thumb {
                    background: #d8d8d8;
                    opacity: 0.5;
                    border-radius: 1.5px;
                }
            `}</style>
            <FlatList
                nativeID="mvpdGrid"
                data={mvpds}
                key={seeAllProviders ? 'allMvpds' : 'tierTwoMvpds'}
                renderItem={renderMvpdItem}
                keyExtractor={(item) => item.key}
                numColumns={seeAllProviders ? 0 : 2}
                columnWrapperStyle={seeAllProviders ? undefined : styles.columnWrapperStyle}
            />
        </View>
    );
}
