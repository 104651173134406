import React from 'react';
import PropTypes from 'prop-types';
import { Text, Interactable, View, Icon } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import getPickerItemStyles from '../styles';

const PickerItem = ({
    title,
    onPress,
    color,
    styles: getStyles,
    additionalContainerStyles,
    additionalTextStyles,
    focusEnabled,
    isDropdown,
    iconSize,
    buttonTestID,
}) => {
    const Wrapper = focusEnabled ? Interactable : View;
    const { theme } = useTheme();
    const styles = getStyles(theme);

    return (
        <Wrapper
            activeOpacity={0.2}
            onPress={onPress}
            style={[styles.container, additionalContainerStyles]}
            testID={buttonTestID}
        >
            <Text style={[{ color, ...styles.textStyle }, additionalTextStyles]}>{title}</Text>
            {isDropdown && (
                <Icon
                    color={color}
                    style={{ opacity: 0.5 }}
                    size={iconSize || 30}
                    font={theme.icons.common.dropdownExpand?.iconFont}
                    name={theme.icons.common.dropdownExpand.iconName}
                />
            )}
        </Wrapper>
    );
};

PickerItem.defaultProps = {
    color: '#fff',
    onPress: () => {},
    additionalContainerStyles: {},
    additionalTextStyles: {},
    focusEnabled: true,
    isDropdown: false,
    styles: getPickerItemStyles,
    buttonTestID: '',
};
PickerItem.propTypes = {
    color: PropTypes.string,
    title: PropTypes.string.isRequired,
    onPress: PropTypes.func,
    focusEnabled: PropTypes.bool,
    isDropdown: PropTypes.bool,
    additionalContainerStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    additionalTextStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    styles: PropTypes.func,
    buttonTestID: PropTypes.string,
};

export { getPickerItemStyles };
export default overridable(PickerItem, 'PickerItem');
