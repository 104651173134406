import React, { useState } from 'react';
import { Text, View, Image, Icon, Interactable } from '@24i/nxg-sdk-quarks';
import Modal from 'react-native-modal';
import LinearGradient from 'react-native-linear-gradient';

import { useTheme } from '@24i/nxg-sdk-higgs';
import { useTranslation } from 'react-i18next';
import { gradientColors } from '@24i/nxg-core-utils';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';

import { useMediaStatus, useCastDevice } from '../../../../context/Cast';
import { CastMediaInfo } from '../../../../context/Cast/types';

import PlayerControls from '../components/ChromecastPlayerControls';
import getChromecastExpandedControllerStyles from '../styles';
import { CHROMECAST_EXPANDED_CONTROLLER_TEST_IDS } from '../types';

const ChromecastExpandedController = ({
    isVisible = false,
    onClosePress,
    styles: getStyles = getChromecastExpandedControllerStyles,
    onDialogPress,
}) => {
    const { t } = useTranslation(['sott']);
    const [audioSubtitleModalVisible, setAudioSubtitleModalvisible] = useState(false);
    const swipeable = audioSubtitleModalVisible ? undefined : 'down';
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const status = useMediaStatus();
    const device = useCastDevice();
    const mediaInfo = status?.mediaInfo as CastMediaInfo;
    const metaData = mediaInfo?.metadata;

    return (
        <Modal
            isVisible={isVisible}
            onSwipeComplete={onClosePress}
            swipeDirection={swipeable}
            backdropColor={theme.color.primaryBlur1.color}
            style={styles.modal}
        >
            <View style={styles.mainContainer} pointerEvents="auto">
                <View style={styles.roundedModalContainer}>
                    <Image
                        source={{ uri: metaData?.images ? metaData?.images[0]?.url : '' }}
                        style={styles.image}
                    >
                        <>
                            <LinearGradient
                                colors={gradientColors(theme.color.background1).reverse()}
                                style={[styles.linearGradientStyles, { top: 0 }]}
                            />
                            <View style={styles.metadataContainer}>
                                <View style={styles.headerContainer}>
                                    <View style={styles.borderNotchContainer} />
                                    <View>
                                        <Text style={styles.title}>{metaData?.title}</Text>
                                        <Text style={styles.subtitle}>{metaData?.subtitle}</Text>
                                    </View>
                                    <View style={styles.castedDeviceContainer}>
                                        <Icon
                                            font={theme.icons.chromecast.castConnected?.iconFont}
                                            name={theme.icons.chromecast.castConnected.iconName}
                                            size={21}
                                            color={theme.color.textPrimary}
                                            style={styles.castIconStyles}
                                        />
                                        <Interactable
                                            onPress={onDialogPress}
                                            testID={
                                                CHROMECAST_EXPANDED_CONTROLLER_TEST_IDS.CAST_TO_BUTTON
                                            }
                                        >
                                            <Text style={styles.castedToTextStyles}>
                                                {`${t('chromecast.castingTo')} ${
                                                    device?.friendlyName
                                                }`}
                                            </Text>
                                        </Interactable>
                                    </View>
                                </View>
                                <PlayerControls
                                    audioSubtitleModalVisible={audioSubtitleModalVisible}
                                    setAudioSubtitleModalvisible={(value) => {
                                        setAudioSubtitleModalvisible(value);
                                    }}
                                />
                            </View>
                        </>
                    </Image>
                </View>
            </View>
        </Modal>
    );
};

export { getChromecastExpandedControllerStyles };
export default overridable(ChromecastExpandedController, 'ChromecastExpandedController');
