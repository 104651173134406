import React from 'react';
import PropTypes from 'prop-types';
import { Text, Interactable } from '@24i/nxg-sdk-quarks';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { withWebHeader } from '@24i/nxg-sdk-smartott/src/navigation/components/withWebHeader';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { firstLetterUppercase } from '@24i/nxg-core-utils';
import SettingsOptionPage from '../../SettingsOptionPage';
import getSettingsLanguageStyles from '../styles';

const SettingsLanguage = ({ styles: getStyles, title }) => {
    const { t, i18n } = useTranslation(['sott']);
    const [selectedLang, setSelectedLang] = React.useState(i18n.language);
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const renderOptions = () =>
        Object.keys(i18n.store.data).map((lang) => (
            <Interactable
                testID={`${t(`common.${lang}`).toLowerCase()}_button`}
                key={lang}
                style={[
                    {
                        opacity: lang === selectedLang ? 1 : 0.7,
                        ':hover': { opacity: 1 },
                    },
                    styles.listItem,
                ]}
                onPress={() => {
                    setSelectedLang(lang);
                    i18n.changeLanguage(lang);
                }}
            >
                <Text style={styles.listItemText}>
                    {firstLetterUppercase(t(`settings.language.${lang}`))}
                </Text>
            </Interactable>
        ));

    return (
        <SettingsOptionPage
            title={title}
            description={t('settings.language.description')}
            descriptionTestID="languageDescription"
            renderOptions={renderOptions}
            subtitle={firstLetterUppercase(t(`settings.language.${i18n.language}`))}
        />
    );
};

SettingsLanguage.propTypes = {
    styles: PropTypes.func,
    title: PropTypes.string,
    // The below types should come from expanding WithWebHeaderProps when this file is converted to TS
    webHeaderTitle: PropTypes.string,
    backButtonTitle: PropTypes.string,
    renderActionButton: PropTypes.func,
    fullWidth: PropTypes.bool,
};

SettingsLanguage.defaultProps = {
    styles: getSettingsLanguageStyles,
    title: 'No Title',
};

export { getSettingsLanguageStyles };
export default withWebHeader(overridable(SettingsLanguage, 'SettingsLanguage'));
