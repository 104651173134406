const getStyles = () => ({
    wrapper: {
        flex: 1,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        top: 30,
    },
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
    },
    loadingWrapper: {
        width: '100%',
        height: '100%',
    },
    collection: {
        top: -1,
    },
});

export default getStyles;
