import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Icon, Interactable, Text } from '@24i/nxg-sdk-quarks';
import React, { FC, useEffect } from 'react';
import ReactModal from 'react-modal';
import getModalStyles from '../styles/index.web';
import { ModalProps, MODAL_TEST_IDS } from '../types';

const Modal: FC<ModalProps> = ({
    visible,
    styles: getStyles = getModalStyles,
    children,
    modalTitle,
    onRequestClose,
    animationType,
    transparent = true,
    overrideDisplayIcon = true,
    ...props
}) => {
    const { theme } = useTheme();
    const styles = getStyles(theme, transparent, animationType);

    useEffect(() => {
        if (props?.name === 'search') {
            document.body.classList.replace(
                'ReactModal__Body--open',
                'ReactModal__Body--open__hidden'
            );
        } else {
            document.body.classList.remove('ReactModal__Body--open__hidden');
        }
    }, [props?.name]);

    return (
        <ReactModal
            testId="web-modal-test-id"
            style={{
                overlay: styles.overlay,
                content: styles.content,
            }}
            isOpen={visible}
            onRequestClose={onRequestClose}
            closeTimeoutMS={350}
            {...props}
        >
            {
                // @ts-ignore
                !!modalTitle && <Text style={styles.title}>{modalTitle}</Text>
            }
            {overrideDisplayIcon && onRequestClose && (
                <Interactable
                    onPress={onRequestClose}
                    // @ts-ignore
                    style={styles.closeBtn}
                    testID={MODAL_TEST_IDS.CLOSE_BUTTON}
                >
                    <Icon
                        style={styles.closeIcon}
                        color={theme?.color?.textPrimary}
                        size={32}
                        font={theme?.icons?.common?.modalClose?.iconFont}
                        name={theme?.icons?.common?.modalClose?.iconName}
                    />
                </Interactable>
            )}
            {children}
        </ReactModal>
    );
};

export { getModalStyles };
export default overridable(Modal, 'Modal');
