import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { Platform } from '@24i/nxg-sdk-quantum';
import { usePurchaseData } from '@24i/nxg-sdk-smartott-shared/src/context/PurchaseData';
import { useAppConfigQuery } from '@24i/nxg-sdk-smartott/src/hooks/query/useAppConfigQuery';
import { delay } from '@24i/nxg-core-utils';
import { useCallback, useState } from 'react';
import { skipNextPurchaseAnalytics } from '@24i/nxg-sdk-smartott-shared/src/analytics';
import {
    ConfirmPurchaseProps,
    ConfirmPurchaseViewProps,
    PurchaseError,
    PurchaseErrorType,
} from '../types';
import { useShared } from './shared';

export const useViewModel = (props: ConfirmPurchaseProps): ConfirmPurchaseViewProps => {
    const { selectedItem, isOnboarding, onPurchaseError } = props;

    const [isLoading, setIsLoading] = useState(false);
    const purchaseDataClient = usePurchaseData();
    const { appSettings } = useAppConfigQuery();
    const { offerId, isPinVerificationActive, setPinControlVisible, ...shared } = useShared(props);

    const inAppPurchases = appSettings?.features.inAppPurchases || {
        enabled: true,
    };
    const arePurchasesEnabled =
        inAppPurchases.enabled && !inAppPurchases.disabledPlatforms?.includes(Platform.OS);

    const router = useRouter();

    const checkout = useCallback(async () => {
        skipNextPurchaseAnalytics();
        setIsLoading(true);
        try {
            if (!offerId) {
                throw new Error(`Could not checkout. The subscription id is falsy!`);
            }

            const { url } = await purchaseDataClient.fetchExternalCheckoutLink(offerId);

            const [baseUrl, defaultParams] = url.split('?');
            const params = new URLSearchParams(defaultParams);
            const appUrl = window.location.origin;
            let redirectUri = `${appUrl}${router.asPath}?purchased=1`;
            if (isOnboarding) redirectUri += '&onboarding=true';

            const [id] = offerId.split('_');

            params.set('redirectUri', redirectUri);
            params.set('offerId', id);

            window.location.href = `${baseUrl}?${params}`;
            // For now never resolve this. At this point it should
            // redirect outside of the app.
            await delay(Infinity);
        } catch (error) {
            onPurchaseError?.(error);
        } finally {
            setIsLoading(false);
        }
    }, [selectedItem]);

    const onPurchaseConfirm = () => {
        if (!arePurchasesEnabled) {
            props.onPurchaseError?.(
                new PurchaseError(
                    'Purchases are not available for this platform',
                    PurchaseErrorType.UNAVAILABLE
                )
            );

            return;
        }

        if (isPinVerificationActive) {
            setPinControlVisible(true);
            return;
        }
        checkout();
    };

    const onSubmitPin = () => {
        setPinControlVisible(false);
        checkout();
    };

    return {
        isLoading,
        onPurchaseConfirm,
        onSubmitPin,
        ...props,
        ...shared,
    };
};
