import { Theme } from '@24i/nxg-sdk-photon';
import { getFont, getBorderRadius } from '@24i/nxg-sdk-higgs';
import { TextStyle, ViewStyle } from 'react-native';

export interface TitleViewWebStyle {
    holder: ViewStyle;
    title: TextStyle;
    liveBadge: TextStyle;
    channelLogo: ViewStyle;
    timeTextContainer: ViewStyle;
    timeText: TextStyle;
    subtitle: TextStyle;
    infoLabelContainer: ViewStyle;
    infoLabelText: TextStyle;
    rating: TextStyle;
    assetLabelContainer: ViewStyle;
}

const webStyles = (theme: Theme): TitleViewWebStyle => ({
    holder: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        color: theme.color.textPrimary,
        marginTop: 20,
        marginBottom: 16,
        ...getFont(theme, 'hero'),
    },
    liveBadge: {
        color: 'white',
        backgroundColor: theme.color.progressBarLive,
        marginLeft: 10,
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 5,
        paddingRight: 5,
        borderRadius: 4,
        ...getBorderRadius(theme.elements, 'label-cornerRadius'),
        overflow: 'hidden',
        textAlign: 'center',
        textAlignVertical: 'center',
        alignSelf: 'center',
        ...getFont(theme, 'h6'),
    },
    channelLogo: {
        width: 56,
        height: 56,
    },
    assetLabelContainer: {
        marginTop: 15,
        alignSelf: 'flex-start',
    },
    timeTextContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 8,
        marginBottom: 16,
    },
    timeText: {
        color: theme.color.textPrimary,
        fontSize: 14,
        fontWeight: 'bold',
        ...getFont(theme, 'h6'),
    },
    subtitle: {
        color: `${theme.color.textPrimary}99`,
        fontWeight: 'bold',
        ...getFont(theme, 'h4'),
        alignItems: 'center',
    },
    infoLabelContainer: {
        backgroundColor: theme.color.labelPrimary || 'rgba(255, 255, 255, 0.3)',
        borderRadius: 4,
        ...getBorderRadius(theme.elements, 'label-cornerRadius'),
        position: 'absolute',
        top: -16,
    },
    infoLabelText: {
        ...getFont(theme, 'h6'),
        color: theme.color.textPrimary,
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: 4,
        paddingRight: 4,
        fontWeight: 'bold',
    },
    rating: {
        color: theme.color.textPrimary,
        fontSize: 16,
        letterSpacing: 2,
    },
});

export { webStyles as getPlayerTitleView };
export default webStyles;
