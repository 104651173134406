import { ViewStyleWeb } from '@24i/nxg-sdk-smartott/src/utils/styles/types';
import React from 'react';

interface ArrowProps {
    orientation: 'left' | 'right';
    isEnabled: boolean;
    isHovered?: boolean;
    outterWrapperStyle?: ViewStyleWeb;
    outterArrowStyle?: ViewStyleWeb;
    onClick?: () => void;
}

export const Arrow = ({
    orientation,
    isEnabled,
    isHovered,
    outterArrowStyle,
    outterWrapperStyle,
    onClick,
}: ArrowProps) => {
    // Inlining styles because this is the purpose of this component
    // Move to a separate file if this component needs some logic
    const wrapperStyle: ViewStyleWeb = isHovered
        ? {
              width: 26,
              height: 26,
          }
        : {
              width: 22,
              height: 22,
          };

    const arrowStyle: ViewStyleWeb = {
        borderRightWidth: 3,
        borderBottomWidth: 3,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderColor: 'white',
        borderStyle: 'solid',
        padding: 3,
        opacity: isEnabled && isHovered ? 1 : 0.6,
        boxSizing: 'border-box',
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        transform: orientation === 'left' ? 'rotate(135deg)' : 'rotate(-45deg)',
        transitionProperty: 'opacity',
        transitionDuration: '0.1s',
        transitionTimingFunction: 'ease-out',
        transitionDelay: '0s',
    };

    return (
        <div
            role="button"
            style={{ ...wrapperStyle, ...outterWrapperStyle }}
            onClick={onClick}
            onKeyDown={onClick}
            tabIndex={0}
        >
            <div style={{ ...arrowStyle, ...outterArrowStyle }} />
        </div>
    );
};
