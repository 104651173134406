import React from 'react';
import { Icon, Interactable, View, Text, Image } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import getStyles from './styles';
import { SettingsButtonLargeProps } from './types';
import { normalizeTestId } from '../../../utils/testing';

const SettingsButtonLarge = ({ title, icon = {}, onPress, iconSize }: SettingsButtonLargeProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const { iconName, iconImage, iconFont } = icon;

    return (
        <Interactable
            activeOpacity={0.7}
            // eslint-disable-next-line react/no-array-index-key
            onPress={onPress}
            testID={normalizeTestId(title, 'button')}
        >
            <View style={styles.optionButton}>
                <View style={styles.buttonContainer}>
                    {iconImage ? (
                        <Image
                            resizeMode="contain"
                            style={{
                                width: iconSize,
                                height: iconSize,
                            }}
                            source={typeof iconImage === 'number' ? iconImage : { uri: iconImage }}
                        />
                    ) : (
                        <Icon
                            color={theme.color.textPrimary}
                            size={iconSize ?? 24}
                            name={iconName || ''}
                            font={iconFont || 'materialIcons'}
                            style={styles.optionIcon}
                        />
                    )}
                    <Text style={styles.subheaderText}>{title}</Text>
                </View>
            </View>
        </Interactable>
    );
};

export default SettingsButtonLarge;
