import { BaseApiParams, isRuntimeParams } from '@24i/nxg-core-utils/src/api';
import { DefaultBackstageClients } from './types';
import { createBackstageAnalyticsDataClient } from '../AnalyticsDataClient';
import { createBackstageAppSettingDataClient } from '../AppSettingsDataClient';
import { createBackstageAppStructureDataClient } from '../AppStructureDataClient';
import { ContentDataClient } from '../ContentDataClient';
import { createBackstageEntitlementsClient } from '../EntitlementsClient';
import { createBackstagePlayerDataClient } from '../PlayerDataClient';
import { createBackstagePurchaseDataClient } from '../PurchaseDataClient';
import { createBackstageThemeDataClient } from '../ThemeDataClient';
import { createBackstageUserDataClient } from '../UserDataClient';
import { createBackstageEpgDataClient } from '../EpgDataClient';
import { createBackstagePrimetimeDataClient } from '../PrimetimeDataClient';

export const getDefaultBackstageClients = (params: BaseApiParams): DefaultBackstageClients => {
    if (!isRuntimeParams(params))
        throw new Error(
            `Not possible to create default backstage clients. Please specify a valid service param.`
        );
    const analyticsDataClient = createBackstageAnalyticsDataClient(params);
    const appSettingsDataClient = createBackstageAppSettingDataClient(params);
    const appStructureDataClient = createBackstageAppStructureDataClient(params);
    const contentDataClient = new ContentDataClient(params);
    const entitlementsClient = createBackstageEntitlementsClient(params, contentDataClient);
    const playerDataClient = createBackstagePlayerDataClient(params);
    const purchaseDataClient = createBackstagePurchaseDataClient(params);
    const themeDataClient = createBackstageThemeDataClient(params);
    const userDataClient = createBackstageUserDataClient(params);
    const epgDataClient = createBackstageEpgDataClient(params);
    const primetimeDataClient = createBackstagePrimetimeDataClient(params);

    return {
        appStructureDataClient,
        appSettingsDataClient,
        analyticsDataClient,
        contentDataClient,
        entitlementsClient,
        playerDataClient,
        purchaseDataClient,
        themeDataClient,
        userDataClient,
        epgDataClient,
        primetimeDataClient,
    };
};
