import { PlaybackEvent, PlayerEvent } from '@24i/nxg-sdk-photon/src/analytics/player';
import { ISeriesData } from '@24i/nxg-sdk-smartott/src/screens/PlaybackScreen/managers';
import { useCommonEventParams } from '../../hooks/useCommonEventParams';
import { usePlayerSessionId } from '../usePlayerSessionId';

export interface CommonPlayerEventParams {
    asset?: PlayerEvent['asset'];
    source?: PlayerEvent['source'];
    currentTime: number;
    duration: number;
    seriesData?: ISeriesData;
}

export const useCommonPlayerEventParams = ({
    asset,
    source,
    currentTime,
    duration,
    seriesData,
}: CommonPlayerEventParams): Omit<PlaybackEvent, 'type'> | undefined => {
    const event = useCommonEventParams();
    const { playerSessionId } = usePlayerSessionId();
    const percentage = (currentTime / duration) * 100;

    if (!asset || !source) {
        return undefined;
    }

    return {
        ...event,
        currentPlaybackTimeMs: currentTime,
        percentOfPlayback: percentage,
        asset,
        source,
        playerSessionId: playerSessionId || '',
        duration,
        seriesData,
    };
};

export interface CommonPlayerEventTriggerParams {
    percentOfPlayback: number;
    currentPlaybackTimeMs: number;
    duration: number;
}
