/* eslint-disable react/display-name */
import React from 'react';
import { View, Text } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { isPlatformWeb } from 'renative';
import { useTranslation } from 'react-i18next';
import BackButton from '@24i/nxg-sdk-gluons/src/components/buttons/BackButton';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { useCurrentWebPageConfig } from '@24i/nxg-sdk-smartott/src/hooks/useCurrentWebPageConfig';
import getStyles from './styles';
import { WEB_HEADER_TEST_IDS, WithWebHeaderProps } from './types';

export const withWebHeader = <P extends WithWebHeaderProps>(Component: React.ComponentType<P>) => {
    return (props) => {
        const {
            webHeaderTitle,
            renderActionButton,
            fullWidth = false,
            backButtonTitle,
            hasMargin = true,
            forceShowHeader = false,
            forceDisable = false,
            onBackPressOverride,
        } = props;

        if (!isPlatformWeb || forceDisable) return <Component {...props} />;
        const { theme } = useTheme();
        const router = useRouter();
        const { t } = useTranslation();
        const { currentPageConfig } = useCurrentWebPageConfig();
        const styles = getStyles(theme, hasMargin);

        const containingMenuTitle = router?.query?.containingMenuTitle
            ? `${t('common.backTo')} ${router.query.containingMenuTitle}`
            : undefined;
        const backButtonText = backButtonTitle || containingMenuTitle;
        const showHeader = forceShowHeader || currentPageConfig?.optionsWeb?.headerShown;

        return (
            <View style={fullWidth ? styles.containerFullWidth : styles.container}>
                <View style={styles.headerContainer}>
                    <View style={styles.titleContainer}>
                        {backButtonText && (
                            <BackButton
                                onPress={() =>
                                    onBackPressOverride ? onBackPressOverride() : router.back()
                                }
                                icon={theme.icons.settings.back}
                                iconSize={styles.backButtonIcon.fontSize || 16}
                                title={backButtonText}
                                additionalTextStyles={styles.backButtonText}
                                additionalIconStyles={styles.backButtonIcon}
                                titleNumberOfLines={1}
                            />
                        )}
                        {showHeader && (
                            <Text
                                testID={WEB_HEADER_TEST_IDS.PAGE_TITLE}
                                style={backButtonText ? styles.headerText : styles.headerTextLarge}
                            >
                                {webHeaderTitle || currentPageConfig?.menuTitle}
                            </Text>
                        )}
                    </View>
                    <View style={styles.actionButton}>
                        {renderActionButton && renderActionButton()}
                    </View>
                </View>
                <Component {...props} />
            </View>
        );
    };
};
