import React, { useState, useRef } from 'react';
import { View } from '@24i/nxg-sdk-quarks';
import getDropdownPickerStyles from '../styles';
import { DropdownPickerProps } from '../types';
import DropdownButton from '../components/DropdownButton';
import DropdownMenu from '../components/DropdownMenu';

const DropdownPicker = (props: DropdownPickerProps) => {
    const {
        options,
        selectedItem,
        onSelectionPress,
        icon,
        iconSize,
        additionalButtonStyles,
        additionalContainerStyles,
        additionalTextStyles,
        buttonTestID = '',
        buttonKey = '',
        getItemsTestID,
        itemsPrefixKey = 'dropdownPicker-',
        dropdownButtonStyles,
        dropdownMenuStyles,
        styles: getStyles = getDropdownPickerStyles,
    } = props;

    const styles = getStyles();
    const [isListOpen, setListOpen] = useState(false);
    const completeScrollBarHeight = useRef(0);

    const toggleList = () => {
        setListOpen(!isListOpen);
        if (isListOpen) completeScrollBarHeight.current = 0;
    };

    const handleClickOutside = () => {
        setListOpen(false);
        completeScrollBarHeight.current = 0;
    };

    return (
        <View style={styles.container}>
            <DropdownButton
                title={selectedItem}
                onPress={toggleList}
                icon={icon}
                iconSize={iconSize}
                buttonTestID={buttonTestID}
                buttonKey={buttonKey}
                additionalButtonStyles={additionalButtonStyles}
                additionalTextStyles={additionalTextStyles}
                handleClickOutside={handleClickOutside}
                styles={dropdownButtonStyles}
            />
            {isListOpen && (
                <DropdownMenu
                    options={options}
                    selectedItem={selectedItem}
                    onSelectionPress={onSelectionPress}
                    setListOpen={setListOpen}
                    getItemsTestID={getItemsTestID}
                    itemsPrefixKey={itemsPrefixKey}
                    additionalContainerStyles={additionalContainerStyles}
                    completeScrollBarHeight={completeScrollBarHeight}
                    additionalTextStyles={additionalTextStyles}
                    styles={dropdownMenuStyles}
                />
            )}
        </View>
    );
};

export { getDropdownPickerStyles };
export default DropdownPicker;
