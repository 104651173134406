import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFirebase } from '@24i/nxg-sdk-smartott/src/context/Firebase';
import { Platform } from '@24i/nxg-sdk-quantum';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { isPlatformWeb } from 'renative';
import { ViewModelReturnType, MyContentScreenProps } from '../types';
import { useAppConfigQuery } from '../../../hooks/query/useAppConfigQuery';
import { useStore } from '../../../context/ApplicationStore';
import { useCurrentWebPageConfig } from '../../../hooks/useCurrentWebPageConfig';

// Cycle dependency ignored since we are importing other screens for default (ex, MyPurchases, MyList...) that the one we are importing (MyContent) in screenConfigBuilders
// eslint-disable-next-line import/no-cycle
import {
    buildMyListScreen,
    buildMyPurchaseScreen,
    buildRecentlyWatchedScreen,
    buildRecordingsScreen,
} from '../../../navigation/navigationConfig/screenConfigBuilders';

const useViewModel = (props: MyContentScreenProps): ViewModelReturnType => {
    const { subMenuScreens, route, ...rest } = props;
    const { crashlyticsLog } = useFirebase();
    const { appSettings } = useAppConfigQuery();
    const { t } = useTranslation();
    const { userData } = useStore();
    const { currentPageConfig } = useCurrentWebPageConfig();

    const inAppPurchases = appSettings?.features?.inAppPurchases || {
        enabled: true,
    };

    const arePurchasesEnabled =
        Boolean(inAppPurchases.enabled) && !inAppPurchases.disabledPlatforms?.includes(Platform.OS);

    const defaultSubscreensBuilders = [buildMyListScreen, buildRecentlyWatchedScreen];

    const router = useRouter();

    const params = isPlatformWeb ? router.query : {};

    if (arePurchasesEnabled) defaultSubscreensBuilders.push(buildMyPurchaseScreen);

    if (appSettings?.features?.recordings?.enabled)
        defaultSubscreensBuilders.push(buildRecordingsScreen);

    const defaultSubscreens = defaultSubscreensBuilders.map((builder) =>
        builder(null, {
            t,
            user: userData,
            features: appSettings?.features,
            params,
        })
    );

    const submenuTitle: string =
        route?.params?.title || currentPageConfig?.initialParams?.title || t('myContent.title');

    useEffect(() => {
        crashlyticsLog('MyContentScreen entered');
        return crashlyticsLog('MyContentScreen exited');
    }, []);

    return {
        // @ts-ignore
        subMenuScreens: subMenuScreens || defaultSubscreens,
        submenuTitle,
        ...rest,
    };
};

export { useViewModel };
