import { ColorValue } from 'react-native';
import { Ratio } from '@24i/nxg-sdk-higgs';
import { SectionStyleGetter } from './types';

const getSectionStyles: SectionStyleGetter = ({ theme }) => {
    return {
        sectionContent: {
            borderRadius: 8,
            flexDirection: 'column',
            padding: Ratio(32),
            borderWidth: 2,
        },
        containerFocused: {
            borderColor: theme.color.textPrimary,
            backgroundColor: theme.color.lighter4,
        },
        container: {
            borderColor: 'transparent',
        },
        divider: {
            marginTop: 16,
            borderBottomWidth: 0.5,
            borderBottomColor: <ColorValue>theme.color.buttonTertiary,
            borderRadius: 0.3,
        },
        tvContainer: {
            flexDirection: 'column',
            padding: Ratio(32),
        },
        interactableView: {
            marginBottom: 8,
        },
    };
};

export default getSectionStyles;
