import React from 'react';
import { Interactable, Text, View } from '@24i/nxg-sdk-quarks';
import { getFont, useTheme } from '@24i/nxg-sdk-higgs';

import IconButton from '@24i/nxg-sdk-gluons/src/components/buttons/IconButton';

import getManageButtonStyle from '../styles';

interface ManageButtonProps {
    title: string;
    isEditingDisable?: boolean;
    isEditing: boolean;
    onEdit: () => void;
}

const ManageButton = (props: ManageButtonProps) => {
    const { onEdit, isEditing, isEditingDisable, title } = props;

    const { theme } = useTheme();
    const styles = getManageButtonStyle(theme);
    const { icons, color } = theme;

    return (
        <View style={styles.wrapper}>
            <Interactable onPress={onEdit} style={styles.container}>
                <IconButton
                    color={color.textPrimary}
                    iconSize={getFont(theme, 'h4').fontSize}
                    icon={isEditing ? icons.packshot.check : icons.myContent.pencil}
                    onPress={onEdit}
                    additionalContainerStyles={isEditingDisable ? { opacity: 0 } : {}}
                    disabled={isEditingDisable}
                />
                <Text style={styles.title}>{title}</Text>
            </Interactable>
        </View>
    );
};

export { getManageButtonStyle };
export default ManageButton;
