import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Icon, Interactable, Text, View } from '@24i/nxg-sdk-quarks';
import React from 'react';
import { SEARCH_RECENT_TEST_IDS } from '../../../types';
import getSearchDropdownItemStyles from '../styles';
import { SearchDropdownItemProps } from './types';

const noop = () => {};

const SearchDropdownItem = ({
    item = '',
    onDropdownItemPress = noop,
    onRemovePress = noop,
    styles: getStyles = getSearchDropdownItemStyles,
    testID = '',
}: SearchDropdownItemProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);

    return (
        <View testID={testID} style={styles.container}>
            <Interactable onPress={() => onDropdownItemPress(item)} style={styles.button}>
                <Icon
                    style={styles.dropdownItemIcon}
                    font={theme.icons.search.dropdownItem?.iconFont}
                    name={theme.icons.search.dropdownItem.iconName}
                    size={20}
                />
                <Text
                    numberOfLines={1}
                    style={styles.text}
                    testID={SEARCH_RECENT_TEST_IDS.SEARCH_RECENT_TEXT}
                >
                    {item}
                </Text>
            </Interactable>
            <Interactable
                testID={SEARCH_RECENT_TEST_IDS.SEARCH_RECENT_X_BUTTON}
                onPress={() => onRemovePress(item)}
                style={styles.interectable}
            >
                <Icon
                    style={styles.clearIcon}
                    font={theme.icons.search.clear?.iconFont}
                    name={theme.icons.search.clear.iconName}
                    size={20}
                />
            </Interactable>
        </View>
    );
};

export { getSearchDropdownItemStyles };

export default overridable(SearchDropdownItem, 'SearchDropdownItem');
