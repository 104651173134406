import { Theme } from '@24i/nxg-sdk-photon';
import { getMargins } from '@24i/nxg-sdk-higgs';
import { EpgScreenStyles } from './types';

const PageEpg = (theme: Theme): EpgScreenStyles => ({
    container: {
        flex: 1,
        backgroundColor: theme.color.background1,
        height: '95%',
    },
    header: {
        ...getMargins(),
        marginTop: 95,
    },
});

export default PageEpg;
