import React, { useRef, useState } from 'react';
import { Animated } from 'react-native';
import { Interactable, Text, View, Icon, ScrollView } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import getDropdownMenuStyles from '../styles';
import { DropdownMenuProps } from '../../../types';
import { itemMagins } from '../../../../ModalPicker/components/ContentModal/styles';

const DropdownMenu = (props: DropdownMenuProps) => {
    const {
        options,
        completeScrollBarHeight,
        selectedItem,
        getItemsTestID,
        itemsPrefixKey,
        onSelectionPress,
        setListOpen,
        additionalTextStyles,
        iconSize,
        styles: getStyles = getDropdownMenuStyles,
        scrollEnabled = true,
        calculateOptionsHeight = () => {},
    } = props;

    const { theme } = useTheme();
    const styles = getStyles(theme);

    const [visibleScrollBarHeight, setVisibleScrollBarHeight] = useState(0);
    const scrollIndicator = useRef(new Animated.Value(0)).current;

    const showScrollIndicator = completeScrollBarHeight.current > visibleScrollBarHeight;

    const scrollIndicatorSize = showScrollIndicator
        ? (visibleScrollBarHeight * visibleScrollBarHeight) / completeScrollBarHeight.current
        : visibleScrollBarHeight;

    const difference =
        visibleScrollBarHeight > scrollIndicatorSize
            ? visibleScrollBarHeight - scrollIndicatorSize
            : 1;

    const scrollIndicatorPosition = Animated.multiply(
        scrollIndicator,
        visibleScrollBarHeight / completeScrollBarHeight.current
    ).interpolate({
        inputRange: [0, difference],
        outputRange: [0, difference],
        extrapolate: 'clamp',
    });

    const calculateScrollBarHeight = (sectionHeight: number) => {
        calculateOptionsHeight(sectionHeight);
    };

    return (
        <View style={styles.dropdownContainer}>
            <ScrollView
                showsVerticalScrollIndicator={false}
                scrollEnabled={scrollEnabled}
                onLayout={(e) => {
                    setVisibleScrollBarHeight(e.nativeEvent.layout.height);
                }}
                scrollEventThrottle={16}
                onScroll={Animated.event(
                    [{ nativeEvent: { contentOffset: { y: scrollIndicator } } }],
                    { useNativeDriver: false }
                )}
                contentContainerStyle={styles.additionalContainerStyles}
            >
                {options.map((item, index) => {
                    const isActiveItem = selectedItem === item || selectedItem === item.name;
                    const isFirstItem = index === 0;
                    const isLastItem = index === options.length - 1;

                    return (
                        <Interactable
                            testID={getItemsTestID && getItemsTestID(index)}
                            key={`${itemsPrefixKey}${item.name || item}`}
                            style={{
                                opacity: isActiveItem ? 1 : 0.7,
                                ...styles.pickerItem,
                                marginTop: isFirstItem ? itemMagins().top : 0,
                                marginBottom: isLastItem ? itemMagins().bottom : 0,
                            }}
                            onPress={() => {
                                onSelectionPress(index);
                                setListOpen(false);
                            }}
                            onLayout={(e) => {
                                calculateScrollBarHeight(e.nativeEvent.layout.height);
                            }}
                        >
                            {!!item.icon && (
                                <Icon
                                    font={item.icon?.iconFont}
                                    name={item.icon.iconName}
                                    color={theme.color.textPrimary}
                                    size={iconSize || 22}
                                    style={styles.itemIcon}
                                />
                            )}
                            <Text
                                numberOfLines={1}
                                style={{
                                    opacity: isActiveItem ? 1 : 0.8,
                                    ...styles.itemText,
                                    ...additionalTextStyles,
                                }}
                            >
                                {item.name || item}
                            </Text>
                        </Interactable>
                    );
                })}
            </ScrollView>
            {showScrollIndicator && (
                <View style={styles.scrollTrack}>
                    <Animated.View
                        style={[
                            styles.scrollIndicator,
                            {
                                height: scrollIndicatorSize,
                                transform: [{ translateY: scrollIndicatorPosition }],
                            },
                        ]}
                    />
                </View>
            )}
        </View>
    );
};

export { getDropdownMenuStyles };
export default DropdownMenu;
