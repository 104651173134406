import { getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon/src';
import { MobileProfilePickerStyles } from '../types';

const mobileProfilePickerStyles = (theme: Theme): MobileProfilePickerStyles => ({
    holder: {
        alignItems: 'center',
    },
    container: { flexDirection: 'row', marginLeft: 8 },
    flatListContainer: {
        marginTop: 30,
    },
    flatListContentContainer: {
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    manageBtn: {
        marginTop: 20,
        marginBottom: 32,
        backgroundColor: theme.color.buttonTertiary,
    },
    profileAvatarLetter: {
        color: '#fff',
        fontSize: 34,
        fontWeight: 'bold',
        ...getFont(theme, 'h1'),
    },
    changeProfileText: {
        color: theme.color.textPrimary,
        textAlign: 'left',
        marginTop: 5,
        ...getFont(theme, 'h5'),
    },
    divider: {
        height: 2,
        marginHorizontal: 20,
        backgroundColor: theme.color.lighter3,
    },
    profilePickerContainer: {
        marginRight: 12,
    },
});

export default mobileProfilePickerStyles;
