import { PlaybackEvent } from '@24i/nxg-sdk-photon/src/analytics/player';
import { useMonitoring } from '@24i/nxg-sdk-smartott-shared/src/context/MonitoringData';
import { onAnalytics, ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics';
import { AppState } from 'react-native';
import { isPlatformNative } from '@24i/nxg-core-utils';
import { ASSET_TYPE } from '@24i/nxg-sdk-photon';
import { useRef, useEffect } from 'react';
import { PlaybackStarted } from '@24i/nxg-core-utils/src/globalSingletons';
import { isPlatformWeb } from 'renative';
import { AnyPayload } from '@24i/nxg-sdk-photon/src/clients/AnalyticsClient';
import {
    CommonPlayerEventParams,
    useCommonPlayerEventParams,
    CommonPlayerEventTriggerParams,
} from '../useCommonPlayerEventParams';
import { usePlayerSessionId } from '../usePlayerSessionId';

export const useTriggerOnPlayerEvent = (
    params: CommonPlayerEventParams,
    eventType: PlaybackEvent['type']
) => {
    const event = useCommonPlayerEventParams(params);
    const { setEvent } = usePlayerSessionId();
    const { triggerMonitoringEvent } = useMonitoring();
    const eventRef = useRef<Omit<PlaybackEvent, 'type'> | undefined>();
    const enabledTriggering = useRef(true);

    if (event) {
        eventRef.current = event;
    }

    const handleAppStateChange = () => {
        if (PlaybackStarted.get()) {
            onAnalytics(ANALYTICS_TRIGGERS.APP_CLOSE, eventRef.current as AnyPayload);
            PlaybackStarted.set(false);
        }
    };

    useEffect(() => {
        if (isPlatformWeb) {
            window.addEventListener('beforeunload', handleAppStateChange);
            return () => {
                window.removeEventListener('beforeunload', handleAppStateChange);
            };
        }
        return () => {};
    }, []);

    const trigger: (params: CommonPlayerEventTriggerParams) => void = ({
        percentOfPlayback,
        currentPlaybackTimeMs,
        duration,
    }) => {
        if (!eventRef.current) {
            return;
        }

        const eventWithCurrentTime = {
            ...eventRef.current,
            percentOfPlayback,
            currentPlaybackTimeMs,
            duration,
        };

        setEvent(eventWithCurrentTime);
        triggerMonitoringEvent({ ...eventWithCurrentTime, type: eventType });

        if (!enabledTriggering.current && AppState.currentState === 'active') {
            enabledTriggering.current = true;
        }

        if (
            enabledTriggering.current &&
            AppState.currentState.match(/inactive|background/) &&
            eventType === 'PLAYBACK_PAUSE' &&
            PlaybackStarted.get()
        ) {
            onAnalytics(ANALYTICS_TRIGGERS.APP_CLOSE, eventWithCurrentTime);
            enabledTriggering.current = false;
            return;
        }

        if (!enabledTriggering.current) {
            return;
        }

        if (eventType === 'PLAYBACK_START') {
            PlaybackStarted.set(true);
        }

        if (eventType === 'PLAYBACK_PAUSE') {
            if (PlaybackStarted.get()) {
                onAnalytics(ANALYTICS_TRIGGERS.PLAYBACK_PAUSE, eventWithCurrentTime);
            }
            return;
        }

        if (eventType === 'PLAYBACK_STOP') {
            if (PlaybackStarted.get()) {
                onAnalytics(ANALYTICS_TRIGGERS.PLAYBACK_STOP, eventWithCurrentTime);
                onAnalytics(ANALYTICS_TRIGGERS.PLAYER_CLOSE, eventWithCurrentTime);
                PlaybackStarted.set(false);
                return;
            }
            return;
        }

        if (
            eventType === 'PLAYBACK_START' &&
            (isPlatformNative || eventRef.current.asset.type !== ASSET_TYPE.EPISODE)
        ) {
            onAnalytics(ANALYTICS_TRIGGERS.PLAYER_OPEN, eventWithCurrentTime);
            onAnalytics(ANALYTICS_TRIGGERS.PLAYBACK_START, eventWithCurrentTime);
            return;
        }

        if (eventType === 'PLAYBACK_START' && eventRef.current.asset.type === ASSET_TYPE.EPISODE) {
            return;
        }

        if (eventType === 'PLAYBACK_ENDED') {
            if (PlaybackStarted.get()) {
                onAnalytics(ANALYTICS_TRIGGERS.PLAYBACK_STOP, eventWithCurrentTime);
                PlaybackStarted.set(false);
                return;
            }
            return;
        }

        onAnalytics(ANALYTICS_TRIGGERS[eventType], eventWithCurrentTime);
    };

    return trigger;
};
