import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import TertiaryButtonWithoutBg from '@24i/nxg-sdk-gluons/src/components/buttons/TertiaryButtonWithoutBg';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { ViewStyle } from 'react-native';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Text, View } from '@24i/nxg-sdk-quarks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isPlatformIos, isPlatformWeb } from 'renative';
import { StandardButtons } from '../components/StandardButtons';
import getSignupScreenStyles from '../styles';
import { SIGNUP_TEST_IDS } from './types';
import Wrapper from './Wrapper';
import { SignupViewProps } from '../types';
import LoadingWrapper from '../../../components/LoadingWrapper/View/index';

const noop = () => null;

const SignupScreen = ({
    route,
    customNameErrorMessage,
    customEmailErrorMessage,
    customPasswordErrorMessage,
    email = '',
    styles: getStyles = getSignupScreenStyles,
    password = '',
    firstName = '',
    lastName = '',
    isLoading = false,
    wrapperProps = {
        behavior: isPlatformIos ? 'padding' : undefined,
        keyboardVerticalOffset: 25,
    },
    isSamePasswords = true,
    confirmPassword = '',
    isEmailIncorrect = false,
    displayErrorUnder = true,
    maxPasswordLength = 32,
    isLastNameIncorrect = false,
    isPasswordIncorrect = false,
    isFirstNameIncorrect = false,
    activeTextInputColor = null,
    inactiveTextInputColor = null,
    onSignUpPress = noop,
    onSignInPress = noop,
    renderButtons = StandardButtons,
    onEmailChange = noop,
    onPasswordChange = noop,
    onLastNameChange = noop,
    onFirstNameChange = noop,
    onConfirmPasswordChange = noop,
    shouldDisableRegisterButton = (isNotEmpty) => !isNotEmpty,
}: SignupViewProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme, displayErrorUnder);
    const { t } = useTranslation(['sott']);

    const isNotEmpty =
        email !== '' &&
        lastName !== '' &&
        firstName !== '' &&
        password !== '' &&
        confirmPassword !== '';

    const disabledOpacity = theme.backgroundImage ? 0.7 : 0.4;
    const registerButtonOpacity = shouldDisableRegisterButton(isNotEmpty) ? disabledOpacity : 1;

    const renderSubtitle = (subtitle: string, containerStyle: ViewStyle) => {
        return (
            <View style={containerStyle}>
                <Text style={styles.subtitle}>{subtitle}</Text>
            </View>
        );
    };

    return (
        // @ts-ignore The View shouls be Typed in the next stage of TS-Refactors with all the components.
        <Wrapper styles={styles} theme={theme} wrapperProps={wrapperProps} route={route}>
            {isPlatformWeb && (
                <View style={styles.headerContainer}>
                    <Text style={styles.headerText}>{t('register.title')}</Text>
                </View>
            )}
            <View style={[styles.screenContent, { flex: 1 }]}>
                <View>
                    {renderButtons({
                        t,
                        styles,
                        isSamePasswords,
                        isEmailIncorrect,
                        displayErrorUnder,
                        maxPasswordLength,
                        isLastNameIncorrect,
                        isPasswordIncorrect,
                        activeTextInputColor,
                        isFirstNameIncorrect,
                        inactiveTextInputColor,
                        customNameErrorMessage,
                        customEmailErrorMessage,
                        customPasswordErrorMessage,
                        onEmailChange,
                        onLastNameChange,
                        onPasswordChange,
                        onFirstNameChange,
                        onConfirmPasswordChange,
                        firstName,
                        lastName,
                        email,
                        password,
                        confirmPassword,
                    })}
                    {renderSubtitle(
                        t('password.add.formatValidation'),
                        // 'Use a minimum of 8 characters with at least one letter and one number or special character.',
                        styles.passwordInfoContainer
                    )}
                    <LoadingWrapper
                        isLoading={isLoading}
                        loaderProps={{
                            holderStyles: styles.loaderContainer,
                        }}
                    >
                        <ActionButton
                            testID={SIGNUP_TEST_IDS.CONFIRM_BUTTON}
                            title={t('register.title')}
                            onPress={() => {
                                onSignUpPress({
                                    firstName,
                                    lastName,
                                    email,
                                    password,
                                    confirmPassword,
                                });
                            }}
                            additionalContainerStyles={[
                                {
                                    opacity: registerButtonOpacity,
                                },
                                styles.signupButtonContainer,
                            ]}
                            additionalTextStyles={styles.signButtonText}
                            disabled={shouldDisableRegisterButton(isNotEmpty)}
                        />
                    </LoadingWrapper>
                </View>
                {renderSubtitle(
                    t('register.alreadyRegistered'),
                    styles.alreadyHaveAccountContainer
                )}
                <View style={styles.signInWrapper}>
                    <TertiaryButtonWithoutBg
                        testID={SIGNUP_TEST_IDS.SIGN_IN_BUTTON}
                        title={t('auth.signIn.title')}
                        onPress={() => onSignInPress()}
                        additionalContainerStyles={styles.signInButton}
                        additionalTextStyles={styles.signInButtonText}
                    />
                </View>
            </View>
        </Wrapper>
    );
};

export { getSignupScreenStyles };
export default overridable(SignupScreen, 'SignupScreen');
