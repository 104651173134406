import { UseMutateFunction, useMutation, useQueryClient } from 'react-query';
import { QUERY_KEYS, Recording, RecordingsFolder } from '@24i/nxg-sdk-photon/src';
import { showToast } from '@24i/nxg-sdk-gluons/src/components/ui/Toast';
import { useTranslation } from 'react-i18next';
import { useRecordings } from '@24i/nxg-sdk-smartott-shared/src/context/Recordings';
import { useMyRecordingsQuery } from './useMyRecordingsQuery';
import { getToastMessage } from './utils';
import { RecordingsData } from './types';

type RecordingData = RecordingsFolder & Recording;
type UseDeleteRecordingForBroadcastParams = {
    handleError?: (err: unknown) => void;
    successToast?: null;
};

export function useDeleteRecordingForBroadcast({
    handleError,
    successToast,
}: UseDeleteRecordingForBroadcastParams = {}): UseMutateFunction<
    void,
    unknown,
    RecordingsData,
    unknown
> {
    const { deleteRecordingForBroadcast } = useRecordings();
    const { updateRecordings } = useMyRecordingsQuery();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const { mutate } = useMutation(deleteRecordingForBroadcast, {
        onMutate: async (newData) => {
            // cancel any outgoing queries for recordings data, so old server data
            // doesn't overwrite our optimistic update.
            queryClient.cancelQueries(QUERY_KEYS.recordings);

            const previousRecordingData = queryClient.getQueryData<RecordingData[]>(
                QUERY_KEYS.recordings
            );

            const getUpdatedData = () => {
                // the asset is within a folder
                if (newData.folderId && previousRecordingData) {
                    const updatedData = previousRecordingData.map((recording) => {
                        if (recording.id === newData.folderId) {
                            return {
                                ...recording,
                                recordingIds: recording.recordingIds.filter(
                                    (id) => id !== newData.recordingId
                                ),
                            };
                        }

                        return recording;
                    });

                    return updatedData;
                }
                return previousRecordingData?.filter(({ assetId }) => assetId !== newData.id);
            };

            // optimistically update the cache with updated recordings
            updateRecordings(getUpdatedData());

            // return context object with snapshotted value
            return { previousRecordingData };
        },
        onError: (error, newData, context: any) => {
            // roll back cache to saved value
            if (context.previousRecordingData) {
                updateRecordings(context.previousRecordingData);
            }

            if (handleError) {
                handleError(error);
            } else {
                const errorMessage = error instanceof Error ? error.message : t('error.A00.body');
                showToast(errorMessage, {
                    type: 'error',
                });
            }
        },
        onSuccess: (error, newData) => {
            queryClient.invalidateQueries(QUERY_KEYS.storageInfo);
            queryClient.invalidateQueries([QUERY_KEYS.recordings, newData]);

            if (successToast !== null) {
                const duration = newData.end - newData.start;
                showToast(getToastMessage(duration, 1, t, newData.status));
            }
        },
        onSettled: () => {
            // invalidate recordings query to make sure were in sync with server data
            queryClient.invalidateQueries(QUERY_KEYS.recordings);
        },
    });

    return mutate;
}
