import React, { ReactElement } from 'react';
import { Icon, View, Text } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';

import getEmptyListPlaceholderStyles from '../styles';
import { EmptyListPlaceholderProps } from '../types';

const EmptyListPlaceholder = (props: EmptyListPlaceholderProps): ReactElement => {
    const {
        styles: getStyles = getEmptyListPlaceholderStyles,
        icon,
        title,
        description = undefined,
    } = props;

    const { theme } = useTheme();

    const styles = getStyles(theme);

    return (
        <View style={styles.container}>
            <View style={styles.iconContainer}>
                <Icon
                    name={icon.iconName}
                    font={icon?.iconFont}
                    color={theme.color.textPrimary}
                    style={styles.icon}
                />
            </View>
            <Text style={styles.primaryText}>{title}</Text>
            {description && <Text style={styles.secondaryText}>{description}</Text>}
        </View>
    );
};

export default EmptyListPlaceholder;
