/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/display-name */
import React from 'react';
import { View, Text, Interactable, Icon } from '@24i/nxg-sdk-quarks';
import TertiaryButtonWithoutBg from '@24i/nxg-sdk-gluons/src/components/buttons/TertiaryButtonWithoutBg';
import {
    WithPlatformWrapperAddedProps,
    WithPlatformWrapperProps,
    WithPlatformWrapperPropsWeb,
    WithPlatformWrapperBackProp,
} from './types';
import { SIGN_IN_TEST_IDS } from '../../View/types';

const defaultProps: Partial<WithPlatformWrapperProps> = {
    isDefaultLayout: true,
};

export default function withPlatformWrapper<TProps extends WithPlatformWrapperAddedProps>(
    WrappedComponent: React.ComponentType<TProps>
) {
    return class extends React.Component<
        WithPlatformWrapperPropsWeb &
            Omit<TProps, keyof WithPlatformWrapperAddedProps> &
            WithPlatformWrapperBackProp
    > {
        static defaultProps = defaultProps;

        render() {
            const {
                accountRegistrationEnabled,
                styles,
                t,
                theme,
                onSignupPress,
                isDefaultLayout,
                handleOnBack,
                enableCloseButton,
            } = this.props;

            return (
                <>
                    {enableCloseButton && (
                        <Interactable
                            style={styles.closeButton}
                            onPress={handleOnBack}
                            testID={SIGN_IN_TEST_IDS.CLOSE_BUTTON}
                        >
                            {!!theme.icons.common && (
                                <Icon
                                    font={theme.icons?.common?.modalClose?.iconFont}
                                    name={theme.icons?.common?.modalClose?.iconName}
                                    size={48}
                                    color={theme.color.textPrimary}
                                />
                            )}
                        </Interactable>
                    )}
                    <View style={styles.signinContainer}>
                        <View style={styles.headerSection}>
                            <Text numberOfLines={2} style={styles.headerTitle}>
                                {isDefaultLayout
                                    ? t('auth.signIn.title')
                                    : `Backstage ${t('auth.signIn.title')}`}
                            </Text>
                        </View>
                        {isDefaultLayout && (
                            <View>
                                <Text numberOfLines={2} style={styles.headerDescription}>
                                    {t('auth.signIn.description')}
                                </Text>
                            </View>
                        )}
                        <View style={styles.signinSection}>
                            <WrappedComponent
                                // We can do the typecast. The props are actually compatible, they just have some more potentially unused props
                                // https://github.com/microsoft/TypeScript/issues/35858
                                {...(this.props as unknown as TProps)}
                                landscape={false}
                                portrait={false}
                            />
                        </View>
                        {isDefaultLayout && accountRegistrationEnabled && (
                            <View style={styles.signupSection}>
                                <Text style={styles.signupTitle}>
                                    {t('auth.signIn.dontHaveAccount')}
                                </Text>
                                <TertiaryButtonWithoutBg
                                    title={t('auth.signIn.register')}
                                    additionalContainerStyles={styles.signupButton}
                                    additionalTextStyles={{
                                        color: theme.color.textPrimary,
                                        marginLeft: 0,
                                    }}
                                    onPress={onSignupPress}
                                />
                            </View>
                        )}
                    </View>
                </>
            );
        }
    };
}
