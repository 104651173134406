import { isPlatformWeb } from 'renative';
import { logo, fallbackViewChannel, fallbackViewTime } from '@24i/nxg-sdk-smartott-defaults';
import { Icon } from '@24i/nxg-sdk-photon';

const defaultLocalIcon: Icon = { iconFont: 'materialIcons', iconName: 'info' };

const elements = {
    clientLogo: {
        value: logo,
    },
    backgroundImage: { value: '' },
    'buttonPrimary-cornerRadius': {
        type: 'cornerRadius',
        value: '100px',
        label: 'Primary corner radius',
    },
    'buttonSecondary-cornerRadius': {
        type: 'cornerRadius',
        value: '100px',
        label: 'Secondary corner radius',
    },
    'buttonTertiary-cornerRadius': {
        type: 'cornerRadius',
        value: '100px',
        label: 'Tertiary corner radius',
    },
    'generalTile-cornerRadius': {
        type: 'cornerRadius',
        value: '8px',
        label: 'General corner radius',
    },
    'headerTile-cornerRadius': {
        type: 'cornerRadius',
        value: '8px',
        label: 'Header corner radius',
    },
    'textField-cornerRadius': {
        type: 'cornerRadius',
        value: '100px',
        label: 'Text corner radius',
    },
    'searchField-cornerRadius': {
        type: 'cornerRadius',
        value: '100px',
        label: 'Search corner radius',
    },
    'pinField-cornerRadius': {
        type: 'cornerRadius',
        value: '8px',
        label: 'PIN corner radius',
    },
    'epgItem-cornerRadius': {
        type: 'cornerRadius',
        value: '6px',
        label: 'EPG corner radius',
    },
    'label-cornerRadius': {
        type: 'cornerRadius',
        value: '4px',
        label: 'Label corner radius',
    },
    'progressBar-cornerRadius': {
        type: 'cornerRadius',
        value: '100px',
        label: 'Progress bar corner radius',
    },
};

const colors = {
    background1: {
        type: 'solid',
        color: 'rgba(37, 37, 37, 1)',
    },
    primary1: {
        type: 'solid',
        color: '#252525',
    },
    primary2: {
        type: 'solid',
        color: 'rgba(37,37,37,0.6)',
    },
    primary3: {
        type: 'solid',
        color: 'rgba(37,37,37,0.4)',
    },
    primary4: {
        type: 'solid',
        color: 'rgba(37,37,37,0.2)',
    },
    primaryBlur1: {
        type: 'backgroundBlur',
        color: 'rgba(31,31,31,0.75)',
        blur: 10,
    },
    primaryBlur1Fallback: {
        type: 'solid',
        color: '#212121',
    },
    primaryBlur2: {
        type: 'backgroundBlur',
        color: 'rgba(33,33,33,0.3)',
        blur: 10,
    },
    primaryBlur2Fallback: {
        type: 'solid',
        color: 'rgba(85,85,85,0.85)',
    },
    lighter1: {
        type: 'solid',
        color: '#505050',
    },
    lighter2: {
        type: 'solid',
        color: 'rgba(80,80,80,0.8)',
    },
    lighter3: {
        type: 'solid',
        color: 'rgba(80,80,80,0.6)',
    },
    lighter4: {
        type: 'solid',
        color: 'rgba(80,80,80,0.4)',
    },
    lighterBlur1: {
        type: 'backgroundBlur',
        color: 'rgba(80,80,80,0.7)',
        blur: 40,
    },
    lighterBlur1Fallback: {
        type: 'solid',
        color: '#505050',
    },
    lighterBlur2: {
        type: 'backgroundBlur',
        color: 'rgba(69,69,69,0.4)',
        blur: 40,
    },
    lighterBlur2Fallback: {
        type: 'solid',
        color: 'rgba(53,53,53,0.85)',
    },
    darker1: {
        type: 'solid',
        color: '#000000',
    },
    darker2: {
        type: 'solid',
        color: 'rgba(0,0,0,0.8)',
    },
    darker3: {
        type: 'solid',
        color: '#000000',
    },
    darker4: {
        type: 'solid',
        color: 'rgba(0,0,0,0.3)',
    },
    darkerBlur1: {
        type: 'backgroundBlur',
        color: 'rgba(21,21,21,0.93)',
        blur: 40,
    },
    darkerBlur1Fallback: {
        type: 'solid',
        color: 'rgba(21,21,21,0.93)',
    },
    darkerBlur2: {
        type: 'backgroundBlur',
        color: 'rgba(21,21,21,0.93)',
        blur: 40,
    },
    darkerBlur2Fallback: {
        type: 'solid',
        color: '#252525',
    },
    contrast1: {
        type: 'solid',
        color: '#ffffff',
    },
    contrast2: {
        type: 'solid',
        color: 'rgba(255,255,255,0.6)',
    },
    contrast3: {
        type: 'solid',
        color: 'rgba(255,255,255,0.4)',
    },
    contrast4: {
        type: 'solid',
        color: 'rgba(255,255,255,0.1)',
    },
    contrast5: {
        type: 'solid',
        color: 'rgba(255, 255, 255, 0.8)',
    },
    contrastBlur1: {
        type: 'backgroundBlur',
        color: 'rgba(255,255,255,0.75)',
        blur: 40,
    },
    contrastBlur1Fallback: {
        type: 'solid',
        color: 'rgba(255,255,255,0.9)',
    },
    contrastBlur2: {
        type: 'backgroundBlur',
        color: 'rgba(255,255,255,0.3)',
        blur: 40,
    },
    contrastBlur2Fallback: {
        type: 'solid',
        color: 'rgba(255,255,255,0.9)',
    },
    gradient1: {
        type: 'gradient',
        colors: ['rgba(0, 0, 0, 0)', 'rgba(0, 0, 1, 1)'],
        color: 'rgba(255,255,255,0.9)',
        percentages: [0, 1],
    },
    gradient2: {
        type: 'gradient',
        colors: ['rgba(0, 0, 0, 0.7)', 'rgba(0, 0, 0, 0)'],
        color: 'rgba(255,255,255,0.9)',
        percentages: [0, 1],
    },
    gradient3: {
        type: 'gradient',
        colors: ['rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0.4)'],
        color: 'rgba(255,255,255,0.9)',
        percentages: [0, 1],
    },
    error: {
        type: 'solid',
        color: '#E72819',
    },
    warning: {
        type: 'solid',
        color: '#F4AC13',
    },
    success: {
        type: 'solid',
        color: '#3ba700',
    },
    textPrimary: {
        type: 'solid',
        color: '#ffffff',
    },
    textSecondary: {
        type: 'solid',
        color: '#ffffff',
    },
    textButtonPrimary: {
        type: 'solid',
        color: '#252525',
    },
    textButtonSecondary: {
        type: 'solid',
        color: '#ffffff',
    },
    textButtonTertiary: {
        type: 'solid',
        color: '#ffffff',
    },
    'textMenuItem-active': {
        type: 'solid',
        color: '#ffffff',
    },
    'textMenuItem-inactive': {
        type: 'solid',
        color: '#ffffff',
    },
    buttonPrimary: {
        type: 'solid',
        color: '#ffffff',
    },
    buttonSecondary: {
        type: 'solid',
        color: 'rgba(60,60,60,0.7)',
    },
    buttonTertiary: {
        type: 'solid',
        color: 'rgba(60,60,60,0.7)',
    },
    labelPrimary: {
        type: 'solid',
        color: '#D0021b',
    },
    labelSecondary: {
        type: 'solid',
        color: 'rgba(255,255,255,0.4)',
    },
    'progressBar-accent': {
        type: 'solid',
        color: '#ffffff',
    },
    'progressBar-preAccent': {
        type: 'solid',
        color: '#ffffff',
    },
    'progressBar-live': {
        type: 'solid',
        color: '#D0021B',
    },
    'progressBar-background': {
        type: 'solid',
        color: 'rgba(255,255,255,0.3)',
    },
    menuFocus: {
        type: 'solid',
        color: 'rgba(255,255,255,0.2)',
    },
    'epgItem-active': {
        type: 'solid',
        color: 'rgba(255,208,208,0.2)',
    },
    'epgItem-inactive': {
        type: 'solid',
        color: 'rgba(255,255,255,0.08)',
    },
    tileMainButton: {
        type: 'solid',
        color: '#ffffff',
    },
    statusBarTheme: 'light-content',
};

const fonts = {
    S: {
        hero: {
            family: 'HK Grotesk',
            weight: '700',
            style: 'normal',
            size: 34,
            character: 0,
            line: 40,
            transform: 'none',
        },
        h1: {
            family: 'HK Grotesk',
            weight: '700',
            style: 'normal',
            size: 32,
            character: 0,
            line: 40,
            transform: 'none',
        },
        h2: {
            family: 'HK Grotesk',
            weight: '700',
            style: 'normal',
            size: 24,
            character: 0,
            line: 32,
            transform: 'none',
        },
        h3: {
            family: 'HK Grotesk',
            weight: '700',
            style: 'normal',
            size: 20,
            character: 0,
            line: 24,
            transform: 'none',
        },
        h4: {
            family: 'HK Grotesk',
            weight: '700',
            style: 'normal',
            size: 16,
            character: 0,
            line: 20,
            transform: 'none',
        },
        h5: {
            family: 'HK Grotesk',
            weight: '600',
            style: 'normal',
            size: 13,
            character: 0,
            line: 16,
            transform: 'none',
        },
        h6: {
            family: 'HK Grotesk',
            weight: '600',
            style: 'normal',
            size: 12,
            character: 0,
            line: 16,
            transform: 'uppercase',
        },
        body1: {
            family: 'HK Grotesk',
            weight: '500',
            style: 'normal',
            size: 16,
            character: 0,
            line: 24,
            transform: 'none',
        },
        body2: {
            family: 'HK Grotesk',
            weight: '500',
            style: 'normal',
            size: 14,
            character: 0,
            line: 19,
            transform: 'none',
        },
        primaryButton: {
            family: 'HK Grotesk',
            weight: '700',
            style: 'normal',
            size: 18,
            character: 0,
            line: 21,
            transform: 'uppercase',
        },
        secondaryButton: {
            family: 'HK Grotesk',
            weight: '700',
            style: 'normal',
            size: 18,
            character: 0,
            line: 21,
            transform: 'uppercase',
        },
        tertiaryButton: {
            family: 'HK Grotesk',
            weight: '600',
            style: 'normal',
            size: 14,
            character: 0,
            line: 24,
            transform: 'none',
        },
        'menuItem-active': {
            family: 'HK Grotesk',
            weight: '600',
            style: 'normal',
            size: 12,
            character: 0,
            line: 16,
            transform: 'none',
        },
        'menuItem-inactive': {
            family: 'HK Grotesk',
            weight: '600',
            style: 'normal',
            size: 12,
            character: 0,
            line: 16,
            transform: 'none',
        },
    },
    M: {
        hero: {
            family: 'HK Grotesk',
            weight: '700',
            style: 'normal',
            size: 48,
            character: 0,
            line: 56,
            transform: 'none',
        },
        h1: {
            family: 'HK Grotesk',
            weight: '700',
            style: 'normal',
            size: 32,
            character: 0,
            line: 40,
            transform: 'none',
        },
        h2: {
            family: 'HK Grotesk',
            weight: '700',
            style: 'normal',
            size: 24,
            character: 0,
            line: 32,
            transform: 'none',
        },
        h3: {
            family: 'HK Grotesk',
            weight: '700',
            style: 'normal',
            size: 20,
            character: 0,
            line: 24,
            transform: 'none',
        },
        h4: {
            family: 'HK Grotesk',
            weight: '700',
            style: 'normal',
            size: 16,
            character: 0,
            line: 20,
            transform: 'none',
        },
        h5: {
            family: 'HK Grotesk',
            weight: '600',
            style: 'normal',
            size: 13,
            character: 0,
            line: 16,
            transform: 'none',
        },
        h6: {
            family: 'HK Grotesk',
            weight: '600',
            style: 'normal',
            size: 12,
            character: 0,
            line: 16,
            transform: 'uppercase',
        },
        body1: {
            family: 'HK Grotesk',
            weight: '500',
            style: 'normal',
            size: 16,
            character: 0,
            line: 24,
            transform: 'none',
        },
        body2: {
            family: 'HK Grotesk',
            weight: '500',
            style: 'normal',
            size: 14,
            character: 0,
            line: 19,
            transform: 'none',
        },
        primaryButton: {
            family: 'HK Grotesk',
            weight: '700',
            style: 'normal',
            size: 18,
            character: 0,
            line: 21,
            transform: 'uppercase',
        },
        secondaryButton: {
            family: 'HK Grotesk',
            weight: '700',
            style: 'normal',
            size: 18,
            character: 0,
            line: 21,
            transform: 'uppercase',
        },
        tertiaryButton: {
            family: 'HK Grotesk',
            weight: '600',
            style: 'normal',
            size: 14,
            character: 0,
            line: 24,
            transform: 'none',
        },
        'menuItem-active': {
            family: 'HK Grotesk',
            weight: '600',
            style: 'normal',
            size: 14,
            character: 0,
            line: 20,
            transform: 'none',
        },
        'menuItem-inactive': {
            family: 'HK Grotesk',
            weight: '600',
            style: 'normal',
            size: 14,
            character: 0,
            line: 20,
            transform: 'none',
        },
    },
    L: {
        hero: {
            family: 'HK Grotesk',
            weight: '700',
            style: 'normal',
            size: 70,
            character: -0.58,
            line: 84,
            transform: 'none',
        },
        h1: {
            family: 'HK Grotesk',
            weight: '700',
            style: 'normal',
            size: 50,
            character: -0.42,
            line: 60,
            transform: 'none',
        },
        h2: {
            family: 'HK Grotesk',
            weight: '700',
            style: 'normal',
            size: 36,
            character: -0.3,
            line: 43,
            transform: 'none',
        },
        h3: {
            family: 'HK Grotesk',
            weight: '700',
            style: 'normal',
            size: 30,
            character: -0.25,
            line: 36,
            transform: 'none',
        },
        h4: {
            family: 'HK Grotesk',
            weight: '700',
            style: 'normal',
            size: 26,
            character: -0.22,
            line: 31,
            transform: 'none',
        },
        h5: {
            family: 'HK Grotesk',
            weight: '600',
            style: 'normal',
            size: 20,
            character: -0.17,
            line: 24,
            transform: 'none',
        },
        h6: {
            family: 'HK Grotesk',
            weight: '700',
            style: 'normal',
            size: 20,
            character: -0.17,
            line: 24,
            transform: 'none',
        },
        body1: {
            family: 'HK Grotesk',
            weight: '500',
            style: 'normal',
            size: 24,
            character: -0.2,
            line: 32,
            transform: 'none',
        },
        body2: {
            family: 'HK Grotesk',
            weight: '500',
            style: 'normal',
            size: 22,
            character: -0.18,
            line: 30,
            transform: 'none',
        },
        primaryButton: {
            family: 'HK Grotesk',
            weight: '700',
            style: 'normal',
            size: 28,
            character: -0.23,
            line: 33,
            transform: 'uppercase',
        },
        secondaryButton: {
            family: 'HK Grotesk',
            weight: '700',
            style: 'normal',
            size: 28,
            character: -0.23,
            line: 33,
            transform: 'uppercase',
        },
        tertiaryButton: {
            family: 'HK Grotesk',
            weight: '600',
            style: 'normal',
            size: 22,
            character: -0.18,
            line: 27,
            transform: 'none',
        },
        'menuItem-active': {
            family: 'HK Grotesk',
            weight: '600',
            style: 'normal',
            size: 14,
            character: 0,
            line: 24,
            transform: 'none',
        },
        'menuItem-inactive': {
            family: 'HK Grotesk',
            weight: '600',
            style: 'normal',
            size: 14,
            character: 0,
            line: 24,
            transform: 'none',
        },
    },
    XL: {
        hero: {
            family: 'HK Grotesk',
            weight: '700',
            style: 'normal',
            size: 100,
            character: 0,
            line: 112,
            transform: 'none',
        },
        h1: {
            family: 'HK Grotesk',
            weight: '700',
            style: 'normal',
            size: 80,
            character: 0,
            line: 96,
            transform: 'none',
        },
        h2: {
            family: 'HK Grotesk',
            weight: '700',
            style: 'normal',
            size: 48,
            character: 0,
            line: 56,
            transform: 'none',
        },
        h3: {
            family: 'HK Grotesk',
            weight: '700',
            style: 'normal',
            size: 32,
            character: 0,
            line: 40,
            transform: 'none',
        },
        h4: {
            family: 'HK Grotesk',
            weight: '700',
            style: 'normal',
            size: 28,
            character: 0,
            line: 38,
            transform: 'none',
        },
        h5: {
            family: 'HK Grotesk',
            weight: '700',
            style: 'normal',
            size: 24,
            character: 0,
            line: 30,
            transform: 'none',
        },
        h6: {
            family: 'HK Grotesk',
            weight: '700',
            style: 'normal',
            size: 24,
            character: 0,
            line: 30,
            transform: 'uppercase',
        },
        body1: {
            family: 'HK Grotesk',
            weight: '500',
            style: 'normal',
            size: 32,
            character: 0,
            line: 46,
            transform: 'none',
        },
        body2: {
            family: 'HK Grotesk',
            weight: '500',
            style: 'normal',
            size: 28,
            character: 0,
            line: 38,
            transform: 'none',
        },
        primaryButton: {
            family: 'HK Grotesk',
            weight: '700',
            style: 'normal',
            size: 28,
            character: 0,
            line: 32,
            transform: 'uppercase',
        },
        secondaryButton: {
            family: 'HK Grotesk',
            weight: '700',
            style: 'normal',
            size: 28,
            character: 0,
            line: 32,
            transform: 'uppercase',
        },
        tertiaryButton: {
            family: 'HK Grotesk',
            weight: '600',
            style: 'normal',
            size: 24,
            character: 0,
            line: 29,
            transform: 'none',
        },
        'menuItem-active': {
            family: 'HK Grotesk',
            weight: '600',
            style: 'normal',
            size: 36,
            character: 0,
            line: 44,
            transform: 'none',
        },
        'menuItem-inactive': {
            family: 'HK Grotesk',
            weight: '600',
            style: 'normal',
            size: 36,
            character: 0,
            line: 44,
            transform: 'none',
        },
    },
};

const icons = {
    menu: {
        navbarFont: 'fontAwesome',
        iconsMap: {
            dashboard: 'home',
            'new-movies': 'film',
            'live-tv': 'tv',
            search: 'search',
            'rewind-2': 'history',
            settings: 'cog',
            account: 'user',
            'play-button': 'play-circle',
            series: 'square',
            menu: 'bars',
            guide: 'list',
            'all-movies': 'film',
            mail: 'envelope',
            'no-notifications': 'volume-off',
            notifications: 'volume-up',
            clock: 'clock-o',
            'lock-disabled': 'unlock-alt',
            record: 'circle',
            min: 'minus-circle',
            plus: 'plus-circle',
            cross: 'remove',
            'rewind-1': 'backward',
            'fast-forward': 'forward',
            share: 'share-alt-square',
            subs: 'warning',
            chat: 'wechat',
            'episode-selector': 'warning',
            star: 'star-o',
            'star-full': 'star',
            circle: 'circle-o',
            'circle-full': 'circle',
            'stop-watching': 'eye-slash',
            info: 'info-circle',
            'record-1': 'warning',
            'record-2': 'warning',
            'record-3': 'warning',
            'record-4': 'circle',
            'half-moon': 'moon-o',
            space: 'window-minimize',
            signout: 'sign-out',
            backspace: 'window-close',
            'network-off': 'warning',
            bars: 'bars',
            heart: 'heart',
            'sign-in': 'sign-in',
            'angle-down': 'angle-down',
        },
        more: { iconFont: 'fontAwesome', iconName: 'bars' },
        forward: {
            iconFont: 'fontAwesome',
            iconName: 'chevron-right',
        },
        back: {
            iconFont: isPlatformWeb ? 'entypo' : 'materialIcons',
            iconName: isPlatformWeb ? 'chevron-thin-left' : 'chevron-left',
        },
        menuScrollUp: {
            iconFont: 'fontAwesome',
            iconName: 'chevron-up',
        },
        menuScrollDown: {
            iconFont: 'fontAwesome',
            iconName: 'chevron-down',
        },
    },
    player: {
        play: {
            iconFont: 'materialIcons',
            iconName: 'play-arrow',
        },
        pause: {
            iconFont: 'materialIcons',
            iconName: 'pause',
        },
        cast: {
            iconFont: 'materialIcons',
            iconName: 'cast',
        },
        settings: isPlatformWeb
            ? { iconFont: 'materialIcons', iconName: 'settings' }
            : { iconFont: 'fontAwesome', iconName: 'sliders' },
        seekForward: {
            iconFont: 'materialIcons',
            iconName: 'replay-10',
        },
        seekBackward: {
            iconFont: 'materialIcons',
            iconName: 'forward-10',
        },
        pictureInPicture: {
            iconFont: 'materialIcons',
            iconName: 'picture-in-picture-alt',
        },
        fullSchedule: {
            iconFont: 'materialIcons',
            iconName: 'dvr',
        },
        fullscreen: {
            iconFont: 'materialIcons',
            iconName: 'fullscreen-exit',
        },
        audioAndSubs: {
            iconFont: 'materialIcons',
            iconName: 'subtitles',
        },
        moreEpisodes: {
            iconFont: 'materialIcons',
            iconName: 'burst-mode',
        },
        previousEpisode: {
            iconFont: 'materialIcons',
            iconName: 'skip-previous',
        },
        nextEpisode: {
            iconFont: 'materialIcons',
            iconName: 'skip-next',
        },
        otherChannels: {
            iconFont: 'materialIcons',
            iconName: 'burst-mode',
        },
        record: {
            iconFont: 'materialIcons',
            iconName: 'fiber-manual-record',
        },
        startOver: {
            iconFont: 'materialIcons',
            iconName: 'replay',
        },
        volumeUp: {
            iconFont: 'materialIcons',
            iconName: 'volume-up',
        },
        volumeDown: {
            iconFont: 'materialIcons',
            iconName: 'volume-down',
        },
        volumeOff: {
            iconFont: 'materialIcons',
            iconName: 'volume-off',
        },
    },
    settings: {
        back: {
            iconFont: isPlatformWeb ? 'fontAwesome' : 'ionicons',
            iconName: isPlatformWeb ? 'angle-left' : 'ios-arrow-back',
        },
        checkmark: {
            iconFont: 'antDesign',
            iconName: 'check',
        },
        chevronRight: {
            iconFont: isPlatformWeb ? 'fontAwesome' : 'ionicons',
            iconName: isPlatformWeb ? 'angle-right' : 'ios-arrow-forward',
        },
        check: {
            iconFont: 'materialCommunityIcons',
            iconName: 'check',
        },
        close: {
            iconFont: 'antDesign',
            iconName: 'close',
        },
        iphone: {
            iconFont: 'materialIcons',
            iconName: 'phone-iphone',
        },
        android: {
            iconFont: 'materialIcons',
            iconName: 'phone-android',
        },
        ipad: {
            iconFont: 'materialCommunityIcons',
            iconName: 'tablet-ipad',
        },
        tabletAndroid: {
            iconFont: 'materialCommunityIcons',
            iconName: 'tablet-android',
        },
        laptop: {
            iconFont: 'materialCommunityIcons',
            iconName: 'laptop',
        },
        language: {
            iconFont: 'materialIcons',
            iconName: 'language',
        },
        info: {
            iconFont: 'materialIcons',
            iconName: 'info',
        },
        service: {
            iconFont: 'materialIcons',
            iconName: 'swap-horiz',
        },
        appTheme: {
            iconFont: 'materialIcons',
            iconName: 'palette',
        },
    },
    search: {
        dropdownItem: {
            iconFont: 'materialCommunityIcons',
            iconName: 'clock-outline',
        },
        input: {
            iconFont: 'materialIcons',
            iconName: 'search',
        },
        clear: {
            iconFont: 'materialIcons',
            iconName: 'clear',
        },
        cancel: {
            iconFont: 'materialIcons',
            iconName: 'cancel',
        },
    },
    details: {
        addToList: {
            iconFont: 'materialCommunityIcons',
            iconName: 'plus',
        },
        removeFromList: {
            iconFont: 'materialIcons',
            iconName: 'check',
        },
        record: {
            iconFont: 'materialCommunityIcons',
            iconName: 'record',
        },
        share: {
            iconFont: 'materialCommunityIcons',
            iconName: 'share-variant',
        },
        play: {
            iconFont: isPlatformWeb ? 'materialIcons' : 'ionicons',
            iconName: isPlatformWeb ? 'play-arrow' : 'play',
        },
        remind: {
            iconFont: 'materialIcons',
            iconName: 'alarm-add',
        },
        unrated: {
            iconFont: 'materialCommunityIcons',
            iconName: 'star-outline',
        },
        startOver: {
            iconFont: 'materialIcons',
            iconName: 'replay',
        },
        rated: {
            iconFont: 'materialCommunityIcons',
            iconName: 'star',
        },
        geoBlocked: {
            iconFont: 'materialIcons',
            iconName: 'vpn-lock',
        },
        cancelRecording: {
            iconFont: 'materialIcons',
            iconName: 'cancel',
        },
        deleteRecording: {
            iconFont: 'materialIcons',
            iconName: 'delete',
        },
        setReminder: {
            iconFont: 'materialIcons',
            iconName: 'alarm-add',
        },
        reminderIsSet: {
            iconFont: 'materialIcons',
            iconName: 'alarm-on',
        },
        purchase: {
            iconFont: 'materialIcons',
            iconName: 'shopping-cart',
        },
        rent: {
            iconFont: 'materialIcons',
            iconName: 'shopping-cart',
        },
    },
    myContent: {
        myList: { iconFont: 'materialCommunityIcons', iconName: 'plus' },
        downloads: {
            iconFont: 'materialCommunityIcons',
            iconName: 'cellphone-arrow-down',
        },
        recordings: { iconFont: 'materialCommunityIcons', iconName: 'record' },
        purchases: { iconFont: 'materialCommunityIcons', iconName: 'credit-card' },
        reminders: { iconFont: 'materialCommunityIcons', iconName: 'alarm-check' },
        recentlyWatched: {
            iconFont: 'materialCommunityIcons',
            iconName: 'history',
        },
        pencil: { iconFont: 'materialCommunityIcons', iconName: 'pencil' },
        trash: { iconFont: 'ionicons', iconName: 'md-trash' },
    },
    auth: {
        incorrect: {
            iconFont: 'antDesign',
            iconName: 'exclamationcircle',
        },
    },
    packshot: {
        moreInfo: {
            iconFont: isPlatformWeb ? 'materialIcons' : 'ionicons',
            iconName: isPlatformWeb ? 'info-outline' : 'ios-information-circle-outline',
        },
        play: {
            iconFont: 'materialIcons',
            iconName: 'play-arrow',
        },
        check: {
            iconFont: 'materialIcons',
            iconName: 'check',
        },
        delete: {
            iconFont: 'materialIcons',
            iconName: 'delete',
        },
        cancel: {
            iconFont: 'materialIcons',
            iconName: 'clear',
        },
    },
    epg: {
        chevronRight: {
            iconFont: 'materialIcons',
            iconName: 'chevron-right',
        },
        portraitChannelView: {
            iconImage: fallbackViewChannel,
            // iconStyle: { backgroundColor: 'green', width: 100 },
        },
        portraitTimeView: {
            iconImage: fallbackViewTime,
            // iconStyle: { backgroundColor: 'red', height: 40, width: 40 },
        },
        filter: {
            iconFont: 'materialIcons',
            iconName: 'list',
        },
        calendar: {
            iconFont: 'materialIcons',
            iconName: 'insert-invitation',
        },
    },
    common: {
        modalClose: {
            iconFont: 'materialIcons',
            iconName: 'close',
        },
        dropdownExpand: {
            iconFont: 'materialIcons',
            iconName: 'expand-more',
        },
        arrowUp: {
            iconFont: 'materialIcons',
            iconName: 'keyboard-arrow-up',
        },
        arrowDown: {
            iconFont: 'materialIcons',
            iconName: 'keyboard-arrow-down',
        },
    },
    about: {
        buttonIcon: {
            iconFont: 'materialCommunityIcons',
            iconName: 'exit-to-app',
        },
    },
    pinControl: {
        checkmark: {
            iconFont: 'fontAwesome',
            iconName: 'check',
        },
        backspace: {
            iconFont: 'materialIcons',
            iconName: 'backspace',
        },
    },
    account: {
        user: {
            iconFont: 'materialIcons',
            iconName: 'person',
        },
        subscriptions: {
            iconFont: 'materialIcons',
            iconName: 'credit-card',
        },
        parentalControl: {
            iconFont: 'materialIcons',
            iconName: 'face',
        },
        signin: {
            iconFont: 'materialIcons',
            iconName: 'login',
        },
        devices: {
            iconFont: 'materialCommunityIcons',
            iconName: 'devices',
        },
    },
    chromecast: {
        tv: {
            iconFont: 'materialIcons',
            iconName: 'tv',
        },
        volumeUp: {
            iconFont: 'materialIcons',
            iconName: 'volume-up',
        },
        volumeOff: {
            iconFont: 'materialIcons',
            iconName: 'volume-off',
        },
        volumeDown: {
            iconFont: 'materialIcons',
            iconName: 'volume-down',
        },
        castConnected: {
            iconFont: 'materialIcons',
            iconName: 'cast-connected',
        },
        skipPrev: {
            iconFont: 'materialIcons',
            iconName: 'skip-previous',
        },
        related: {
            iconFont: 'materialIcons',
            iconName: 'playlist-play',
        },
        subtitles: {
            iconFont: 'materialIcons',
            iconName: 'subtitles',
        },
        seekForward: {
            iconFont: 'materialIcons',
            iconName: 'forward-10',
        },
        seekBackward: {
            iconFont: 'materialIcons',
            iconName: 'replay-10',
        },
    },
    'onButtonPrimary-locked': defaultLocalIcon,
    'onButtonPrimary-play': defaultLocalIcon,
    'onButtonPrimary-purchase': defaultLocalIcon,
    'onButtonPrimary-purchase_multipleOptions': defaultLocalIcon,
    'onButtonPrimary-record': defaultLocalIcon,
    'onButtonPrimary-recordMultiple': defaultLocalIcon,
    'onButtonPrimary-recordMultiple_cancel': defaultLocalIcon,
    'onButtonPrimary-record_cancel': defaultLocalIcon,
    'onButtonPrimary-rent': defaultLocalIcon,
    'onButtonPrimary-upsell': defaultLocalIcon,
    'onButtonSecondary-download': defaultLocalIcon,
    'onButtonSecondary-info': defaultLocalIcon,
    'onButtonSecondary-record': defaultLocalIcon,
    'onButtonSecondary-recordMultiple': defaultLocalIcon,
    'onButtonSecondary-recordMultiple_cancel': defaultLocalIcon,
    'onButtonSecondary-record_cancel': defaultLocalIcon,
    'onButtonSecondary-reminder_add': defaultLocalIcon,
    'onButtonSecondary-reminder_added': defaultLocalIcon,
    'onButtonSecondary-share': defaultLocalIcon,
    'onButtonSecondary-startOver': defaultLocalIcon,
    'onButtonSecondary-watchlist_add': defaultLocalIcon,
    'onButtonSecondary-watchlist_added': defaultLocalIcon,
    'onButtonTertiary-download': defaultLocalIcon,
    'onButtonTertiary-info': defaultLocalIcon,
    'onButtonTertiary-record': defaultLocalIcon,
    'onButtonTertiary-recordMultiple': defaultLocalIcon,
    'onButtonTertiary-recordMultiple_cancel': defaultLocalIcon,
    'onButtonTertiary-record_cancel': defaultLocalIcon,
    'onButtonTertiary-reminder_add': defaultLocalIcon,
    'onButtonTertiary-reminder_added': defaultLocalIcon,
    'onButtonTertiary-share': defaultLocalIcon,
    'onButtonTertiary-startOver': defaultLocalIcon,
    'onButtonTertiary-watchlist_add': defaultLocalIcon,
    'onButtonTertiary-watchlist_added': defaultLocalIcon,
    'onDark-airplay': defaultLocalIcon,
    'onDark-allChannels': defaultLocalIcon,
    'onDark-allEpisodes': defaultLocalIcon,
    'onDark-back': defaultLocalIcon,
    'onDark-backToLive': defaultLocalIcon,
    'onDark-back_rtl': defaultLocalIcon,
    'onDark-catchup': defaultLocalIcon,
    'onDark-chromecast': defaultLocalIcon,
    'onDark-chromecast_connected': defaultLocalIcon,
    'onDark-close': defaultLocalIcon,
    'onDark-confirm': defaultLocalIcon,
    'onDark-control_fastForward': defaultLocalIcon,
    'onDark-control_forward10': defaultLocalIcon,
    'onDark-control_info': defaultLocalIcon,
    'onDark-control_pause': defaultLocalIcon,
    'onDark-control_play': defaultLocalIcon,
    'onDark-control_replay10': defaultLocalIcon,
    'onDark-control_rewind': defaultLocalIcon,
    'onDark-control_skipNext': defaultLocalIcon,
    'onDark-control_skipPrevious': defaultLocalIcon,
    'onDark-control_stop': defaultLocalIcon,
    'onDark-control_upsell': defaultLocalIcon,
    'onDark-control_volume_down': defaultLocalIcon,
    'onDark-control_volume_mute': defaultLocalIcon,
    'onDark-control_volume_off': defaultLocalIcon,
    'onDark-control_volume_up': defaultLocalIcon,
    'onDark-filter': defaultLocalIcon,
    'onDark-fullscreen': defaultLocalIcon,
    'onDark-fullscreen_exit': defaultLocalIcon,
    'onDark-overflow': defaultLocalIcon,
    'onDark-pictureInPicture': defaultLocalIcon,
    'onDark-rating': defaultLocalIcon,
    'onDark-record': defaultLocalIcon,
    'onDark-recordMultiple': defaultLocalIcon,
    'onDark-recordMultiple_cancel': defaultLocalIcon,
    'onDark-record_cancel': defaultLocalIcon,
    'onDark-select': defaultLocalIcon,
    'onDark-settings': defaultLocalIcon,
    'onDark-share': defaultLocalIcon,
    'onDark-subtitles': defaultLocalIcon,
    'onDark-tvGuide': defaultLocalIcon,
    'onLabel-catchup': defaultLocalIcon,
    'onLabel-locked': defaultLocalIcon,
    'onLabel-record': defaultLocalIcon,
    'onLabel-recordMultiple': defaultLocalIcon,
    'onLabel-reminder': defaultLocalIcon,
    'onPrimary-airplay': defaultLocalIcon,
    'onPrimary-back': defaultLocalIcon,
    'onPrimary-back_rtl': defaultLocalIcon,
    'onPrimary-chromecast': defaultLocalIcon,
    'onPrimary-chromecast_connected': defaultLocalIcon,
    'onPrimary-clear': defaultLocalIcon,
    'onPrimary-close': defaultLocalIcon,
    'onPrimary-confirm': defaultLocalIcon,
    'onPrimary-delete': defaultLocalIcon,
    'onPrimary-device_family': defaultLocalIcon,
    'onPrimary-device_gameConsole': defaultLocalIcon,
    'onPrimary-device_laptop': defaultLocalIcon,
    'onPrimary-device_smartphone': defaultLocalIcon,
    'onPrimary-device_smartwatch': defaultLocalIcon,
    'onPrimary-device_tablet': defaultLocalIcon,
    'onPrimary-device_tv': defaultLocalIcon,
    'onPrimary-error': defaultLocalIcon,
    'onPrimary-expiration': defaultLocalIcon,
    'onPrimary-field_clear': defaultLocalIcon,
    'onPrimary-field_search': defaultLocalIcon,
    'onPrimary-filter': defaultLocalIcon,
    'onPrimary-overflow': defaultLocalIcon,
    'onPrimary-rating': defaultLocalIcon,
    'onPrimary-scroll_down': defaultLocalIcon,
    'onPrimary-scroll_up': defaultLocalIcon,
    'onPrimary-seeAll': defaultLocalIcon,
    'onPrimary-select': defaultLocalIcon,
    'onPrimary-share': defaultLocalIcon,
    'onPrimary-signOut': defaultLocalIcon,
    'onPrimary-spinner': defaultLocalIcon,
    'onPrimary-subpage': defaultLocalIcon,
    'onPrimary-subpage_rtl': defaultLocalIcon,
    'onTileMainButton-edit': defaultLocalIcon,
    'onTileMainButton-play': defaultLocalIcon,
};

const defaultTheme = {
    id: 'default',
    name: 'Default',
    elements,
    colors,
    fonts,
    icons,
};

export type DefaultTheme = typeof defaultTheme;

export default defaultTheme;
