import { isThemeMenuIcon, isThemeMenuImage } from '@24i/nxg-sdk-photon/src/models/theme';
import { IconButtonIcon } from '@24i/nxg-sdk-quarks/src/types';
import { Theme } from '@24i/nxg-sdk-photon';

export const subMenuIcon = (theme: Theme, icon?: string): IconButtonIcon => {
    const iconsMapIcon = isThemeMenuIcon(icon, theme) ? theme.icons.menu.iconsMap[icon] : undefined;

    if (iconsMapIcon && isThemeMenuImage(iconsMapIcon)) {
        return {
            iconImage: iconsMapIcon.uri,
        };
    }

    return {
        iconName: iconsMapIcon?.name || icon,
        iconFont: iconsMapIcon?.font || 'materialIcons',
    };
};
