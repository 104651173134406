import React from 'react';
import Svg, { G, Path, SvgProps } from 'react-native-svg';

const CastFallbackImage = ({
    color = '#fff',
    width = 96,
    height = 97,
    opacity = 0.1,
    ...props
}: SvgProps) => (
    <Svg width={width} height={height} {...props}>
        <G fill="none" fillRule="evenodd" opacity={opacity}>
            <Path d="M0 .402h96v96H0z" />
            <Path
                d="M48 12.402c-19.882 0-36 16.118-36 36 0 9.808 3.924 18.698 10.286 25.191v10.809h51.43V73.593C80.075 67.1 84 58.21 84 48.402c0-19.882-16.118-36-36-36zm0 4.926c5.652 0 10.232 4.58 10.232 10.232 0 5.65-4.581 10.231-10.232 10.231-5.65 0-10.232-4.58-10.232-10.231S42.35 17.328 48 17.328zm10.231 26.148c5.652 0 10.232 4.605 10.232 10.285v25.715h-5.115v-18a2.565 2.565 0 0 0-2.559-2.571 2.565 2.565 0 0 0-2.558 2.57v18H37.769v-18a2.565 2.565 0 0 0-2.558-2.57 2.564 2.564 0 0 0-2.558 2.57v18h-5.116V53.762c0-5.68 4.58-10.285 10.232-10.285H58.23z"
                fill={color}
            />
        </G>
    </Svg>
);

export default CastFallbackImage;
