import { TextStyle } from 'react-native';

export enum SEPARATORS {
    DOT = '•',
    PIPE = '|',
    STAR = '*',
}
export interface TextWithSeparatorProps {
    children: React.ReactNode[];
    separator: SEPARATORS;
}

export interface TextWithSepratorStyles {
    separator: TextStyle;
    subtitle: TextStyle;
}
