import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import React from 'react';
import { Linking } from 'react-native';
import useRoute from '@24i/nxg-core-router/src/hooks/useRoute/index';
import { RouteProp } from '@react-navigation/core';
import { View } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { SubMenuStackParams, SubMenuStackScreens, ScreenWeb } from '../../stack';
import SettingsButtonLarge from '../../../../components/Buttons/SettingsButtonLarge';
import getStyles from './styles';
import { isExternalLinkValid } from '../../../../navigation/utils';
import { subMenuIcon } from './utils';
/**
 * This screens serves as a list of the screens available in the sub menu.
 * A link to each screen should be displayed via the list property.
 */
const SubMenuScreensList = ({ containingMenuTitle, subMenuScreens: subMenuScreensFromProps }) => {
    const router = useRouter();
    const { theme } = useTheme();
    const styles = getStyles();
    const { params } = useRoute<RouteProp<SubMenuStackParams, SubMenuStackScreens.ScreensList>>();

    const handleClick = async (navItem: ScreenWeb) => {
        const navItemWeb = navItem;

        if (navItem.onPress) {
            navItem.onPress();
            return;
        }

        if (navItemWeb?.webLink?.href && isExternalLinkValid(navItemWeb.webLink.href)) {
            await Linking.openURL(navItemWeb.webLink.href);
            return;
        }

        router.push({
            pathname: navItemWeb.webLink.href,
            query: {
                ...(navItemWeb.webLink.query || {}),
                containingMenuTitle,
            },
        });
    };

    const screensList = subMenuScreensFromProps ?? params.subMenuScreens;

    if (!screensList) return null;
    return (
        <View>
            <View style={styles.scrollContainer}>
                {screensList.map((navItem) => (
                    <View style={styles.itemContainer} key={navItem.title}>
                        <SettingsButtonLarge
                            title={navItem?.title || ''}
                            icon={subMenuIcon(theme, navItem.icon)}
                            onPress={() => handleClick(navItem)}
                            iconSize={40}
                        />
                    </View>
                ))}
            </View>
        </View>
    );
};

export default SubMenuScreensList;
