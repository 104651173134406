import { getFont, Ratio } from '@24i/nxg-sdk-higgs';
import { ColorValue } from 'react-native';
import { NameInputStyleGetter } from './types';

const getNameInputStyles: NameInputStyleGetter = ({ theme, hasText }) => {
    return {
        container: {
            flexDirection: 'column',
            backgroundColor: theme.color.lighter3,
            borderRadius: 24,
        },
        label: {
            color: <ColorValue>theme.color.textPrimary,
            fontWeight: 'bold',
            ...getFont(theme, 'h4'),
        },
        tvLabel: {
            opacity: 0.6,
            color: <ColorValue>theme.color.textPrimary,
            fontWeight: 'bold',
            ...getFont(theme, 'h6'),
        },
        tvProfileName: {
            color: <ColorValue>theme.color.textPrimary,
            fontWeight: 'bold',
            ...getFont(theme, 'h4'),
        },
        textInput: {
            color: theme.color.textPrimary,
            opacity: hasText ? 1 : 0.4,
            fontWeight: 'bold',
            paddingVertical: 12,
            paddingHorizontal: 24,
            ...getFont(theme, 'body1'),
        },
        focusedTextInput: {
            overflow: 'hidden',
            color: theme.color.textPrimary,
            fontWeight: 'bold',
            height: Ratio(90),
            borderRadius: 48,
            ...getFont(theme, 'h2'),
        },
        placeholderColor: theme.color.textPrimary,
        modalHeader: {
            paddingBottom: 48,
            color: theme.color.textPrimary,
            alignSelf: 'center',
            ...getFont(theme, 'h1'),
        },
        modalContainer: {
            width: 960,
        },
        labelContainer: {
            flex: 1,
            marginBottom: 8,
        },
        rowContainer: {
            paddingTop: 8,
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    };
};

export default getNameInputStyles;
