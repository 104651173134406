import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import SubactionButton from '@24i/nxg-sdk-gluons/src/components/buttons/SubactionButton';
import TertiaryButton from '@24i/nxg-sdk-gluons/src/components/buttons/TertiaryButton';
import Loader from '@24i/nxg-sdk-gluons/src/components/ui/Loader';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { LandingScreenConfig, Theme } from '@24i/nxg-sdk-photon';
import { useDimensions } from '@24i/nxg-sdk-quantum';
import { Image, ImageBackground, ScrollView, Text, View } from '@24i/nxg-sdk-quarks';
import { NavigationProp } from '@react-navigation/native';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NativeMethods } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { Breakpoint } from '../../../utils/styles/constants';
import { getLandingScreenViewStyles, LandingScreenViewStylesGetter } from '../styles';
import { LogoSizingOptions, LandingScreenStyles } from '../styles/types';
import { LANDING_SCREEN_TEST_IDS } from './types';

interface LandingScreenProps {
    isLoading?: boolean;
    isSignInEnabled?: boolean;
    landingScreenConfig: LandingScreenConfig | null;
    /** See the type description for more info */
    logoSizingOptions?: LogoSizingOptions;
    additionalLogoText?: string;
    renderLogoSection?: (props: LogoSectionProps) => React.ReactElement;
    renderTextSection?: (props: TextSectionProps) => React.ReactElement;
    renderButtonSection?: (props: ButtonSectionProps) => React.ReactElement;
    onSignInPress?: () => void;
    onSignUpPress?: () => void;
    onLookAroundPress?: () => void;
    headlineText?: string;
    subtitleText?: string;
    getStyles?: LandingScreenViewStylesGetter;
    navigation?: NavigationProp<any>;
}

interface LogoSectionProps {
    theme: Theme;
    styles: LandingScreenStyles;
    additionalLogoText?: string;
}

interface TextSectionProps {
    styles: LandingScreenStyles;
    headlineText?: string;
    subtitleText?: string;
    isSignInEnabled?: boolean;
}

interface ButtonSectionProps {
    styles: LandingScreenStyles;
    onSignInPress?: () => void;
    onSignUpPress?: () => void;
    onLookAroundPress?: () => void;
    signInButtonRef: React.RefObject<React.Component & NativeMethods>;
    signUpButtonRef: React.RefObject<React.Component & NativeMethods>;
    lookAroundButtonRef: React.RefObject<React.Component & NativeMethods>;
    isSignInEnabled?: boolean;
}

export const DefaultLogoSection = (props: LogoSectionProps) => {
    const { theme, styles, additionalLogoText } = props;
    return (
        <View style={styles.logoSection}>
            <Image
                testID={LANDING_SCREEN_TEST_IDS.LANDING_IMAGE_LOGO}
                style={styles.logoImage}
                source={typeof theme.logo === 'number' ? theme.logo : { uri: theme.logo }}
                resizeMode="contain"
            />
            {additionalLogoText && <Text style={styles.logoText}>{additionalLogoText}</Text>}
        </View>
    );
};

export const DefaultTextSection = (props: TextSectionProps) => {
    const { styles, headlineText, subtitleText, isSignInEnabled } = props;
    const { t } = useTranslation();
    return (
        <View style={styles.textSection}>
            <Text testID={LANDING_SCREEN_TEST_IDS.HEADLINE} style={styles.headlineText}>
                {headlineText || t('appStart.landing.title')}
            </Text>
            {isSignInEnabled && (
                <Text testID={LANDING_SCREEN_TEST_IDS.SUBTITLE} style={styles.subtitle}>
                    {subtitleText || t('appStart.landing.description')}
                </Text>
            )}
        </View>
    );
};
export const DefaultButtonSection = (props: ButtonSectionProps) => {
    const {
        styles,
        onSignInPress,
        onSignUpPress,
        onLookAroundPress,
        signInButtonRef,
        signUpButtonRef,
        lookAroundButtonRef,
        isSignInEnabled,
    } = props;
    const { t } = useTranslation();

    return (
        <View style={styles.buttonSection}>
            {isSignInEnabled && onSignInPress && (
                <ActionButton
                    testID={LANDING_SCREEN_TEST_IDS.SIGNIN_BUTTON}
                    buttonRef={signInButtonRef}
                    title={t('auth.signIn.title')}
                    styles={() => styles.signInButton}
                    onPress={onSignInPress}
                />
            )}
            {isSignInEnabled && onSignUpPress && (
                <SubactionButton
                    testID={LANDING_SCREEN_TEST_IDS.SIGNUP_BUTTON}
                    buttonRef={signUpButtonRef}
                    title={t('register.title')}
                    styles={() => styles.registerButton}
                    onPress={onSignUpPress}
                />
            )}
            {onLookAroundPress && (
                <>
                    {isSignInEnabled && <Text style={styles.orText}>{t('common.or')}</Text>}
                    <TertiaryButton
                        testID={LANDING_SCREEN_TEST_IDS.LOOK_AROUND_BUTTON}
                        buttonRef={lookAroundButtonRef}
                        title={t('appStart.landing.lookAround')}
                        styles={() => styles.lookAroundButton}
                        onPress={onLookAroundPress}
                    />
                </>
            )}
        </View>
    );
};

const LandingScreen = ({
    isLoading,
    logoSizingOptions = {
        // TODO: Pass this down from the app, this matches the current logo used by the SOTT experience
        logoImageWidth: 450,
        logoImageHeight: 350,
        logoContentHeight: 350,
    },
    additionalLogoText,
    renderLogoSection = DefaultLogoSection,
    renderTextSection = DefaultTextSection,
    renderButtonSection = DefaultButtonSection,
    landingScreenConfig,
    onSignInPress,
    onSignUpPress,
    headlineText,
    subtitleText,
    onLookAroundPress,
    getStyles = getLandingScreenViewStyles,
    navigation,
    isSignInEnabled,
}: LandingScreenProps) => {
    const { theme } = useTheme();
    const dimensions = useDimensions();
    const styles = getStyles(theme, { dimensions, logoSizingOptions });

    const signInButtonRef = useRef<React.Component & NativeMethods>(null);
    const signUpButtonRef = useRef<React.Component & NativeMethods>(null);
    const lookAroundButtonRef = useRef<React.Component & NativeMethods>(null);

    useEffect(() => {
        if (!navigation) {
            return undefined;
        }

        const unsubscribe = navigation.addListener('focus', () => {
            // When entering the page always set focus on the first item in order not to loose focus
        });

        return unsubscribe;
    }, []);

    if (isLoading) {
        return (
            <Loader
                additionalStyles={{
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            />
        );
    }

    const { backgroundImage } = landingScreenConfig || {};
    const backgroundImageToUse =
        dimensions.width < Breakpoint.SM ? backgroundImage?.portrait : backgroundImage?.landscape;
    return (
        <ImageBackground
            source={{
                // IOS can't handle null values
                uri: backgroundImageToUse || undefined,
            }}
            testID={LANDING_SCREEN_TEST_IDS.LANDING_IMAGE_BACKGROUND}
            style={styles.backgroundWrapper}
            imageStyle={styles.backgroundImageStyle}
        >
            <LinearGradient
                colors={['#000', '#00000065']}
                start={{ x: 0, y: 1 }}
                end={{ x: 0, y: 0 }}
                style={styles.backgroundWrapper}
            >
                <ScrollView
                    style={styles.scrollView}
                    contentContainerStyle={styles.scrollViewContent}
                >
                    <View style={styles.contentWrapper}>
                        {/* TODO: Move logo to gluons */}
                        {renderLogoSection({ theme, styles, additionalLogoText })}
                        <View style={styles.spacer} />
                        <View style={styles.mainContentSection}>
                            {renderTextSection({
                                styles,
                                headlineText,
                                subtitleText,
                                isSignInEnabled,
                            })}
                            {renderButtonSection({
                                styles,
                                onSignInPress,
                                onSignUpPress,
                                onLookAroundPress,
                                signInButtonRef,
                                signUpButtonRef,
                                lookAroundButtonRef,
                                isSignInEnabled,
                            })}
                        </View>
                    </View>
                </ScrollView>
            </LinearGradient>
        </ImageBackground>
    );
};

export default overridable(LandingScreen, 'LandingScreen');
