import React, { ReactElement, useState } from 'react';
import { Icon, Text, View, Interactable, Image } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useImage } from '@24i/nxg-sdk-gluons/src/context/ImageService';
import { isIconMapImage, IconMapItem } from '@24i/nxg-sdk-photon';
import { ViewStyle } from 'react-native';
import getMenuItemStyles, { getTopBarMenuItemStyles } from '../styles';
import { MenuItemPropsWeb, MenuItemStylesWeb } from '../types';

const MenuItem = React.forwardRef((props: MenuItemPropsWeb, ref: typeof MenuItem): ReactElement => {
    const {
        title = '',
        icon = null,
        onPress = () => null,
        styles: getStyles = getMenuItemStyles,
        isHovered: isHoveredFromProps,
        active = false,
        testID = null,
        additionalTextStyles,
        additionalItemStyles,
        hasDividerUnder,
        breakText = false,
        enlargeIcon = false,
    } = props;

    const [isItemHovered, setItemIsHovered] = useState(false);
    const isHovered = typeof isHoveredFromProps === 'boolean' ? isHoveredFromProps : isItemHovered;
    const { theme } = useTheme();
    const styles = getStyles(theme) as MenuItemStylesWeb;
    const { getImageUrl } = useImage();

    const menuItemIcon: IconMapItem = theme.icons.menu.iconsMap[icon || 'default'];

    const isValidImageUrl = (itemIcon?: string): boolean => {
        if (itemIcon?.match(/.(jpg|jpeg|png|gif|svg)$/i)) return true;
        return false;
    };

    const getTestId = () => {
        const itemTitle = title || icon;

        return itemTitle
            ? `${itemTitle.trim().toLowerCase().replace(/\s/g, '_')}_button`
            : undefined;
    };

    const defaultSize = {
        height: 25,
        width: 25,
    };

    const renderContent = (): ReactElement => (
        <View style={styles.itemContainer} testID={`topmenu-item-${title}`}>
            {menuItemIcon &&
                (isIconMapImage(menuItemIcon) ? (
                    <Image
                        style={[
                            styles.iconImage,
                            ...(active || isHovered ? ([styles.iconActive] as ViewStyle[]) : []),
                            ...(enlargeIcon ? [styles.enlargeIcon] : []),
                        ]}
                        source={{
                            uri: getImageUrl(
                                {
                                    ...defaultSize,
                                    imagePath:
                                        active && menuItemIcon.activeUri
                                            ? menuItemIcon.activeUri
                                            : menuItemIcon.uri,
                                },
                                [
                                    {
                                        url:
                                            active && menuItemIcon.activeUri
                                                ? menuItemIcon.activeUri
                                                : menuItemIcon.uri,
                                        size: defaultSize,
                                    },
                                ]
                            ),
                        }}
                        fallbackImage={() => (
                            <Icon
                                size={styles.icon?.fontSize || 25}
                                font="materialIcons"
                                name="image"
                                color={styles.icon?.color}
                            />
                        )}
                        hasFallbackGradient={false}
                    />
                ) : (
                    <View style={icon === 'plus' && styles.plusBackground}>
                        <Icon
                            style={[styles.icon, (active || isHovered) && styles.iconActive]}
                            color={styles.icon?.color}
                            size={styles.icon?.fontSize || 25}
                            {...menuItemIcon}
                        />
                    </View>
                ))}
            {!menuItemIcon && icon && isValidImageUrl(icon) && (
                <View style={styles.profileAvatarImageContainer}>
                    <Image
                        source={{
                            uri: icon,
                        }}
                    />
                </View>
            )}
            {!title ? null : (
                <View style={styles.titleContainer}>
                    <Text
                        numberOfLines={breakText ? 0 : 1}
                        style={[
                            styles.title,
                            (active || isHovered) && styles.titleActive,
                            additionalTextStyles,
                        ]}
                    >
                        {title}
                    </Text>
                </View>
            )}
            {hasDividerUnder && <View style={styles.lineDivider} />}
        </View>
    );

    return (
        <Interactable
            ref={ref}
            style={[styles.holder, active && styles.holderActive, additionalItemStyles]}
            onMouseEnter={() => setItemIsHovered(true)}
            onMouseLeave={() => setItemIsHovered(false)}
            onPress={onPress}
            testID={testID || getTestId()}
        >
            {renderContent()}
        </Interactable>
    );
});

MenuItem.displayName = 'MenuItem';

const TopBarMenuItem = (props: MenuItemPropsWeb): ReactElement => (
    <MenuItem styles={getTopBarMenuItemStyles} {...props} />
);
const OverridableTopBarMenu = overridable(TopBarMenuItem, 'TopBarMenuItem');
export { OverridableTopBarMenu as TopBarMenuItem, getTopBarMenuItemStyles, getMenuItemStyles };

export default overridable(MenuItem, 'MenuItem');
