import { getBorderRadius, getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { PinInputModalContentStyles } from '../types';

const getPinInputModalContentStyles = (theme: Theme): PinInputModalContentStyles => ({
    modalContainer: {
        margin: 'auto',
        width: 384,
        backgroundColor: theme.color.primary1,
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
    },
    modalFrame: {
        justifyContent: 'center',
        alignItems: 'center',
        padding: 30,
    },
    heading: {
        textAlign: 'center',
        color: theme.color.textPrimary,
        marginBottom: 40,
        ...getFont(theme, 'h2'),
    },
    pleaseEnterText: {
        textAlign: 'center',
        opacity: 0.6,
        color: theme.color.textPrimary,
        marginBottom: 24,
        ...getFont(theme, 'body2'),
    },
    profileContainer: {
        marginBottom: 24,
    },
    sequentialInputContainer: {
        flexDirection: 'row',
        marginBottom: 6,
    },
    focusedInput: {
        backgroundColor: theme.color.lighter3,
    },
    inputWrapper: {
        width: 56,
        height: 56,
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 6,
        textAlign: 'center',
        borderRadius: 4,
        backgroundColor: theme.color.lighter4,
        padding: 0,
        ...getBorderRadius(theme.elements, 'pinField-cornerRadius'),
    },
    input: {
        width: 56,
        height: 56,
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 11,
        borderRadius: 10,
        ...getBorderRadius(theme.elements, 'pinField-cornerRadius'),
        padding: 0,
        textAlign: 'center',
        fontSize: 36,
        color: 'white',
        backgroundColor: 'transparent',
    },
    focusUnderline: {
        backgroundColor: theme.color.buttonPrimary,
        justifyContent: 'center',
        alignItems: 'center',
        width: 35,
        height: 3,
        top: -7,
        left: 17,
    },
    incorrectText: {
        color: theme.color.error,
    },
    incorrectTextWrapper: {
        height: 32,
    },
    loader: {
        height: 32,
        backgroundColor: theme.color.primary1,
    },
    forgotButtonText: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'tertiaryButton'),
    },
});

export default getPinInputModalContentStyles;
