import { registerIconFont } from '@24i/nxg-sdk-quarks';

const fontName = 'materialCommunityIcons';

const fontIcon = require('react-native-vector-icons/MaterialCommunityIcons').default;
const fontTTL = require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf');

const registerTriangleIcon = () =>
    registerIconFont(fontName, fontIcon, fontTTL, 'MaterialCommunityIcons');

export default registerTriangleIcon;
