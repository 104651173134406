import React from 'react';

import { useTheme } from '@24i/nxg-sdk-higgs';
import { View, Text } from '@24i/nxg-sdk-quarks';
import CornerLogo from '@24i/nxg-sdk-gluons/src/components/ui/CornerLogo';
import getOnboardingWebHeaderStyles from '../styles';
import { OnboardingHeaderItemsEnum, OnboardingWebHeaderProps } from '../types';
import { TopBarMenuDropdown } from '../../../TopBarMenu';
import { DropdownItem } from '../../../TopBarMenu/components/TopBarMenuDropdown/types';

const OnboardingWebHeader = ({
    title,
    items,
    styles: getStyles = getOnboardingWebHeaderStyles,
    wrapperStyle,
    setSignoutModalVisible,
}: OnboardingWebHeaderProps) => {
    const { theme } = useTheme();

    const styles = getStyles(theme);

    const onListItemPress = (item: DropdownItem) => {
        if (item.name === OnboardingHeaderItemsEnum.Signout) setSignoutModalVisible(true);
    };

    const { iconFont, iconName } = theme.icons.account.user;

    return (
        <View style={[styles.headerContainer, wrapperStyle]}>
            <View nativeID="onboarding-topheader" style={styles.webNavMenu}>
                <CornerLogo isClickable={false} />
                <TopBarMenuDropdown
                    name="onboarding-header-menu"
                    icon={{ name: iconName, font: iconFont, size: 24 }}
                    items={items}
                    onListItemPress={onListItemPress}
                    isOnboarding
                />
            </View>
            <Text style={styles.headerTitle}>{title}</Text>
        </View>
    );
};

export default OnboardingWebHeader;
export { getOnboardingWebHeaderStyles };
