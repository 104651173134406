// eslint-disable-next-line no-use-before-define
import React from 'react';
import { isFactorTv } from 'renative';
import { Text, View, Interactable, Icon } from '@24i/nxg-sdk-quarks';
import { useTheme, getFont } from '@24i/nxg-sdk-higgs';
import { TopBarPrimaryProps } from '../types';
import { overridable } from '../../../../context/ComponentOverrides';
import getTopBarPrimaryStyles from '../styles';

import BackButton from '../../../buttons/BackButton';

const TopBarPrimary = (props: TopBarPrimaryProps) => {
    const {
        styles: getStyles = getTopBarPrimaryStyles,
        title,
        previous,
        onBack,
        onClose,
        additionalContainerStyles,
    } = props;
    const { theme } = useTheme();

    const hasActionButtons = !!(previous || onClose) && !isFactorTv;

    const styles = getStyles(theme, hasActionButtons);

    return (
        <View style={[styles.container, additionalContainerStyles]}>
            {previous && !isFactorTv && (
                <BackButton
                    icon={theme.icons.menu.back}
                    iconSize={getFont(theme, 'h2').fontSize * 0.8}
                    onPress={onBack}
                    additionalContainerStyles={styles.backButtonContainer}
                />
            )}
            <View style={styles.textContainer}>
                <Text style={styles.text}>{title}</Text>
            </View>
            {onClose && !isFactorTv && (
                <Interactable onPress={onClose} style={styles.closeButtonContainer}>
                    <Icon
                        size={getFont(theme, 'h2').fontSize * 0.8}
                        name={theme.icons.settings.close.iconName}
                        font={theme.icons.settings.close?.iconFont}
                        color={theme.color.textPrimary}
                    />
                </Interactable>
            )}
        </View>
    );
};

export default overridable(TopBarPrimary, 'TopBarPrimary');
