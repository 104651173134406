import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Text, View, Interactable } from '@24i/nxg-sdk-quarks';
import { useTheme, Ratio } from '@24i/nxg-sdk-higgs';
import { isPlatformWeb } from 'renative';
import { useTranslation } from 'react-i18next';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { getCurrencySymbol } from '@24i/nxg-core-utils';

import { useDimensions } from '@24i/nxg-sdk-quantum';
import getSubscriptionButtonStyles from '../styles';

const SubscriptionButton = ({
    onPress,
    priceTag: { price, currency },
    title,
    subtitle,
    isCurrentPlan,
    isSelected,
    icon: { iconFont, iconName },
    subtitleNumberOfLines,
    styles: getStyles,
}) => {
    const { t } = useTranslation(['sott'], { wait: true });
    const { isLandscape } = useDimensions();
    const { theme } = useTheme();
    const styles = getStyles(theme);

    return (
        <Interactable
            style={[
                isPlatformWeb
                    ? { backgroundColor: isSelected ? theme.color.lighter2 : theme.color.lighter4 }
                    : {
                          width: isLandscape ? '95%' : '100%',
                          backgroundColor: isSelected ? theme.color.lighter4 : 'transparent',
                      },
                styles.buttonContainer,
            ]}
            onPress={onPress}
        >
            <View style={{ flexDirection: 'row' }}>
                <View style={{ width: isLandscape && !isPlatformWeb ? '60%' : '90%' }}>
                    {isCurrentPlan && !isLandscape && (
                        <Text style={styles.currentPlanTextStyles}>
                            {t('svod.currentSubscriptionBadge')}
                        </Text>
                    )}
                    <View
                        style={[
                            {
                                height: !subtitle ? '100%' : null,
                            },
                            styles.insideSubscriptionButton,
                        ]}
                    >
                        <Text style={styles.titleTextStyles}>
                            {title}
                            <Text style={styles.currencyText}>
                                {`${getCurrencySymbol(currency)}${price}`}
                                <Text style={styles.perMonthText}>{`/ ${t('date.months', {
                                    count: 1,
                                })}`}</Text>
                            </Text>
                        </Text>
                    </View>
                    {subtitle && (
                        <Text
                            style={styles.subtitleTextStyles}
                            numberOfLines={subtitleNumberOfLines}
                        >
                            {subtitle}
                        </Text>
                    )}
                </View>
                <View
                    style={[
                        { alignSelf: 'center', flex: 1 },
                        isLandscape ? styles.innerContainerLandscape : styles.innerContainer,
                    ]}
                >
                    {!isPlatformWeb && isCurrentPlan && isLandscape && (
                        <Text
                            style={[
                                styles.currentPlanTextStyles,
                                {
                                    marginRight: isLandscape ? Ratio(15) : null,
                                },
                            ]}
                        >
                            {t('svod.currentSubscriptionBadge')}
                        </Text>
                    )}
                    <Icon
                        color={theme.color.textPrimary}
                        font={iconFont}
                        name={iconName}
                        size={Ratio(27)}
                        style={!isPlatformWeb && { opacity: isSelected ? 1 : 0 }}
                    />
                </View>
            </View>
        </Interactable>
    );
};

SubscriptionButton.propTypes = {
    onPress: PropTypes.func,
    priceTag: PropTypes.objectOf,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    styles: PropTypes.func,
    isCurrentPlan: PropTypes.bool,
    isSelected: PropTypes.bool,
    icon: PropTypes.shape({
        iconName: PropTypes.string,
        iconFont: PropTypes.string,
    }),
    subtitleNumberOfLines: PropTypes.number,
};

SubscriptionButton.defaultProps = {
    onPress: () => {},
    priceTag: null,
    title: '',
    isCurrentPlan: false,
    isSelected: false,
    icon: {
        iconFont: 'materialIcons',
        iconName: null,
    },
    subtitleNumberOfLines: 1,
    subtitle: '',
    styles: getSubscriptionButtonStyles,
};

export { getSubscriptionButtonStyles };
export default overridable(SubscriptionButton, 'SubscriptionButton');
