import React from 'react';
import { Text, Icon } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import getStyles from './styles';

export interface RateItemProps {
    rating: number;
}

const RateItem = ({ rating }: RateItemProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);

    return (
        <>
            <Text style={styles.text}>
                <>
                    <Icon
                        size={16}
                        font={theme.icons.details.rated?.iconFont}
                        name={theme.icons.details.rated?.iconName}
                        style={styles.icon}
                    />
                    {rating.toString()}
                </>
            </Text>
        </>
    );
};

export default RateItem;
