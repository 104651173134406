import React from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionConfirmPurchaseProps } from '../../types';
import ConfirmPurchase from '../ConfirmPurchase';

const SubscriptionConfirmPurchase: React.FC<SubscriptionConfirmPurchaseProps> = (props) => {
    const { children } = props;
    const { t } = useTranslation();

    return (
        <ConfirmPurchase
            {...props}
            topText={t('svod.add.confirmSubscription.description')}
            middleText={t('svod.add.addConfirm.recurrencePayment')}
            bottomText={t('svod.add.addConfirm.cancelInfo')}
        >
            {children}
        </ConfirmPurchase>
    );
};

export default SubscriptionConfirmPurchase;
