import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import SubactionButton from '@24i/nxg-sdk-gluons/src/components/buttons/SubactionButton';
import TertiaryButton from '@24i/nxg-sdk-gluons/src/components/buttons/TertiaryButton';
import Modal from '@24i/nxg-sdk-gluons/src/components/containers/Modal';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { getBorderRadius, getFont, Ratio, useTheme } from '@24i/nxg-sdk-higgs';
import { DeviceInfo } from '@24i/nxg-sdk-quantum';
import {
    FlatList,
    Icon,
    Image,
    Interactable,
    SafeAreaView,
    ScrollView,
    Text,
    TextInput,
    View,
} from '@24i/nxg-sdk-quarks';
import React, { useRef } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FlatGrid } from 'react-native-super-grid';
import { isFactorMobile, isPlatformWeb } from 'renative';
import { getBottomBarHeight } from '../../../utils';
import LoadingWrapper from '../../LoadingWrapper';
import getEditProfileStyles from '../styles';
import { CommonProps, Image as ImageType, Profile } from '../types';

const noop = () => {};

export interface EditProfileProps extends WithTranslation, CommonProps {
    image: ImageType;
    profile: Profile;
    isModalVisible: boolean;
    onSavePress: (
        newName: string,
        newAge: number | null,
        newImageId: string,
        // IDK
        isNotDefaultProfile: boolean,
        profileId: string
    ) => void;
    onModalPress: (IHaveNoIdeaWhatThisBooleanMeans: boolean) => void;
    onDeletePress: (profileId: string) => void;
}

const EditProfile = ({
    t,
    image,
    images,
    profile,
    isLoading,
    categories,
    age = null,
    name = '',
    styles: getStyles = getEditProfileStyles,
    isKidProfile = false,
    onIsKidProfilePress = noop,
    onAgeCategoryPress = noop,
    onImagePress = noop,
    onNameChange = noop,
    onSavePress = noop,
    onDeletePress = noop,
    onModalPress = noop,
    isModalVisible = false,
    isFocused = false,
    onTextInputFocus = noop,
}: EditProfileProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);

    const flatListRef = useRef<typeof FlatList>(null);

    const goIndex = (wantedIndex) => {
        flatListRef.current?.scrollToIndex?.({
            animated: true,
            index: wantedIndex,
            viewPosition: 0.5,
        });
    };

    const currentProfileImage = profile.image;

    // eslint-disable-next-line consistent-return
    const getSpecificIndex = () => {
        for (let i = 0; i < images.length; i += 1) {
            if (images[i].id === image.id) {
                return i;
            }
        }
    };

    const deleteProfileTitle = profile.name
        ? t('profiles.delete.deleteConfirm.title', { profileName: profile.name })
        : t('manageProfiles.deleteQuestionFallback');

    return (
        <View style={{ height: '100%', width: '100%' }}>
            <ScrollView
                style={{
                    backgroundColor: theme.color.background1,
                }}
                contentContainerStyle={[
                    (isFactorMobile || DeviceInfo.isTablet()) && {
                        paddingBottom: getBottomBarHeight(),
                    },
                    styles.scrollContainer,
                ]}
                scrollIndicatorInsets={{ right: 1 }}
            >
                <SafeAreaView style={styles.screenContainer}>
                    <View style={styles.contentContainer}>
                        <View
                            style={{
                                backgroundColor: isFocused
                                    ? theme.color.lighter1
                                    : theme.color.lighter4,
                                borderRadius: Ratio(25),
                                ...getBorderRadius(theme.elements, 'textField-cornerRadius'),
                                paddingLeft: Ratio(24),
                            }}
                            onFocus={() => onTextInputFocus(true)}
                            onBlur={() => onTextInputFocus(false)}
                        >
                            <TextInput
                                style={styles.textInput}
                                defaultValue={name}
                                placeholderTextColor={`${theme.color.textPrimary}4D`}
                                selectionColor={`${theme.color.textPrimary}66`}
                                autoCapitalize="words"
                                autoCorrect={false}
                                onChangeText={(input) => onNameChange(input)}
                            />
                        </View>
                        <View style={styles.profileImageContainer}>
                            <Text style={styles.profileImageText}>
                                {t('profiles.avatar.label')}
                            </Text>
                            <FlatList
                                data={images}
                                horizontal
                                ref={flatListRef}
                                showsHorizontalScrollIndicator={false}
                                initialScrollIndex={getSpecificIndex()}
                                onScrollToIndexFailed={(error) => {
                                    flatListRef.current?.scrollToOffset?.({
                                        offset: error.averageItemLength * error.index,
                                        animated: true,
                                    });
                                    setTimeout(() => {
                                        if (images.length !== 0 && flatListRef !== null) {
                                            flatListRef.current?.scrollToIndex?.({
                                                index: error.index,
                                                animated: true,
                                            });
                                        }
                                    }, 100);
                                }}
                                renderItem={({ item, index }) => (
                                    <Interactable
                                        onPress={() => {
                                            onImagePress(item);
                                            goIndex(index);
                                        }}
                                    >
                                        <Image
                                            resizeMode="contain"
                                            style={[
                                                {
                                                    marginRight:
                                                        images.length !== index + 1 ? Ratio(15) : 0,

                                                    borderWidth:
                                                        item.id === image.id ? Ratio(3) : 0,
                                                    borderColor: theme.color.textPrimary,
                                                },
                                                styles.additionalProfileImageContainer,
                                            ]}
                                            source={{
                                                uri: item.images.avatar[0].url,
                                            }}
                                        />
                                    </Interactable>
                                )}
                            />
                        </View>
                        <View>
                            <View style={styles.ageCategoryContainer}>
                                <Text style={styles.ageCategoryText}>
                                    {t('manageProfiles.kid')}
                                </Text>
                                <Interactable
                                    onPress={() => {
                                        onIsKidProfilePress(!isKidProfile);
                                        onAgeCategoryPress(
                                            isKidProfile
                                                ? categories[categories.length - 1].value
                                                : categories[0].value
                                        );
                                    }}
                                >
                                    <View
                                        style={[
                                            {
                                                backgroundColor: isKidProfile
                                                    ? theme.color.buttonPrimary
                                                    : 'transparent',
                                            },
                                            styles.additonalAgeCategory,
                                        ]}
                                    >
                                        {isKidProfile && (
                                            <Icon
                                                font={theme.icons.settings.check?.iconFont}
                                                name={theme.icons.settings.check.iconName}
                                                size={Ratio(20)}
                                                color={theme.color.textButtonPrimary}
                                            />
                                        )}
                                    </View>
                                </Interactable>
                            </View>
                            {isKidProfile && (
                                <>
                                    <View style={{ marginBottom: Ratio(48) }}>
                                        <Text style={styles.ageCategoryDescriptionText}>
                                            {t('manageProfiles.kidDescription')}
                                        </Text>

                                        <FlatGrid
                                            itemDimension={Ratio(400)}
                                            // eslint-disable-next-line react/prop-types
                                            data={categories.filter(
                                                (member, index) => index !== categories.length - 1
                                            )}
                                            spacing={0}
                                            contentContainerStyle={{
                                                marginTop: Ratio(15),
                                            }}
                                            renderItem={({ item }) => (
                                                <Interactable
                                                    onPress={() => {
                                                        if (isKidProfile)
                                                            onAgeCategoryPress(item.value);
                                                    }}
                                                    activeOpacity={!isKidProfile ? 1 : 0.2}
                                                >
                                                    <View
                                                        style={[
                                                            styles.selectionAgeCategory,
                                                            {
                                                                marginLeft: isPlatformWeb
                                                                    ? Ratio(15)
                                                                    : 0,
                                                                opacity:
                                                                    age === item.value ? 1 : 0.4,
                                                            },
                                                        ]}
                                                    >
                                                        <View
                                                            style={{
                                                                width: Ratio(22),
                                                                marginRight: Ratio(19),
                                                            }}
                                                        >
                                                            <Icon
                                                                font={
                                                                    theme.icons.settings.check
                                                                        ?.iconFont
                                                                }
                                                                name={
                                                                    theme.icons.settings.check
                                                                        .iconName
                                                                }
                                                                size={Ratio(22)}
                                                                color={theme.color.contrast1}
                                                                style={{
                                                                    opacity:
                                                                        age === item.value ? 1 : 0,
                                                                }}
                                                            />
                                                        </View>
                                                        <Text
                                                            style={[
                                                                {
                                                                    color: theme.color.textPrimary,
                                                                },
                                                                styles.additonalPreferenceOfAge,
                                                            ]}
                                                        >
                                                            {item.title}
                                                        </Text>
                                                        <View
                                                            style={[
                                                                {
                                                                    backgroundColor:
                                                                        theme.color.contrast3,
                                                                },
                                                                styles.additionalAgeValueContainer,
                                                            ]}
                                                        >
                                                            <Text
                                                                style={{
                                                                    color: theme.color
                                                                        .textSecondary,
                                                                }}
                                                            >
                                                                {item.age}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                </Interactable>
                                            )}
                                        />
                                    </View>
                                </>
                            )}
                        </View>
                    </View>
                    <View style={{ alignSelf: 'center' }}>
                        <LoadingWrapper
                            isLoading={isLoading}
                            loaderProps={{ holderStyles: styles.activityIndicatorContainer }}
                        >
                            <ActionButton
                                disabled={
                                    !(
                                        (name !== '' && name !== profile.name) ||
                                        (age !== profile.age && name !== '') ||
                                        (image.id !== currentProfileImage.id && name !== '')
                                    )
                                }
                                onPress={() => {
                                    onSavePress(
                                        name,
                                        age,
                                        image.id,
                                        !profile.defaultProfile,
                                        profile.id
                                    );
                                }}
                                title={t('common.save')}
                                additionalContainerStyles={[
                                    styles.additionalSaveButtonContainer,
                                    {
                                        opacity: !(
                                            (name !== '' && name !== profile.name) ||
                                            (age !== profile.age && name !== '') ||
                                            (image.id !== currentProfileImage.id && name !== '')
                                        )
                                            ? 0.2
                                            : 1,
                                    },
                                ]}
                                additionalTextStyles={{
                                    marginLeft: 0,
                                }}
                            />
                        </LoadingWrapper>
                        <TertiaryButton
                            onPress={() => {
                                if (isPlatformWeb) {
                                    onDeletePress(profile.id);
                                } else onModalPress(true);
                            }}
                            additionalContainerStyles={{
                                marginBottom: Ratio(8),
                                backgroundColor: 'transparent',
                            }}
                            additionalTextStyles={{ marginLeft: 0, color: theme.color.textPrimary }}
                            title={t('profiles.delete.deleteButton')}
                        />
                    </View>
                    {isPlatformWeb && (
                        <Interactable
                            style={{
                                alignSelf: 'center',
                            }}
                            onPress={() => {
                                if (isPlatformWeb) {
                                    onDeletePress(profile.id);
                                } else onModalPress(true);
                            }}
                        >
                            <Text
                                style={{
                                    color: theme.color.textPrimary,
                                    ...getFont(theme, 'tertiaryButton'),
                                }}
                            >
                                Go back
                            </Text>
                        </Interactable>
                    )}
                </SafeAreaView>
            </ScrollView>
            <Modal visible={isModalVisible}>
                <View style={styles.modalContainer}>
                    <Text style={styles.modalText}>{deleteProfileTitle}</Text>
                    <Text style={styles.modalDescription}>
                        {t('profiles.delete.deleteConfirm.description')}
                    </Text>
                    <SubactionButton
                        onPress={() => {
                            onModalPress(false);
                            onDeletePress(profile.id);
                        }}
                        title={t('manageProfile:delete')}
                        additionalContainerStyles={styles.modalDeleteButton}
                        additionalTextStyles={{ marginLeft: 0 }}
                        disableATVonPressLimiter
                    />
                    <TertiaryButton
                        onPress={() => onModalPress(false)}
                        additionalContainerStyles={{
                            marginTop: Ratio(24),
                            backgroundColor: 'transparent',
                        }}
                        additionalTextStyles={{ marginLeft: 0, color: theme.color.textPrimary }}
                        title={t('common.cancel')}
                        disableATVonPressLimiter
                    />
                </View>
            </Modal>
        </View>
    );
};

EditProfile.defaultProps = {};

const LocalizedEditProfile = withTranslation(['sott'])(EditProfile);

export default overridable(LocalizedEditProfile, 'EditProfile');
