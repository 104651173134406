import { registerIconFont } from '@24i/nxg-sdk-quarks';

export const fontName = 'materialCommunityIcons';

const fontIcon = require('react-native-vector-icons/MaterialCommunityIcons').default;
const fontTTF = require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf');

const registerStoryFonts = () =>
    registerIconFont(fontName, fontIcon, fontTTF, 'MaterialCommunityIcons');

export default registerStoryFonts;
