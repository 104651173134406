import { getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { Breakpoint } from '../../../../../../../utils/styles/constants';
import { SearchDropdownItemStyle } from '../View/types';

const SearchDropdownItem = (theme: Theme): SearchDropdownItemStyle => ({
    container: {
        width: '50%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        [`@media (max-width: ${Breakpoint.MD}px)`]: {
            width: '100%',
        },
    },
    button: {
        height: 52,
        marginLeft: 8,
        flexDirection: 'row',
        alignItems: 'center',
        zIndex: 3,
        width: '90%',
    },
    text: {
        width: 'inherit',
        color: theme.color.textPrimary,
        marginLeft: 6,
        ...getFont(theme, 'body2'),
    },
    interectable: {
        position: 'absolute',
        right: 0,
        zIndex: 199,
    },
    clearIcon: {
        color: `${theme.color.textPrimary}99`,
        fontSize: 20,
    },
    dropdownItemIcon: {
        color: `${theme.color.textPrimary}99`,
        fontSize: 17,
    },
});

export default SearchDropdownItem;
