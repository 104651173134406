import React from 'react';
import { Icon } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import getIconLinkStyles from '../styles';

const IconLink = ({ icon, index, isLastItem }) => {
    const { theme } = useTheme();

    return (
        <a
            key={`${icon.name}${index}`}
            style={{
                marginRight: isLastItem ? 0 : 35,
            }}
            rel="noopener noreferrer"
            target="_blank"
            href={icon?.href}
        >
            <Icon color={theme?.color?.textPrimary} size={24} font={icon.font} name={icon.name} />
        </a>
    );
};

export { getIconLinkStyles };
export default IconLink;
