import React from 'react';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Interactable, View, Icon, Text } from '@24i/nxg-sdk-quarks';

import getRecordingButtonStyles from '../styles';

import { RecordingButtonProps } from '../../../types';

const RecordingButton = ({ title, onPress, disabled }: RecordingButtonProps) => {
    const { theme } = useTheme();
    const styles = getRecordingButtonStyles(theme, { disabled });
    return (
        <Interactable
            onPress={onPress}
            style={styles.wrapper}
            disabled={disabled}
            testID="recording_toggle_button"
        >
            <Text style={styles.title}>{title}</Text>
            <View style={styles.iconWrapper}>
                <Icon
                    color={theme.color.textPrimary}
                    font="fontAwesome"
                    name="angle-right"
                    size={20}
                />
            </View>
        </Interactable>
    );
};

export { getRecordingButtonStyles };
export default RecordingButton;
