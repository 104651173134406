import { TimelineStyles } from '../types';

const getStyles = (theme: any): TimelineStyles => ({
    collection: {
        overflow: 'hidden',
        overflowX: 'hidden',
        overflowY: 'hidden',
        left: theme.sidebar.container.width,
        right: 0,
        top: 0,
    },
    text: {
        textAlign: 'center',
        position: 'absolute',
        width: '100%',
        left: '-50%',
    },
    arrows: {
        opacity: 0.6,
        fontSize: 30,
        ':hover': { opacity: 1, fontSize: 34 },
    },
    leftArrowContainer: {
        backgroundColor: theme.timeline.container.backgroundColor,
    },
    rightArrowContainer: {
        marginLeft: 'auto',
        backgroundColor: theme.timeline.container.backgroundColor,
    },
    arrowsWrapper: {
        top: -8,
        position: 'absolute',
        left: theme.sidebar.container.width,
        flexDirection: 'row',
    },
    columnContainer: {
        justifyContent: 'flex-start',
        display: 'flex',
        backgroundColor: theme.timeline.container.backgroundColor,
    },
    currentTimeLabelContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        zIndex: 10,
        ...theme.currentTime.label,
    },
    currentTimeLabelText: {
        textAlign: 'center',
        ...theme.currentTime.labelText,
    },
});

export default getStyles;
