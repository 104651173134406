import { getFont, getBorderRadius } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { DropdownButtonStylesTypes } from '../../../types';

const DropdownButtonStyles = (theme: Theme): DropdownButtonStylesTypes => ({
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 16,
        paddingRight: 14,
        backgroundColor: theme.color.lighter3,
        ...getBorderRadius(theme.elements, 'buttonTertiary-cornerRadius'),
    },
    innerButtonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    dropdownButtonText: {
        ...getFont(theme, 'body1'),
        color: theme.color.textPrimary,
        marginLeft: 5,
    },
    secondaryIcon: {
        opacity: 0.6,
        marginLeft: 3,
    },
});

export default DropdownButtonStyles;
