import React, { ReactElement } from 'react';
import { View } from 'react-native';
import LoadingWrapper from '@24i/nxg-sdk-smartott/src/components/LoadingWrapper';
import { RENDER_STATE } from '@24i/adobe-primetime/src/shared/accessEnablerConstants';
import LinearGradient from 'react-native-linear-gradient';
import { ImageBackground, Icon } from '@24i/nxg-sdk-quarks';
import { useDimensions } from '@24i/nxg-sdk-quantum';
import { useTheme } from '@24i/nxg-sdk-higgs/src/hooks/useTheme';
import type { MvpdSignInViewModelReturnType } from '../types';
import MvpdSignInContent from '../components/MvpdSignInContent';
import { Breakpoint } from '../../../utils/styles/constants';

export const MvpdSignIn = (props: MvpdSignInViewModelReturnType): ReactElement => {
    const { renderState, landingScreenConfig, isModal, handleOnBackWeb, guestModeEnabled } = props;
    const { theme } = useTheme();
    const dimensions = useDimensions();
    const { LOADING } = RENDER_STATE;

    const isLoading = renderState === LOADING;

    const { backgroundImage } = landingScreenConfig || {};
    const backgroundImageToUse =
        dimensions.width < Breakpoint.SM ? backgroundImage?.portrait : backgroundImage?.landscape;

    const renderView = () => (
        <View
            style={[
                !isModal && { height: '100%' },
                { flex: 1, alignItems: 'center', justifyContent: 'center' },
            ]}
        >
            <LoadingWrapper isLoading={isLoading}>
                <MvpdSignInContent {...props} />
            </LoadingWrapper>
        </View>
    );
    const renderCloseIcon = () =>
        guestModeEnabled ? (
            <View
                style={{
                    position: 'absolute',
                    right: 48,
                    top: 48,
                    zIndex: 1000,
                    // Dont use platform specific logic as this file is already platform specific
                    // @ts-expect-error
                    cursor: 'pointer',
                }}
                onClick={handleOnBackWeb}
            >
                {!!theme.icons.common && (
                    <Icon
                        font={theme.icons?.common?.modalClose?.iconFont}
                        name={theme.icons?.common?.modalClose?.iconName}
                        size={36}
                        color={theme.color.textPrimary}
                    />
                )}
            </View>
        ) : null;

    if (isModal) {
        return renderView();
    }

    return (
        <>
            {renderCloseIcon()}
            <ImageBackground
                source={{
                    uri: backgroundImageToUse || undefined,
                }}
                style={{ height: '100vh' }}
                imageStyle={{ opacity: 1 }}
            >
                <LinearGradient
                    colors={['#000', '#00000065']}
                    start={{ x: 0, y: 1 }}
                    end={{ x: 0, y: 0 }}
                    style={{ height: '100vh' }}
                >
                    {renderView()}
                </LinearGradient>
            </ImageBackground>
        </>
    );
};
