import React from 'react';
import ProgressBar, {
    getProgressBarStyles,
} from '@24i/nxg-sdk-gluons/src/components/ui/ProgressBar';
import { View, Text } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { isPlatformIos } from 'renative';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import BlurView from '@24i/nxg-sdk-gluons/src/components/containers/BlurView';
import IconButton from '@24i/nxg-sdk-gluons/src/components/buttons/IconButton';
import {
    useMediaStatus,
    useStreamPosition,
    useRemoteMediaClient,
    MediaPlayerState,
} from '../../../../context/Cast';
import type { CastMediaInfo } from '../../../../context/Cast/types';
import getChromecastMiniControllerStyles from '../styles';
import { MINI_CONTROLLER_TEST_IDS } from '../types';

const ChromecastMiniController = ({
    onPress,
    styles: getStyles = getChromecastMiniControllerStyles,
}) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const status = useMediaStatus();
    const client = useRemoteMediaClient();
    const streamPosition = useStreamPosition() || 0;
    const mediaInfo = status?.mediaInfo as CastMediaInfo;
    const duratio = mediaInfo?.streamDuration || 0;
    const isPlaying = status?.playerState === MediaPlayerState.PLAYING;
    const metaData = mediaInfo?.metadata;
    const { icons } = theme;

    const Wrapper = isPlatformIos ? BlurView : View;

    const onPlay = () => {
        if (isPlaying) client?.pause();
        else client?.play();
    };

    const calculateProgress = () => {
        if (duratio !== 0 && streamPosition !== 0) {
            return streamPosition / duratio;
        }
        return 0;
    };

    return (
        <View
            style={styles.mainContainer}
            onStartShouldSetResponder={onPress}
            testID={MINI_CONTROLLER_TEST_IDS.CONTAINER}
        >
            <Wrapper
                style={styles.blurViewContainer}
                blurType="dark"
                blurAmount={theme.color.primaryBlur1.blur}
            >
                <View style={styles.metadataContainer}>
                    <View style={styles.titleAndSubtitleContainer}>
                        <Text style={styles.titleStyles} numberOfLines={1}>
                            {metaData?.title}
                        </Text>
                        <Text style={styles.subtitleStyles} numberOfLines={1}>
                            {metaData?.subtitle}
                        </Text>
                    </View>
                    <View style={styles.playPauseContainer}>
                        <IconButton
                            color={theme.color.textPrimary}
                            iconSize={32}
                            additionalContainerStyles={{
                                justifyContent: 'center',
                            }}
                            icon={isPlaying ? icons.player.pause : icons.player.play}
                            onPress={onPlay}
                            testID={MINI_CONTROLLER_TEST_IDS.ACTION_BUTTON}
                        />
                    </View>
                </View>
                <ProgressBar
                    theme={theme}
                    styles={getProgressBarStyles}
                    progress={calculateProgress()}
                    additionalTrackStyles={styles.progressBar}
                    color={theme.color.progressBarAccent}
                    trackColor={theme.color.progressBarBackground}
                />
            </Wrapper>
        </View>
    );
};

export { getChromecastMiniControllerStyles };
export default overridable(ChromecastMiniController, 'ChromecastMiniController');
