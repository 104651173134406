import { useRoute } from '@24i/nxg-core-router';
import { useTranslation } from 'react-i18next';
import { isPlatformWeb } from 'renative';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { getRuntimeConfig } from '@24i/nxg-sdk-smartott/src/Application/initApp';

import useAssetActions from '@24i/nxg-sdk-smartott/src/hooks/useAssetActions';
import { MyRecentlyWatchedModelProps, MyRecentlyWatchedViewProps } from '../types';
import useLastWatchedQuery from '../../../hooks/query/useLastWatchedQuery';

const useViewModel = (props: MyRecentlyWatchedModelProps): MyRecentlyWatchedViewProps => {
    const { t } = useTranslation();
    const route = useRoute();
    const { theme } = useTheme();
    const { handlePackshotPress } = useAssetActions();
    const { mobile, desktop } = getRuntimeConfig('defaultLazyLoading');

    const size = isPlatformWeb ? desktop : mobile;

    const { data, isLoading, hasNextPage, fetchNextPage } = useLastWatchedQuery(size);

    const lastWatchPlaylist = data?.pages.flat() || [];

    const handleOnEndReached = () => {
        if (hasNextPage) fetchNextPage();
    };

    // do not show progress bar in tiles on "Recently watched" grid
    const dataWithoutProgress = lastWatchPlaylist.map((item) => ({
        ...item,
        continueWatchingOffset: 0,
    }));

    return {
        data: dataWithoutProgress,
        isLoading,
        emptyListTitle: t('watchHistory.noData.title'),
        emptyListDescription: t('watchHistory.noData.description'),
        backButtonTitle: `${t('common.backTo')} ${t('myContent.title')}`,
        webHeaderTitle: route.params?.title || t('watchHistory.title'),
        fullWidth: isPlatformWeb,
        emptyListIcon: theme.icons.myContent.recentlyWatched,
        handleOnEndReached,
        handleOnPackshotPress: handlePackshotPress,
        ...props,
    };
};

export default useViewModel;
