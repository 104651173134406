import React from 'react';
import { Slider } from '@24i/nxg-sdk-gluons';
import { View, Icon } from '@24i/nxg-sdk-quarks';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useTheme } from '@24i/nxg-sdk-higgs';
import getCastVolumeControllerStyles from '../styles';
import { CAST_VOLUME_CONTROLLER } from '../types';

const CastVolumeController = ({
    volume,
    onVolumeChange,
    styles: getStyles = getCastVolumeControllerStyles,
    volumeIcon,
}) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);
    return (
        <View style={styles.volumeContainer}>
            <Icon
                color={theme.color.textPrimary}
                size={24}
                font={volumeIcon?.iconFont}
                name={volumeIcon?.iconName}
            />
            <Slider
                style={styles.sliderStyles}
                minimumValue={0}
                maximumValue={100}
                minimumTrackTintColor={theme.color.progressBarAccent}
                maximumTrackTintColor={theme.color.progressBarBackground}
                thumbTintColor={theme.color.progressBarAccent}
                step={5}
                value={volume}
                onValueChange={onVolumeChange}
                testID={CAST_VOLUME_CONTROLLER.VOLUME_SLIDER}
            />
        </View>
    );
};

export { getCastVolumeControllerStyles };
export default overridable(CastVolumeController, 'CastVolumeController');
