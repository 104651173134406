import React, { FC } from 'react';
import { Icon, Text, View } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import Interactable from '@24i/nxg-sdk-quarks/src/components/Interactable';
import getStyles from './styles';

interface Props {
    title?: string | null;
    subtitle?: string | null;
    isBackButtonVisible?: boolean;
    onBackPress?: () => void;
}

const BasicLayout: FC<Props> = ({
    title,
    subtitle,
    isBackButtonVisible,
    onBackPress,
    children,
}) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);

    return (
        <View style={styles.wrapper}>
            <View style={styles.titleWrapper} pointerEvents="box-none">
                {isBackButtonVisible && onBackPress ? (
                    <Interactable onPress={onBackPress} style={styles.backButton}>
                        <Icon
                            color={theme.color.textPrimary}
                            font="fontAwesome"
                            name="angle-left"
                            size={28}
                        />
                    </Interactable>
                ) : (
                    <View />
                )}
                <Text style={styles.title}>{title}</Text>
                {/* Empty view because of justifyContent: 'space-between' */}
                <View />
            </View>
            {!!subtitle && <Text style={styles.subtitle}>{subtitle}</Text>}
            <View style={styles.content}>{children}</View>
        </View>
    );
};

export default BasicLayout;
