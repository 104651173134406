const getBottomTabBarStyles = (theme) => ({
    menuContainer: {
        width: '100%',
        flexDirection: 'row',
        display: 'flex',
        padding: '0 4%',
        zIndex: 99,
        backgroundColor: theme.color.primaryBlur1.color,
        backdropFilter: 'saturate(180%) blur(20px)',
        position: 'fixed',
        bottom: '0px',
        top: 'initial',
        height: 51,
        '@media (min-width: 801px)': {
            display: 'none',
        },
    },
    section: {
        flexDirection: 'row',
        alignSelf: 'center',
        justifyContent: 'space-around',
        flex: 1,
        maxHeight: 51,
    },
    sectionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        flex: 1,
    },
});

export { getBottomTabBarStyles };
