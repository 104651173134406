export enum SIGNUP_TEST_IDS {
    // Inputs
    FIRST_NAME_INPUT = 'first_name_input',
    LAST_NAME_INPUT = 'last_name_input',
    EMAIL_INPUT = 'register_email_input',
    PASSWORD_INPUT = 'register_password_input',
    CONFIRM_PASSWORD_INPUT = 'register_confirm_password_input',
    // Buttons
    SHOW_PASSWORD_BUTTON = 'register_password_input_show_button',
    SHOW_CONFIRM_PASSWORD_BUTTON = 'register_confirm_password_input_show_button',
    CONFIRM_BUTTON = 'register_confirm_button',
    SIGN_IN_BUTTON = 'sign_in_button',
    ERROR_MESSAGE_EMAIL = 'error_message_email',
    ERROR_MESSAGE_PASSWORD = 'error_message_password',
    ERROR_MESSAGE_NAMES = 'error_message_names',
}
