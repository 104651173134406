import React, { useRef, useCallback } from 'react';
import { Platform } from 'react-native';
import { View, Icon, Modal, Interactable } from '@24i/nxg-sdk-quarks';
import PropTypes from 'prop-types';
import registerIconFont from './registerTriangleIcon';
import { getIconPosition } from '../utils';
import { DROPDOWN_MENU_TEST_ID } from '../constants';

const ICON_SIZE = 10;

registerIconFont();
const fontName = 'materialCommunityIcons';

const DropDownMenuWrapper = ({
    children,
    menuContainerStyle,
    menuPosition,
    withArrow,
    closeDropDown,
    onLayout,
}) => {
    const modalWrappedRef = useRef(null);
    const { vertical, horizontal, ...positionCoords } = menuPosition;
    const Wrapper = Platform.isTV ? View : Interactable;
    const onLayoutCallback = useCallback(() => {
        modalWrappedRef.current.measure((x, y, width, height) => {
            onLayout({ width, height });
        });
    }, [modalWrappedRef, onLayout]);
    const areCoordsReady =
        typeof positionCoords.top !== 'undefined' && typeof positionCoords.left !== 'undefined';

    return (
        <Modal visible transparent>
            <Wrapper
                style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                }}
                {...(!Platform.isTV && {
                    onPress: closeDropDown,
                    activeOpacity: 1,
                })}
            >
                <View
                    onLayout={onLayoutCallback}
                    ref={(ref) => {
                        modalWrappedRef.current = ref;
                    }}
                    style={{
                        position: 'absolute',
                        ...(withArrow && { marginVertical: 10 }),
                        ...menuContainerStyle,
                        opacity: areCoordsReady ? 1 : 0,
                        ...positionCoords,
                    }}
                    testID={DROPDOWN_MENU_TEST_ID}
                >
                    {children}
                    {withArrow ? (
                        <Icon
                            style={{
                                ...getIconPosition({ vertical, horizontal }, ICON_SIZE),
                                width: ICON_SIZE,
                                height: ICON_SIZE,
                                position: 'absolute',
                            }}
                            size={ICON_SIZE}
                            font={fontName}
                            name="triangle"
                            color={menuContainerStyle.backgroundColor || 'transparent'}
                        />
                    ) : null}
                </View>
            </Wrapper>
        </Modal>
    );
};

DropDownMenuWrapper.defaultProps = {
    children: null,
    menuContainerStyle: {},
};

DropDownMenuWrapper.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    closeDropDown: PropTypes.func.isRequired,
    menuContainerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.number]),
    menuPosition: PropTypes.shape({
        vertical: PropTypes.string,
        horizontal: PropTypes.string,
    }).isRequired,
    withArrow: PropTypes.bool.isRequired,
    onLayout: PropTypes.func.isRequired,
};
export default DropDownMenuWrapper;
