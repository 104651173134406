import styled from 'styled-components';
import { Breakpoint } from '@24i/nxg-sdk-gluons/src/utils/styles/constants';
import { getBorderRadius, getFont, getMargins, Ratio } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { testableStyledComponentConfig } from '../../../../testUtils';
import { SingleRowStylesWeb, SingleRowStylesGetter } from './types';

export const getArrowClassName = (navButtonId, position) => {
    return `single-row-${navButtonId}-swiper-button swiper-button-${position} swiper-button-white swiper-button-large`;
};

export const Arrow = styled.div.withConfig(testableStyledComponentConfig)``;

const Carousel: SingleRowStylesGetter = (theme: Theme, lessMargin = false): SingleRowStylesWeb => ({
    container: {
        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        display: 'flex',
        flexDirection: 'column',
        [`@media (max-width: ${Breakpoint.MD}px)`]: {
            marginTop: 12,
        },
        [`@media (min-width: ${Breakpoint.MD}px) and (max-width: ${Breakpoint.XL}px)`]: {
            marginTop: 20,
        },
        [`@media (min-width: ${Breakpoint.XL + 1}px) and (max-width: ${Breakpoint.XXL}px)`]: {
            marginTop: lessMargin ? 24 : 48,
        },
        [`@media (min-width: ${Breakpoint.XXL + 1}px)`]: {
            marginTop: lessMargin ? 24 : 48,
        },
    },
    arrow: { height: '100%' },
    indicator: {
        width: '100%',
        height: Ratio(225),
        marginBottom: Ratio(5),
        backgroundColor: 'transparent',
    },
    titleContainer: {
        width: 'fit-content',
        ...getMargins(),
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 19,
    },
    title: {
        paddingRight: 8,
        color: theme.color.textPrimary,
        left: 0,
        ...getFont(theme, 'h3'),
    },
    genreTitle: {
        paddingRight: 8,
        color: theme.color.textPrimary,
        left: 0,
        ...getFont(theme, 'h3'),
    },
    content: {
        paddingRight: 10,
        paddingLeft: 32,
        paddingTop: 0,
    },
    slideHolder: {
        width: '100vw',
        alignSelf: 'center',
        marginTop: 10,
    },
    slideContainer: {
        flex: 1,
        marginRight: 0,
        [`@media (max-width: ${Breakpoint.MD}px)`]: {
            marginLeft: 12,
            right: -8,
        },
        [`@media (min-width: ${Breakpoint.MD + 1}px) and (max-width: ${Breakpoint.XL}px)`]: {
            marginLeft: 12,
            right: -20,
        },
        [`@media (min-width: ${Breakpoint.XL + 1}px) and (max-width: ${Breakpoint.XXL}px)`]: {
            marginLeft: 16,
            right: -32,
        },
        [`@media (min-width: ${Breakpoint.XXL + 1}px)`]: {
            marginLeft: 16,
            right: -32,
        },
    },
    landscapePackshotHolder: {
        boxSizing: 'border-box',
        width: 'calc(20% - (8% - 10px)/5)',
        padding: '0 10px',
        [`@media (max-width: ${Breakpoint.SM}px)`]: {
            width: 'calc(50% - (40px - 6px)/2)',
            paddingHorizontal: 6,
        },
        [`@media (min-width: ${Breakpoint.SM + 1}px) and (max-width: ${Breakpoint.MD}px)`]: {
            width: 'calc(33.3333333333% - (40px - 6px)/3)',
            paddingHorizontal: 6,
        },
        [`@media (min-width: ${Breakpoint.MD + 1}px) and (max-width: ${Breakpoint.XL}px)`]: {
            width: 'calc(25% - (96px - 8px)/4)',
            paddingHorizontal: 8,
        },
    },
    posterPackshotHolder: {
        boxSizing: 'border-box',
        width: 'calc(14.2857142857% - (8% - 10px)/7)',
        padding: '0 10px',
        [`@media (max-width: ${Breakpoint.SM}px)`]: {
            width: 'calc(33.3333333333% - (40px - 6px)/3)',
            paddingHorizontal: 6,
        },
        [`@media (min-width: ${Breakpoint.SM + 1}px) and (max-width: ${Breakpoint.MD}px)`]: {
            width: 'calc(25% - (40px - 6px)/4)',
            paddingHorizontal: 6,
        },
        [`@media (min-width: ${Breakpoint.MD + 1}px) and (max-width: ${Breakpoint.XL}px)`]: {
            width: 'calc(16.6666666667% - (96px - 8px)/6)',
            paddingHorizontal: 8,
        },
    },
    highlightedLandscapePackshotHolder: {
        boxSizing: 'border-box',
        width: 'calc(25% - (8% - 10px)/4)',
        padding: '0 10px',
        [`@media (max-width: ${Breakpoint.SM}px)`]: {
            width: 'calc(100% - (40px - 6px)/1)',
            paddingHorizontal: 6,
        },
        [`@media (min-width: ${Breakpoint.SM + 1}px) and (max-width: ${Breakpoint.MD}px)`]: {
            width: 'calc(50% - (40px - 6px)/2)',
            paddingHorizontal: 6,
        },
        [`@media (min-width: ${Breakpoint.MD + 1}px) and (max-width: ${Breakpoint.XL}px)`]: {
            width: 'calc(33.3333333333% - (96px - 8px)/3)',
            paddingHorizontal: 8,
        },
    },
    highlightedPosterPackshotHolder: {
        boxSizing: 'border-box',
        border: '1px solid transparent',
        width: 'calc(16.6666666667% - (8% - 10px)/6)',
        padding: '0 10px',
        [`@media (max-width: ${Breakpoint.SM}px)`]: {
            width: 'calc(50% - (40px - 6px)/2)',
            paddingHorizontal: 6,
        },
        [`@media (min-width: ${Breakpoint.SM + 1}px) and (max-width: ${Breakpoint.MD}px)`]: {
            width: 'calc(33.3333333333% - (40px - 6px)/3)',
            paddingHorizontal: 6,
        },
        [`@media (min-width: ${Breakpoint.MD + 1}px) and (max-width: ${Breakpoint.XL}px)`]: {
            width: 'calc(20% - (96px - 8px)/5)',
            paddingHorizontal: 8,
        },
    },
    seeAllButtonTitle: {
        transition: '0.35s ease-in-out',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.color.textPrimary,
        ...getFont(theme, 'body2'),
    },
    seeAllButtonContainer: {
        flexDirection: 'row-reverse',
        transition: '0.35s ease-in-out',
        width: 24,
        height: 24,
        borderRadius: 12,
        ...getBorderRadius(theme.elements, 'buttonPrimary-cornerRadius'),
        alignItems: 'center',
        backgroundColor: theme.color.lighterBlur1?.color,
        overflow: 'hidden',
        paddingRight: 2,
    },
    seeAllHovered: {
        width: '100%',
        paddingLeft: 8,
    },
});

export default Carousel;
