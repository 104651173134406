export const mockTheme = () => {
    const theme = {
        name: 'Default -> Smart Apps Duplicate 2',
        fonts: {
            S: {
                hero: {
                    family: 'HK Grotesk',
                    weight: '700',
                    style: 'normal',
                    size: 34,
                    character: 0,
                    line: 40,
                    transform: 'none',
                },
                h1: {
                    family: 'Roboto',
                    weight: '700',
                    style: 'normal',
                    size: 32,
                    character: 0,
                    line: 38,
                    transform: 'none',
                },
                h2: {
                    family: 'HK Grotesk',
                    weight: '700',
                    style: 'normal',
                    size: 24,
                    character: 0,
                    line: 32,
                    transform: 'none',
                },
                h3: {
                    family: 'HK Grotesk',
                    weight: '700',
                    style: 'normal',
                    size: 20,
                    character: 0,
                    line: 26,
                    transform: 'none',
                },
                h4: {
                    family: 'HK Grotesk',
                    weight: '700',
                    style: 'normal',
                    size: 16,
                    character: 0,
                    line: 21,
                    transform: 'none',
                },
                h5: {
                    family: 'HK Grotesk',
                    weight: '600',
                    style: 'normal',
                    size: 13,
                    character: -0.11,
                    line: 17,
                    transform: 'none',
                },
                h6: {
                    family: 'HK Grotesk',
                    weight: '700',
                    style: 'normal',
                    size: 12,
                    character: 0,
                    line: 16,
                    transform: 'uppercase',
                },
                body1: {
                    family: 'HK Grotesk',
                    weight: '500',
                    style: 'normal',
                    size: 16,
                    character: 0,
                    line: 24,
                    transform: 'none',
                },
                body2: {
                    family: 'HK Grotesk',
                    weight: '500',
                    style: 'normal',
                    size: 15,
                    character: 0,
                    line: 20,
                    transform: 'none',
                },
                primaryButton: {
                    family: 'HK Grotesk',
                    weight: '700',
                    style: 'normal',
                    size: 18,
                    character: 0,
                    line: 23,
                    transform: 'uppercase',
                },
                secondaryButton: {
                    family: 'HK Grotesk',
                    weight: '700',
                    style: 'normal',
                    size: 18,
                    character: 0,
                    line: 23,
                    transform: 'uppercase',
                },
                tertiaryButton: {
                    family: 'HK Grotesk',
                    weight: '600',
                    style: 'normal',
                    size: 14,
                    character: 0,
                    line: 18,
                    transform: 'none',
                },
                'menuItem-active': {
                    family: 'HK Grotesk',
                    weight: '500',
                    style: 'normal',
                    size: 11,
                    character: -0.3,
                    line: 16,
                    transform: 'none',
                },
                'menuItem-inactive': {
                    family: 'HK Grotesk',
                    weight: '500',
                    style: 'normal',
                    size: 11,
                    character: 0,
                    line: 16,
                    transform: 'none',
                },
            },
            M: {
                hero: {
                    family: 'HK Grotesk',
                    weight: '700',
                    style: 'normal',
                    size: 48,
                    character: 0,
                    line: 60,
                    transform: 'none',
                },
                h1: {
                    family: 'HK Grotesk',
                    weight: '700',
                    style: 'normal',
                    size: 32,
                    character: 0,
                    line: 38,
                    transform: 'none',
                },
                h2: {
                    family: 'HK Grotesk',
                    weight: '700',
                    style: 'normal',
                    size: 24,
                    character: 0,
                    line: 28,
                    transform: 'none',
                },
                h3: {
                    family: 'HK Grotesk',
                    weight: '700',
                    style: 'normal',
                    size: 20,
                    character: 0,
                    line: 26,
                    transform: 'none',
                },
                h4: {
                    family: 'HK Grotesk',
                    weight: '700',
                    style: 'normal',
                    size: 16,
                    character: 0,
                    line: 21,
                    transform: 'none',
                },
                h5: {
                    family: 'HK Grotesk',
                    weight: '600',
                    style: 'normal',
                    size: 13,
                    character: -0.11,
                    line: 17,
                    transform: 'none',
                },
                h6: {
                    family: 'HK Grotesk',
                    weight: '700',
                    style: 'normal',
                    size: 12,
                    character: 0,
                    line: 16,
                    transform: 'uppercase',
                },
                body1: {
                    family: 'HK Grotesk',
                    weight: '500',
                    style: 'normal',
                    size: 16,
                    character: 0,
                    line: 24,
                    transform: 'none',
                },
                body2: {
                    family: 'HK Grotesk',
                    weight: '500',
                    style: 'normal',
                    size: 15,
                    character: 0,
                    line: 20,
                    transform: 'none',
                },
                primaryButton: {
                    family: 'HK Grotesk',
                    weight: '700',
                    style: 'normal',
                    size: 18,
                    character: 0,
                    line: 23,
                    transform: 'uppercase',
                },
                secondaryButton: {
                    family: 'HK Grotesk',
                    weight: '700',
                    style: 'normal',
                    size: 18,
                    character: 0,
                    line: 23,
                    transform: 'uppercase',
                },
                tertiaryButton: {
                    family: 'HK Grotesk',
                    weight: '600',
                    style: 'normal',
                    size: 14,
                    character: 0,
                    line: 18,
                    transform: 'none',
                },
                'menuItem-active': {
                    family: 'HK Grotesk',
                    weight: '500',
                    style: 'normal',
                    size: 13,
                    character: 0,
                    line: 16,
                    transform: 'none',
                },
                'menuItem-inactive': {
                    family: 'HK Grotesk',
                    weight: '600',
                    style: 'normal',
                    size: 13,
                    character: 0,
                    line: 16,
                    transform: 'none',
                },
            },
            L: {
                hero: {
                    family: 'HK Grotesk',
                    weight: '700',
                    style: 'normal',
                    size: 64,
                    character: 0,
                    line: 79,
                    transform: 'none',
                },
                h1: {
                    family: 'Roboto',
                    weight: '700',
                    style: 'normal',
                    size: 48,
                    character: 0,
                    line: 63,
                    transform: 'none',
                },
                h2: {
                    family: 'HK Grotesk',
                    weight: '700',
                    style: 'normal',
                    size: 32,
                    character: 0,
                    line: 42,
                    transform: 'none',
                },
                h3: {
                    family: 'HK Grotesk',
                    weight: '700',
                    style: 'normal',
                    size: 24,
                    character: 0,
                    line: 31,
                    transform: 'none',
                },
                h4: {
                    family: 'HK Grotesk',
                    weight: '700',
                    style: 'normal',
                    size: 18,
                    character: 0,
                    line: 23,
                    transform: 'none',
                },
                h5: {
                    family: 'HK Grotesk',
                    weight: '600',
                    style: 'normal',
                    size: 15,
                    character: -0.17,
                    line: 20,
                    transform: 'none',
                },
                h6: {
                    family: 'HK Grotesk',
                    weight: '700',
                    style: 'normal',
                    size: 13,
                    character: 0,
                    line: 17,
                    transform: 'uppercase',
                },
                body1: {
                    family: 'HK Grotesk',
                    weight: '500',
                    style: 'normal',
                    size: 18,
                    character: 0,
                    line: 25,
                    transform: 'none',
                },
                body2: {
                    family: 'HK Grotesk',
                    weight: '500',
                    style: 'normal',
                    size: 16,
                    character: 0,
                    line: 23,
                    transform: 'none',
                },
                primaryButton: {
                    family: 'HK Grotesk',
                    weight: '700',
                    style: 'normal',
                    size: 20,
                    character: 0,
                    line: 26,
                    transform: 'uppercase',
                },
                secondaryButton: {
                    family: 'HK Grotesk',
                    weight: '700',
                    style: 'normal',
                    size: 20,
                    character: 0,
                    line: 26,
                    transform: 'uppercase',
                },
                tertiaryButton: {
                    family: 'HK Grotesk',
                    weight: '600',
                    style: 'normal',
                    size: 16,
                    character: 0,
                    line: 21,
                    transform: 'none',
                },
                'menuItem-active': {
                    family: 'HK Grotesk',
                    weight: '700',
                    style: 'normal',
                    size: 16,
                    character: 0,
                    line: 21,
                    transform: 'none',
                },
                'menuItem-inactive': {
                    family: 'HK Grotesk',
                    weight: '600',
                    style: 'normal',
                    size: 16,
                    character: 0,
                    line: 21,
                    transform: 'none',
                },
            },
            XL: {
                hero: {
                    family: 'HK Grotesk',
                    weight: '700',
                    style: 'normal',
                    size: 100,
                    character: 0,
                    line: 112,
                    transform: 'none',
                },
                h1: {
                    family: 'HK Grotesk',
                    weight: '700',
                    style: 'normal',
                    size: 80,
                    character: 0,
                    line: 104,
                    transform: 'none',
                },
                h2: {
                    family: 'HK Grotesk',
                    weight: '700',
                    style: 'normal',
                    size: 48,
                    character: 0,
                    line: 63,
                    transform: 'none',
                },
                h3: {
                    family: 'HK Grotesk',
                    weight: '600',
                    style: 'normal',
                    size: 39,
                    character: 0,
                    line: 51,
                    transform: 'none',
                },
                h4: {
                    family: 'HK Grotesk',
                    weight: '700',
                    style: 'normal',
                    size: 30,
                    character: 0,
                    line: 39,
                    transform: 'none',
                },
                h5: {
                    family: 'HK Grotesk',
                    weight: '600',
                    style: 'normal',
                    size: 24,
                    character: -0.21,
                    line: 31,
                    transform: 'none',
                },
                h6: {
                    family: 'HK Grotesk',
                    weight: '700',
                    style: 'normal',
                    size: 24,
                    character: 0,
                    line: 31,
                    transform: 'uppercase',
                },
                body1: {
                    family: 'HK Grotesk',
                    weight: '500',
                    style: 'normal',
                    size: 28,
                    character: 0,
                    line: 40,
                    transform: 'none',
                },
                body2: {
                    family: 'HK Grotesk',
                    weight: '500',
                    style: 'normal',
                    size: 27,
                    character: 0,
                    line: 34,
                    transform: 'none',
                },
                primaryButton: {
                    family: 'HK Grotesk',
                    weight: '700',
                    style: 'normal',
                    size: 32,
                    character: 0,
                    line: 40,
                    transform: 'uppercase',
                },
                secondaryButton: {
                    family: 'HK Grotesk',
                    weight: '700',
                    style: 'normal',
                    size: 32,
                    character: 0,
                    line: 40,
                    transform: 'uppercase',
                },
                tertiaryButton: {
                    family: 'HK Grotesk',
                    weight: '600',
                    style: 'normal',
                    size: 24,
                    character: 0,
                    line: 30,
                    transform: 'none',
                },
                'menuItem-active': {
                    family: 'HK Grotesk',
                    weight: '700',
                    style: 'normal',
                    size: 32,
                    character: 0,
                    line: 42,
                    transform: 'uppercase',
                },
                'menuItem-inactive': {
                    family: 'HK Grotesk',
                    weight: '600',
                    style: 'normal',
                    size: 32,
                    character: 0,
                    line: 42,
                    transform: 'uppercase',
                },
            },
        },
        elements: {
            clientLogo: {
                type: 'image',
                value: 'https://cdn.backstage-api.com?key=backstage-cms-production-uploads/3840x2160/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/theming/d6b609ca-0d3d-44ab-8d43-896010a5fd63/d02536a7-6953-4068-b570-aadaf4dae23d-24ilogo big.png',
                label: 'Logo',
            },
            backgroundImage: {
                type: 'image',
                value: null,
                label: 'Background image',
            },
            'buttonPrimary-cornerRadius': {
                type: 'cornerRadius',
                value: '100px',
                label: 'Primary corner radius',
            },
            'buttonSecondary-cornerRadius': {
                type: 'cornerRadius',
                value: '100px',
                label: 'Secondary corner radius',
            },
            'buttonTertiary-cornerRadius': {
                type: 'cornerRadius',
                value: '100px',
                label: 'Tertiary corner radius',
            },
            'generalTile-cornerRadius': {
                type: 'cornerRadius',
                value: '8px',
                label: 'General corner radius',
            },
            'headerTile-cornerRadius': {
                type: 'cornerRadius',
                value: '8px',
                label: 'Header corner radius',
            },
            'textField-cornerRadius': {
                type: 'cornerRadius',
                value: '100px',
                label: 'Text corner radius',
            },
            'searchField-cornerRadius': {
                type: 'cornerRadius',
                value: '100px',
                label: 'Search corner radius',
            },
            'pinField-cornerRadius': {
                type: 'cornerRadius',
                value: '8px',
                label: 'PIN corner radius',
            },
            'epgItem-cornerRadius': {
                type: 'cornerRadius',
                value: '6px',
                label: 'EPG corner radius',
            },
            'label-cornerRadius': {
                type: 'cornerRadius',
                value: '4px',
                label: 'Label corner radius',
            },
            'progressBar-cornerRadius': {
                type: 'cornerRadius',
                value: '100px',
                label: 'Progress bar corner radius',
            },
        },
        icons: {
            menu: {
                navbarFont: 'fontAwesome',
                iconsMap: {
                    dashboard: {
                        name: 'home',
                        font: 'fontAwesome',
                    },
                    'new-movies': {
                        name: 'film',
                        font: 'fontAwesome',
                    },
                    'live-tv': {
                        name: 'tv',
                        font: 'fontAwesome',
                    },
                    search: {
                        name: 'search',
                        font: 'fontAwesome',
                    },
                    'rewind-2': {
                        name: 'history',
                        font: 'fontAwesome',
                    },
                    settings: {
                        name: 'cog',
                        font: 'fontAwesome',
                    },
                    account: {
                        name: 'user',
                        font: 'fontAwesome',
                    },
                    'play-button': {
                        name: 'play-circle',
                        font: 'fontAwesome',
                    },
                    series: {
                        name: 'square',
                        font: 'fontAwesome',
                    },
                    menu: {
                        name: 'bars',
                        font: 'fontAwesome',
                    },
                    guide: {
                        name: 'list',
                        font: 'fontAwesome',
                    },
                    'all-movies': {
                        name: 'film',
                        font: 'fontAwesome',
                    },
                    mail: {
                        name: 'envelope',
                        font: 'fontAwesome',
                    },
                    'no-notifications': {
                        name: 'volume-off',
                        font: 'fontAwesome',
                    },
                    notifications: {
                        name: 'volume-up',
                        font: 'fontAwesome',
                    },
                    clock: {
                        name: 'clock-o',
                        font: 'fontAwesome',
                    },
                    'lock-disabled': {
                        name: 'unlock-alt',
                        font: 'fontAwesome',
                    },
                    record: {
                        name: 'circle',
                        font: 'fontAwesome',
                    },
                    min: {
                        name: 'minus-circle',
                        font: 'fontAwesome',
                    },
                    plus: {
                        name: 'plus-circle',
                        font: 'fontAwesome',
                    },
                    cross: {
                        name: 'remove',
                        font: 'fontAwesome',
                    },
                    'rewind-1': {
                        name: 'backward',
                        font: 'fontAwesome',
                    },
                    'fast-forward': {
                        name: 'forward',
                        font: 'fontAwesome',
                    },
                    share: {
                        name: 'share-alt-square',
                        font: 'fontAwesome',
                    },
                    subs: {
                        name: 'warning',
                        font: 'fontAwesome',
                    },
                    chat: {
                        name: 'wechat',
                        font: 'fontAwesome',
                    },
                    'episode-selector': {
                        name: 'warning',
                        font: 'fontAwesome',
                    },
                    star: {
                        name: 'star-o',
                        font: 'fontAwesome',
                    },
                    'star-full': {
                        name: 'star',
                        font: 'fontAwesome',
                    },
                    circle: {
                        name: 'circle-o',
                        font: 'fontAwesome',
                    },
                    'circle-full': {
                        name: 'circle',
                        font: 'fontAwesome',
                    },
                    'stop-watching': {
                        name: 'eye-slash',
                        font: 'fontAwesome',
                    },
                    info: {
                        name: 'info-circle',
                        font: 'fontAwesome',
                    },
                    'record-1': {
                        name: 'warning',
                        font: 'fontAwesome',
                    },
                    'record-2': {
                        name: 'warning',
                        font: 'fontAwesome',
                    },
                    'record-3': {
                        name: 'warning',
                        font: 'fontAwesome',
                    },
                    'record-4': {
                        name: 'circle',
                        font: 'fontAwesome',
                    },
                    'half-moon': {
                        name: 'moon-o',
                        font: 'fontAwesome',
                    },
                    space: {
                        name: 'window-minimize',
                        font: 'fontAwesome',
                    },
                    signout: {
                        name: 'sign-out',
                        font: 'fontAwesome',
                    },
                    backspace: {
                        name: 'window-close',
                        font: 'fontAwesome',
                    },
                    'network-off': {
                        name: 'warning',
                        font: 'fontAwesome',
                    },
                    bars: {
                        name: 'bars',
                        font: 'fontAwesome',
                    },
                    heart: {
                        name: 'heart',
                        font: 'fontAwesome',
                    },
                    'sign-in': {
                        name: 'sign-in',
                        font: 'fontAwesome',
                    },
                    'angle-down': {
                        name: 'angle-down',
                        font: 'fontAwesome',
                    },
                    'menu-icons-hamburger-bkstg-state-selected': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/60e4f24c-a8eb-4279-bedf-a8844351052d-menu-icons-hamburger-bkstg-state-selected.png',
                    },
                    'menu-icons-video-library-bkstg-state-selected': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/e4735896-5087-4e2d-93e4-c6168bada980-menu-icons-video-library-bkstg-state-selected.png',
                    },
                    'menu-icons-watch-later-bkstg-state-selected': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/2f24cf2d-af83-466a-987f-b8fdb4ede104-menu-icons-watch-later-bkstg-state-selected.png',
                    },
                    'menu-icons-movie-clap-bkstg-state-selected': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/e7817908-ad81-4b62-8f41-e41a7f478fac-menu-icons-movie-clap-bkstg-state-selected.png',
                    },
                    'menu-icons-videocam-bkstg-state-selected': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/6b76e76a-6c10-4c99-ad24-a489f76d6d4e-menu-icons-videocam-bkstg-state-selected.png',
                    },
                    'menu-icons-account-bkstg-state-selected': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/76a67c50-fadb-43a0-a693-4ce0d0d0f7be-menu-icons-account-bkstg-state-selected.png',
                    },
                    'menu-icons-heart-bkstg-state-selected': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/e6a8c21b-c2c1-427f-9acd-9f8be45d88e7-menu-icons-heart-bkstg-state-selected.png',
                    },
                    'menu-icons-phone-bkstg-state-selected': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/19de90b4-2812-4c83-a22d-566cc684ee2a-menu-icons-phone-bkstg-state-selected.png',
                    },
                    'menu-icons-play-bkstg-state-selected': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/963ef5cd-7f79-4677-8244-c018a5a7f288-menu-icons-play-bkstg-state-selected.png',
                    },
                    'menu-icons-vod-bkstg-state-selected': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/b2fdae96-3212-473e-8f47-12149c7f313d-menu-icons-vod-bkstg-state-selected.png',
                    },
                    'menu-icons-epg-bkstg-state-selected': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/ff75efc7-0890-4a2c-8547-9a3ecd112a13-menu-icons-epg-bkstg-state-selected.png',
                    },
                    'menu-icons-phone': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/9342f63c-d750-4b6d-b7fb-cd53eaea584c-menu-icons-phone.png',
                    },
                    'menu-icons-reminders-bkstg-state-selected': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/0ddbf93e-bf93-43a4-92b0-4077c1ba8fa2-menu-icons-reminders-bkstg-state-selected.png',
                    },
                    'menu-icons-history-bkstg-state-selected': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/d5972b04-3105-4265-bc50-de6719aef0f8-menu-icons-history-bkstg-state-selected.png',
                    },
                    'menu-icons-info-bkstg-state-selected': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/9246e866-c3d3-4dac-9d8e-9a22b22d7346-menu-icons-info-bkstg-state-selected.png',
                    },
                    'menu-icons-film-roll': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/b6788930-87fe-438a-8c54-6de582b6f3c1-menu-icons-film-roll.png',
                    },
                    'menu-icons-live-tv': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/50fcf236-ff2e-4424-bd94-11248e6cdb33-menu-icons-live-tv.png',
                    },
                    'menu-icons-settings': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/5864818c-cc18-4da6-9d04-329ffe1f798d-menu-icons-settings.png',
                    },
                    'menu-icons-list': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/81f2696a-7924-429b-ac23-c1d36c5a932d-menu-icons-list.png',
                    },
                    'menu-icons-home': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/680ee0e5-b4c3-4f76-b50d-91bb4a00816d-menu-icons-home.png',
                    },
                    'menu-icons-tv': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/3f4dde1d-f074-4b5e-a730-fdcc9be73083-menu-icons-tv.png',
                    },
                    'menu-icons-recordings-bkstg-state-selected': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/67dafff8-8664-485d-b9bd-1eb6810d621a-menu-icons-recordings-bkstg-state-selected.png',
                    },
                    'menu-icons-settings-bkstg-state-selected': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/10313b11-c97a-4741-8cbd-c8edbc300454-menu-icons-settings-bkstg-state-selected.png',
                    },
                    'menu-icons-star-bkstg-state-selected': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/ac3b2c03-4fa9-46d4-8a1c-2dd5325cf300-menu-icons-star-bkstg-state-selected.png',
                    },
                    'menu-icons-social-twitter': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/b5dac0f7-4e3a-41e5-846f-e2353e3452cc-menu-icons-social-twitter.png',
                    },
                    'menu-icons-reminders': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/fe3fbb86-b600-4d03-8b20-3dd0f1037e54-menu-icons-reminders.png',
                    },
                    'menu-icons-downloads': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/9f8c4cb3-a238-4c4b-b7c0-2f79deeaf887-menu-icons-downloads.png',
                    },
                    'menu-icons-videocam': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/471bdb06-7bac-4c8a-8b6d-41ca773aa727-menu-icons-videocam.png',
                    },
                    'menu-icons-search': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/f830a6a4-cc41-462d-ad20-9c4e4f85cc79-menu-icons-search.png',
                    },
                    'menu-icons-star': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/f6798319-908d-4ca7-bd39-ec8d42d69ff5-menu-icons-star.png',
                    },
                    'menu-icons-live-tv-bkstg-state-selected': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/fbbd5eb1-5bbe-408a-8e86-63fcf1660446-menu-icons-live-tv-bkstg-state-selected.png',
                    },
                    'menu-icons-replay-bkstg-state-selected': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/2ba5693d-4341-4ee9-bd24-afcac36bc5ed-menu-icons-replay-bkstg-state-selected.png',
                    },
                    'menu-icons-email-bkstg-state-selected': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/afa9bfa8-9326-4c8f-b78b-64e7dc3cad92-menu-icons-email-bkstg-state-selected.png',
                    },
                    'menu-icons-home-bkstg-state-selected': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/00164cd1-59f0-47c9-a761-0dd43454571f-menu-icons-home-bkstg-state-selected.png',
                    },
                    'menu-icons-social-instagram': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/1f861c36-84b9-4287-b10c-d51a6e606582-menu-icons-social-instagram.png',
                    },
                    'menu-icons-video-library': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/64920f19-af27-49c7-827a-de7e70e12f9c-menu-icons-video-library.png',
                    },
                    'menu-icons-history': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/da5263aa-190a-4820-ae0a-fd49e8172b1a-menu-icons-history.png',
                    },
                    'menu-icons-email': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/a0788938-5aba-4a5b-bf70-48f1d39021d3-menu-icons-email.png',
                    },
                    'menu-icons-heart': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/31f1f06c-6691-4c5a-af1c-cc5311a99a5f-menu-icons-heart.png',
                    },
                    'menu-icons-play': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/cee3caec-517d-496b-984f-938f275094f4-menu-icons-play.png',
                    },
                    'menu-icons-credit-card-bkstg-state-selected': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/524a7d6c-147a-4d2c-9f0c-19515f74c0e9-menu-icons-credit-card-bkstg-state-selected.png',
                    },
                    'menu-icons-search-bkstg-state-selected': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/400d8227-3ecc-45d9-b261-7bfa73b77e19-menu-icons-search-bkstg-state-selected.png',
                    },
                    'menu-icons-social-youtube': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/14a3204a-345c-47c0-8778-d6a806d91e75-menu-icons-social-youtube.png',
                    },
                    'menu-icons-watch-later': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/cdd7c038-0336-463c-af39-3b61c480bb44-menu-icons-watch-later.png',
                    },
                    'menu-icons-movie-clap': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/c244788b-5aa9-43b4-83c7-fe58fbeec347-menu-icons-movie-clap.png',
                    },
                    'menu-icons-recordings': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/e901149e-2180-423d-add3-70e9f66253d9-menu-icons-recordings.png',
                    },
                    'menu-icons-hamburger': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/21339fb3-7cf3-467d-a3da-9a9bed304911-menu-icons-hamburger.png',
                    },
                    'menu-icons-account': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/5fc50de9-97e6-4701-9528-40bd1a065310-menu-icons-account.png',
                    },
                    'menu-icons-info': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/b1606808-2981-44e9-81e1-0945a30dfa5e-menu-icons-info.png',
                    },
                    'menu-icons-list-bkstg-state-selected': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/062d2d7f-f19e-4b6d-ac95-ea13bc583d8c-menu-icons-list-bkstg-state-selected.png',
                    },
                    'menu-icons-tv-bkstg-state-selected': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/39ec0a19-d4d9-432e-a7b0-2153d24dcca1-menu-icons-tv-bkstg-state-selected.png',
                    },
                    'menu-icons-replay': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/6e4a877a-1aff-4cf6-a72e-e82892692e52-menu-icons-replay.png',
                    },
                    'menu-icons-vod': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/c5faa2ae-8a34-4111-97c6-90aace05d953-menu-icons-vod.png',
                    },
                    'menu-icons-epg': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/360285b8-af31-44f9-b8e7-6841847246d8-menu-icons-epg.png',
                    },
                    'menu-icons-film-roll-bkstg-state-selected': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/0271fc95-5396-4c96-993c-4dcdeebf8094-menu-icons-film-roll-bkstg-state-selected.png',
                    },
                    'menu-icons-downloads-bkstg-state-selected': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/50ca9b1e-a14a-419f-a56c-435030b97a1a-menu-icons-downloads-bkstg-state-selected.png',
                    },
                    'menu-icons-social-facebook': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/0a740236-c826-478d-90ed-81b035c8b764-menu-icons-social-facebook.png',
                    },
                    'menu-icons-credit-card': {
                        size: {
                            width: 128,
                            height: 128,
                        },
                        uri: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/icons-sets/svg/128x128/5ecc88f1-a75a-46a6-8eee-95a8b4829269-menu-icons-credit-card.png',
                    },
                },
                more: {
                    iconFont: 'fontAwesome',
                    iconName: 'bars',
                },
                forward: {
                    iconFont: 'fontAwesome',
                    iconName: 'chevron-right',
                },
                back: {
                    iconFont: 'materialIcons',
                    iconName: 'chevron-left',
                },
                menuScrollUp: {
                    iconFont: 'fontAwesome',
                    iconName: 'chevron-up',
                },
                menuScrollDown: {
                    iconFont: 'fontAwesome',
                    iconName: 'chevron-down',
                },
            },
            player: {
                play: {
                    iconFont: 'materialIcons',
                    iconName: 'play-arrow',
                },
                pause: {
                    iconFont: 'materialIcons',
                    iconName: 'pause',
                },
                cast: {
                    iconFont: 'materialIcons',
                    iconName: 'cast',
                },
                settings: {
                    iconFont: 'fontAwesome',
                    iconName: 'sliders',
                },
                seekForward: {
                    iconFont: 'materialIcons',
                    iconName: 'replay-10',
                },
                seekBackward: {
                    iconFont: 'materialIcons',
                    iconName: 'forward-10',
                },
                pictureInPicture: {
                    iconFont: 'materialIcons',
                    iconName: 'picture-in-picture-alt',
                },
                fullSchedule: {
                    iconFont: 'materialIcons',
                    iconName: 'dvr',
                },
                fullscreen: {
                    iconFont: 'materialIcons',
                    iconName: 'fullscreen-exit',
                },
                audioAndSubs: {
                    iconFont: 'materialIcons',
                    iconName: 'subtitles',
                },
                moreEpisodes: {
                    iconFont: 'materialIcons',
                    iconName: 'burst-mode',
                },
                previousEpisode: {
                    iconFont: 'materialIcons',
                    iconName: 'skip-previous',
                },
                nextEpisode: {
                    iconFont: 'materialIcons',
                    iconName: 'skip-next',
                },
                otherChannels: {
                    iconFont: 'materialIcons',
                    iconName: 'burst-mode',
                },
                record: {
                    iconFont: 'materialIcons',
                    iconName: 'fiber-manual-record',
                },
                startOver: {
                    iconFont: 'materialIcons',
                    iconName: 'replay',
                },
                volumeUp: {
                    iconFont: 'materialIcons',
                    iconName: 'volume-up',
                },
                volumeDown: {
                    iconFont: 'materialIcons',
                    iconName: 'volume-down',
                },
                volumeOff: {
                    iconFont: 'materialIcons',
                    iconName: 'volume-off',
                },
            },
            settings: {
                back: {
                    iconFont: 'ionicons',
                    iconName: 'ios-arrow-back',
                },
                checkmark: {
                    iconFont: 'antDesign',
                    iconName: 'check',
                },
                chevronRight: {
                    iconFont: 'ionicons',
                    iconName: 'ios-arrow-forward',
                },
                check: {
                    iconFont: 'materialCommunityIcons',
                    iconName: 'check',
                },
                close: {
                    iconFont: 'antDesign',
                    iconName: 'close',
                },
                iphone: {
                    iconFont: 'materialIcons',
                    iconName: 'phone-iphone',
                },
                android: {
                    iconFont: 'materialIcons',
                    iconName: 'phone-android',
                },
                ipad: {
                    iconFont: 'materialCommunityIcons',
                    iconName: 'tablet-ipad',
                },
                tabletAndroid: {
                    iconFont: 'materialCommunityIcons',
                    iconName: 'tablet-android',
                },
                laptop: {
                    iconFont: 'materialCommunityIcons',
                    iconName: 'laptop',
                },
                language: {
                    iconFont: 'materialIcons',
                    iconName: 'language',
                },
                info: {
                    iconFont: 'materialIcons',
                    iconName: 'info',
                },
                service: {
                    iconFont: 'materialIcons',
                    iconName: 'swap-horiz',
                },
                appTheme: {
                    iconFont: 'materialIcons',
                    iconName: 'palette',
                },
            },
            search: {
                dropdownItem: {
                    iconFont: 'materialCommunityIcons',
                    iconName: 'clock-outline',
                },
                input: {
                    iconFont: 'materialIcons',
                    iconName: 'search',
                },
                clear: {
                    iconFont: 'materialIcons',
                    iconName: 'clear',
                },
                cancel: {
                    iconFont: 'materialIcons',
                    iconName: 'cancel',
                },
            },
            details: {
                addToList: {
                    iconFont: 'materialCommunityIcons',
                    iconName: 'plus',
                },
                removeFromList: {
                    iconFont: 'materialIcons',
                    iconName: 'check',
                },
                record: {
                    iconFont: 'materialCommunityIcons',
                    iconName: 'record',
                },
                share: {
                    iconFont: 'materialCommunityIcons',
                    iconName: 'share-variant',
                },
                play: {
                    iconFont: 'ionicons',
                    iconName: 'play',
                },
                remind: {
                    iconFont: 'materialIcons',
                    iconName: 'alarm-add',
                },
                unrated: {
                    iconFont: 'materialCommunityIcons',
                    iconName: 'star-outline',
                },
                startOver: {
                    iconFont: 'materialIcons',
                    iconName: 'replay',
                },
                rated: {
                    iconFont: 'materialCommunityIcons',
                    iconName: 'star',
                },
                geoBlocked: {
                    iconFont: 'materialIcons',
                    iconName: 'vpn-lock',
                },
                cancelRecording: {
                    iconFont: 'materialIcons',
                    iconName: 'cancel',
                },
                deleteRecording: {
                    iconFont: 'materialIcons',
                    iconName: 'delete',
                },
                setReminder: {
                    iconFont: 'materialIcons',
                    iconName: 'alarm-add',
                },
                reminderIsSet: {
                    iconFont: 'materialIcons',
                    iconName: 'alarm-on',
                },
            },
            myContent: {
                myList: {
                    iconFont: 'materialCommunityIcons',
                    iconName: 'plus',
                },
                downloads: {
                    iconFont: 'materialCommunityIcons',
                    iconName: 'cellphone-arrow-down',
                },
                recordings: {
                    iconFont: 'materialCommunityIcons',
                    iconName: 'record',
                },
                purchases: {
                    iconFont: 'materialCommunityIcons',
                    iconName: 'credit-card',
                },
                reminders: {
                    iconFont: 'materialCommunityIcons',
                    iconName: 'alarm-check',
                },
                recentlyWatched: {
                    iconFont: 'materialCommunityIcons',
                    iconName: 'history',
                },
                pencil: {
                    iconFont: 'materialCommunityIcons',
                    iconName: 'pencil',
                },
                trash: {
                    iconFont: 'ionicons',
                    iconName: 'md-trash',
                },
            },
            auth: {
                incorrect: {
                    iconFont: 'antDesign',
                    iconName: 'exclamationcircle',
                },
            },
            packshot: {
                moreInfo: {
                    iconFont: 'ionicons',
                    iconName: 'ios-information-circle-outline',
                },
                play: {
                    iconFont: 'materialIcons',
                    iconName: 'play-arrow',
                },
                check: {
                    iconFont: 'materialIcons',
                    iconName: 'check',
                },
                delete: {
                    iconFont: 'materialIcons',
                    iconName: 'delete',
                },
                cancel: {
                    iconFont: 'materialIcons',
                    iconName: 'clear',
                },
            },
            epg: {
                chevronRight: {
                    iconFont: 'materialIcons',
                    iconName: 'chevron-right',
                },
                portraitChannelView: {
                    iconImage: 8,
                },
                portraitTimeView: {
                    iconImage: 9,
                },
                filter: {
                    iconFont: 'materialIcons',
                    iconName: 'list',
                },
                calendar: {
                    iconFont: 'materialIcons',
                    iconName: 'insert-invitation',
                },
            },
            common: {
                modalClose: {
                    iconFont: 'materialIcons',
                    iconName: 'close',
                },
                dropdownExpand: {
                    iconFont: 'materialIcons',
                    iconName: 'expand-more',
                },
                arrowUp: {
                    iconFont: 'materialIcons',
                    iconName: 'keyboard-arrow-up',
                },
                arrowDown: {
                    iconFont: 'materialIcons',
                    iconName: 'keyboard-arrow-down',
                },
            },
            about: {
                buttonIcon: {
                    iconFont: 'materialCommunityIcons',
                    iconName: 'exit-to-app',
                },
            },
            pinControl: {
                checkmark: {
                    iconFont: 'fontAwesome',
                    iconName: 'check',
                },
                backspace: {
                    iconFont: 'materialIcons',
                    iconName: 'backspace',
                },
            },
            account: {
                user: {
                    iconFont: 'materialIcons',
                    iconName: 'person',
                },
                subscriptions: {
                    iconFont: 'materialIcons',
                    iconName: 'credit-card',
                },
                parentalControl: {
                    iconFont: 'materialIcons',
                    iconName: 'face',
                },
                signin: {
                    iconFont: 'materialIcons',
                    iconName: 'login',
                },
                devices: {
                    iconFont: 'materialCommunityIcons',
                    iconName: 'devices',
                },
            },
            chromecast: {
                tv: {
                    iconFont: 'materialIcons',
                    iconName: 'tv',
                },
                volumeUp: {
                    iconFont: 'materialIcons',
                    iconName: 'volume-up',
                },
                volumeOff: {
                    iconFont: 'materialIcons',
                    iconName: 'volume-off',
                },
                volumeDown: {
                    iconFont: 'materialIcons',
                    iconName: 'volume-down',
                },
                castConnected: {
                    iconFont: 'materialIcons',
                    iconName: 'cast-connected',
                },
                skipPrev: {
                    iconFont: 'materialIcons',
                    iconName: 'skip-previous',
                },
                related: {
                    iconFont: 'materialIcons',
                    iconName: 'playlist-play',
                },
                subtitles: {
                    iconFont: 'materialIcons',
                    iconName: 'subtitles',
                },
                seekForward: {
                    iconFont: 'materialIcons',
                    iconName: 'forward-10',
                },
                seekBackward: {
                    iconFont: 'materialIcons',
                    iconName: 'replay-10',
                },
            },
            'onPrimary-back': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onPrimary-back.svg',
                        density: 1,
                    },
                ],
            },
            'onPrimary-back_rtl': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onPrimary-back_rtl.svg',
                        density: 1,
                    },
                ],
            },
            'onPrimary-overflow': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onPrimary-overflow.svg',
                        density: 1,
                    },
                ],
            },
            'onPrimary-select': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onPrimary-select.svg',
                        density: 1,
                    },
                ],
            },
            'onPrimary-share': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onPrimary-share.svg',
                        density: 1,
                    },
                ],
            },
            'onPrimary-confirm': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onPrimary-confirm.svg',
                        density: 1,
                    },
                ],
            },
            'onPrimary-close': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onPrimary-close.svg',
                        density: 1,
                    },
                ],
            },
            'onPrimary-airplay': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onPrimary-airplay.svg',
                        density: 1,
                    },
                ],
            },
            'onPrimary-chromecast': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onPrimary-chromecast.svg',
                        density: 1,
                    },
                ],
            },
            'onPrimary-chromecast_connected': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onPrimary-chromecast_connected.svg',
                        density: 1,
                    },
                ],
            },
            'onPrimary-filter': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onPrimary-filter.svg',
                        density: 1,
                    },
                ],
            },
            'onPrimary-spinner': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onPrimary-spinner.svg',
                        density: 1,
                    },
                ],
            },
            'onPrimary-subpage': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onPrimary-subpage.svg',
                        density: 1,
                    },
                ],
            },
            'onPrimary-subpage_rtl': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onPrimary-subpage_rtl.svg',
                        density: 1,
                    },
                ],
            },
            'onPrimary-clear': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onPrimary-clear.svg',
                        density: 1,
                    },
                ],
            },
            'onPrimary-delete': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onPrimary-delete.svg',
                        density: 1,
                    },
                ],
            },
            'onPrimary-seeAll': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onPrimary-seeAll.svg',
                        density: 1,
                    },
                ],
            },
            'onPrimary-rating': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onPrimary-rating.svg',
                        density: 1,
                    },
                ],
            },
            'onPrimary-expiration': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onPrimary-expiration.svg',
                        density: 1,
                    },
                ],
            },
            'onPrimary-error': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'https://cdn.backstage-api.com?original=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/themes-icons/svg/5bc553bf-7a58-4c45-b791-feaf0cc3b96b-onPrimary-error.svg',
                        density: 1,
                    },
                ],
                png: [
                    {
                        path: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/themes-icons/svg/16x16/5bc553bf-7a58-4c45-b791-feaf0cc3b96b-onPrimary-error.png',
                    },
                    {
                        path: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/themes-icons/svg/32x32/5bc553bf-7a58-4c45-b791-feaf0cc3b96b-onPrimary-error.png',
                    },
                    {
                        path: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/themes-icons/svg/128x128/5bc553bf-7a58-4c45-b791-feaf0cc3b96b-onPrimary-error.png',
                    },
                    {
                        path: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/themes-icons/svg/256x256/5bc553bf-7a58-4c45-b791-feaf0cc3b96b-onPrimary-error.png',
                    },
                    {
                        path: 'https://cdn.backstage-api.com/?key=backstage-cms-production-uploads/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/themes-icons/svg/512x512/5bc553bf-7a58-4c45-b791-feaf0cc3b96b-onPrimary-error.png',
                    },
                ],
            },
            'onPrimary-device_tv': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onPrimary-device_tv.svg',
                        density: 1,
                    },
                ],
            },
            'onPrimary-device_laptop': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onPrimary-device_laptop.svg',
                        density: 1,
                    },
                ],
            },
            'onPrimary-device_tablet': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onPrimary-device_tablet.svg',
                        density: 1,
                    },
                ],
            },
            'onPrimary-device_smartphone': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onPrimary-device_smartphone.svg',
                        density: 1,
                    },
                ],
            },
            'onPrimary-device_smartwatch': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onPrimary-device_smartwatch.svg',
                        density: 1,
                    },
                ],
            },
            'onPrimary-device_family': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onPrimary-device_family.svg',
                        density: 1,
                    },
                ],
            },
            'onPrimary-device_gameConsole': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onPrimary-device_gameConsole.svg',
                        density: 1,
                    },
                ],
            },
            'onPrimary-scroll_up': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onPrimary-scroll_up.svg',
                        density: 1,
                    },
                ],
            },
            'onPrimary-scroll_down': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onPrimary-scroll_down.svg',
                        density: 1,
                    },
                ],
            },
            'onPrimary-field_search': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onPrimary-field_search.svg',
                        density: 1,
                    },
                ],
            },
            'onPrimary-field_clear': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onPrimary-field_clear.svg',
                        density: 1,
                    },
                ],
            },
            'onPrimary-signOut': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onPrimary-signOut.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-back': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-back.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-back_rtl': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-back_rtl.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-overflow': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-overflow.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-select': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-select.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-share': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-share.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-confirm': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-confirm.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-close': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-close.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-airplay': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-airplay.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-chromecast': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-chromecast.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-chromecast_connected': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-chromecast_connected.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-filter': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-filter.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-control_play': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-control_play.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-control_pause': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-control_pause.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-control_stop': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-control_stop.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-control_upsell': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-control_upsell.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-control_info': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-control_info.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-control_rewind': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-control_rewind.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-control_fastForward': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-control_fastForward.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-control_skipPrevious': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-control_skipPrevious.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-control_skipNext': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-control_skipNext.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-control_replay10': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-control_replay10.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-control_forward10': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-control_forward10.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-control_volume_mute': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-control_volume_mute.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-control_volume_down': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-control_volume_down.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-control_volume_up': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-control_volume_up.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-control_volume_off': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-control_volume_off.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-fullscreen': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-fullscreen.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-fullscreen_exit': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-fullscreen_exit.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-pictureInPicture': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-pictureInPicture.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-subtitles': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-subtitles.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-settings': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-settings.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-record': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-record.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-recordMultiple': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-recordMultiple.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-record_cancel': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-record_cancel.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-recordMultiple_cancel': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-recordMultiple_cancel.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-tvGuide': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-tvGuide.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-catchup': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-catchup.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-backToLive': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-backToLive.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-allChannels': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-allChannels.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-allEpisodes': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-allEpisodes.svg',
                        density: 1,
                    },
                ],
            },
            'onDark-rating': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onDark-rating.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonPrimary-play': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonPrimary-play.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonPrimary-record': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonPrimary-record.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonPrimary-recordMultiple': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonPrimary-recordMultiple.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonPrimary-record_cancel': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonPrimary-record_cancel.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonPrimary-recordMultiple_cancel': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonPrimary-recordMultiple_cancel.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonPrimary-purchase': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonPrimary-purchase.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonPrimary-rent': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonPrimary-rent.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonPrimary-upsell': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonPrimary-upsell.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonPrimary-purchase_multipleOptions': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonPrimary-purchase_multipleOptions.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonPrimary-locked': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonPrimary-locked.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonSecondary-startOver': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonSecondary-startOver.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonSecondary-record': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonSecondary-record.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonSecondary-recordMultiple': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonSecondary-recordMultiple.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonSecondary-record_cancel': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonSecondary-record_cancel.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonSecondary-recordMultiple_cancel': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonSecondary-recordMultiple_cancel.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonSecondary-watchlist_add': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonSecondary-watchlist_add.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonSecondary-watchlist_added': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonSecondary-watchlist_added.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonSecondary-reminder_add': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonSecondary-reminder_add.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonSecondary-reminder_added': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonSecondary-reminder_added.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonSecondary-share': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonSecondary-share.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonSecondary-download': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonSecondary-download.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonSecondary-info': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonSecondary-info.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonTertiary-startOver': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonTertiary-startOver.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonTertiary-record': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonTertiary-record.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonTertiary-recordMultiple': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonTertiary-recordMultiple.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonTertiary-record_cancel': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonTertiary-record_cancel.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonTertiary-recordMultiple_cancel': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonTertiary-recordMultiple_cancel.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonTertiary-watchlist_add': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonTertiary-watchlist_add.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonTertiary-watchlist_added': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonTertiary-watchlist_added.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonTertiary-reminder_add': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonTertiary-reminder_add.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonTertiary-reminder_added': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonTertiary-reminder_added.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonTertiary-share': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonTertiary-share.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonTertiary-download': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonTertiary-download.svg',
                        density: 1,
                    },
                ],
            },
            'onButtonTertiary-info': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onButtonTertiary-info.svg',
                        density: 1,
                    },
                ],
            },
            'onTileMainButton-play': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onTileMainButton-play.svg',
                        density: 1,
                    },
                ],
            },
            'onTileMainButton-edit': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onTileMainButton-edit.svg',
                        density: 1,
                    },
                ],
            },
            'onLabel-catchup': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onLabel-catchup.svg',
                        density: 1,
                    },
                ],
            },
            'onLabel-record': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onLabel-record.svg',
                        density: 1,
                    },
                ],
            },
            'onLabel-recordMultiple': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onLabel-recordMultiple.svg',
                        density: 1,
                    },
                ],
            },
            'onLabel-reminder': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onLabel-reminder.svg',
                        density: 1,
                    },
                ],
            },
            'onLabel-locked': {
                width: 24,
                height: 24,
                svg: [
                    {
                        path: 'onLabel-locked.svg',
                        density: 1,
                    },
                ],
            },
        },
        logo: 'https://cdn.backstage-api.com?key=backstage-cms-production-uploads/3840x2160/5e0ad1b0-515e-11e9-a7ed-371ac744bd33/theming/d6b609ca-0d3d-44ab-8d43-896010a5fd63/d02536a7-6953-4068-b570-aadaf4dae23d-24ilogo big.png',
        backgroundImage: null,
        color: {
            background1: '#2a2a2a',
            primary1: 'rgba(62, 61, 64, 1)',
            primary2: 'rgba(62, 61, 64, 0.8)',
            primary3: 'rgba(62, 61, 64, 0.6)',
            primary4: 'rgba(62, 61, 64, 0.3)',
            primaryBlur1: {
                type: 'backgroundBlur',
                color: 'rgba(62, 61, 64, 0.8)',
                blur: 30,
            },
            primaryBlur1Fallback: 'rgba(62, 61, 64, 0.9)',
            primaryBlur2: {
                type: 'backgroundBlur',
                color: 'rgba(62, 61, 64, 0.3)',
                blur: 20,
            },
            primaryBlur2Fallback: 'rgba(62, 61, 64, 0.6)',
            lighter1: 'rgba(97, 98, 100, 1)',
            lighter2: 'rgba(97, 98, 100, 0.8)',
            lighter3: 'rgba(97, 98, 100, 0.6)',
            lighter4: 'rgba(97, 98, 100, 0.3)',
            lighterBlur1: {
                type: 'backgroundBlur',
                color: 'rgba(97, 98, 100, 0.8)',
                blur: 16,
            },
            lighterBlur1Fallback: 'rgba(97, 98, 100, 0.9)',
            lighterBlur2: {
                type: 'backgroundBlur',
                color: 'rgba(97, 98, 100, 0.4)',
                blur: 16,
            },
            lighterBlur2Fallback: 'rgba(97, 98, 100, 0.6)',
            darker1: 'rgba(31, 31, 31, 1)',
            darker2: 'rgba(31, 31, 31, 0.8)',
            darker3: 'rgba(31, 31, 31, 0.6)',
            darker4: 'rgba(31, 31, 31, 0.3)',
            darkerBlur1: {
                type: 'backgroundBlur',
                color: 'rgba(31, 31, 31, 0.75)',
                blur: 30,
            },
            darkerBlur1Fallback: 'rgba(31, 31, 31, 0.9)',
            darkerBlur2: {
                type: 'backgroundBlur',
                color: 'rgba(31, 31, 31, 0.3)',
                blur: 16,
            },
            darkerBlur2Fallback: 'rgba(31, 31, 31, 0.6)',
            contrast1: 'rgba(247, 247, 247, 1)',
            contrast2: 'rgba(247, 247, 247, 0.8)',
            contrast3: 'rgba(247, 247, 247, 0.6)',
            contrast4: 'rgba(247, 247, 247, 0.3)',
            contrast5: 'rgba(255, 255, 255, 0.8)',
            contrastBlur1: {
                type: 'backgroundBlur',
                color: 'rgba(247, 247, 247, 0.8)',
                blur: 20,
            },
            contrastBlur1Fallback: 'rgba(247, 247, 247, 0.9)',
            contrastBlur2: {
                type: 'backgroundBlur',
                color: 'rgba(247, 247, 247, 0.4)',
                blur: 20,
            },
            contrastBlur2Fallback: 'rgba(247, 247, 247, 0.6)',
            gradient1: {
                colors: ['rgba(0, 0, 0, 0)', 'rgba(0, 0, 1, 1)'],
                percentages: [0, 1],
                type: 'gradient',
                color: 'linear-gradient(0deg, rgba(0, 0, 0, 1) 0.0%, rgba(0, 0, 1, 0) 100.0%)',
            },
            gradient2: {
                colors: ['rgba(0, 0, 0, 0.7)', 'rgba(0, 0, 0, 0)'],
                percentages: [0, 1],
                color: 'linear-gradient(0deg, rgba(74, 74, 74, 1) 0.0%, rgba(74, 74, 74, 1) 0.0%, rgba(255, 255, 255, 1) 100.0%)',
                type: 'gradient',
            },
            gradient3: {
                colors: ['rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0.4)'],
                percentages: [0, 1],
                color: 'linear-gradient(180deg, rgba(74,74,74,1) 0.0%, rgba(0, 0, 0, 0) 100.0%)',
                type: 'gradient',
            },
            error: '#c73600',
            warning: '#ef9e00',
            success: 'rgba(0, 148, 0, 1)',
            textPrimary: '#ffffff',
            textSecondary: '#ffffff',
            textButtonPrimary: 'rgba(0, 0, 0, 1)',
            textButtonSecondary: 'rgba(255, 255, 255, 1)',
            textButtonTertiary: 'rgba(255, 255, 255, 1)',
            buttonPrimary: 'rgba(250, 188, 11, 1)',
            buttonSecondary: 'rgba(97, 98, 100, 0.85)',
            buttonTertiary: 'rgba(97, 98, 100, 0.6)',
            labelPrimary: 'rgba(108, 108, 108, 0.4)',
            labelSecondary: 'rgba(108, 108, 108, 0.4)',
            progressBarAccent: '#ffffff',
            progressBarPreAccent: '#ffffff',
            progressBarLive: 'rgba(0, 173, 221, 1)',
            progressBarBackground: 'rgba(255,  255,  255,  0.3)',
            menuItemActive: '#ffffff',
            menuItemInactive: 'rgba(255, 255, 255, 0.8)',
            menuFocus: 'rgba(255, 255, 255, 0.18)',
            tileMainButton: 'rgba(31, 31, 31, 0.25)',
            epgRowActive: 'rgba(88, 193, 245, 1)',
            epgRowInactive: 'rgba(255, 255, 255, 0.1)',
            epgRowActiveWhole: 'rgba(88, 193, 245, 1)',
            epgRowActivePassed: 'rgba(88, 193, 245, 1)',
            epgTop: 'rgba(31, 31, 31, 0.75)',
            epgSide: 'rgba(42, 42, 42, 1)',
            epgBackground: 'rgba(42, 42, 42, 1)',
            statusBarTheme: 'light-content',
        },
    };
    return theme;
};
