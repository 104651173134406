import React from 'react';
import { View, Text } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { useTranslation } from 'react-i18next';
import { isPlatformWeb } from 'renative';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import IconButton from '@24i/nxg-sdk-gluons/src/components/buttons/IconButton';
import { useDimensions } from '@24i/nxg-sdk-quantum';
import getStyles from '@24i/nxg-sdk-smartott/src/screens/ManageProfilesScreen/components/ManageProfileScreenHeader/styles';
import { ManageProfileScreenHeaderProps } from '@24i/nxg-sdk-smartott/src/screens/ManageProfilesScreen/types';

const ManageProfileScreenHeader = (props: ManageProfileScreenHeaderProps) => {
    const { editing, onBack, onEditPress, allowModeSwitch, shouldDisplayBackArrow } = props;
    const { t } = useTranslation(['sott']);
    const { theme } = useTheme();
    const dimensions = useDimensions();
    const styles = getStyles(theme, dimensions);

    return (
        <View style={styles.headerContainer}>
            {shouldDisplayBackArrow && (
                <IconButton
                    color={theme.color.textPrimary}
                    icon={theme.icons.menu.back}
                    iconSize={isPlatformWeb ? 30 : 40}
                    additionalContainerStyles={styles.goBackButtonContainer}
                    onPress={onBack}
                    testID="manage_profiles_screen_back_button"
                />
            )}
            <Text style={styles.headerText}>
                {!editing ? t('profiles.whoIsWatching.title') : t('profiles.manage.title')}
            </Text>
            {allowModeSwitch && (
                <IconButton
                    color={theme.color.textPrimary}
                    icon={!editing ? theme.icons.myContent.pencil : theme.icons.packshot.check}
                    iconSize={!editing ? 28 : 36}
                    additionalContainerStyles={styles.editButtonContainer}
                    onPress={onEditPress}
                    testID={editing ? 'manage_profiles_screen_confirm_button' : 'edit_button'}
                />
            )}
        </View>
    );
};

export default overridable(ManageProfileScreenHeader, 'ManageProfileScreenHeader');
