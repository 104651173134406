import { ReactNode } from 'react';

export enum SubscriptionFlowStep {
    Selection,
    Confirm,
    Success,
    Failure,
}

interface BaseStepData {
    title: string | null;
    content: ReactNode;
    layout: 'basic' | 'status';
}

export interface BasicStepData extends BaseStepData {
    layout: 'basic';
}

export interface StatusStepData extends BaseStepData {
    layout: 'status';
    actionButton: {
        title: string | null;
        onPress: () => void;
    };
    cancelButton?: {
        title?: string | null;
        onPress: () => void;
    };
}

export type StepData = BasicStepData | StatusStepData;
