import { Source } from './types';

enum IMG_PARAMS {
    WIDTH = 'w',
    HEIGHT = 'h',
    QUALITY = 'q',
}

export const getSource = (source?: Source) => {
    if (typeof source === 'string') {
        return source;
    }
    if (source && typeof source.uri === 'string') {
        return source.uri;
    }

    return undefined;
};

export const splitUrlAtQueryParams = (url: string): string[] => {
    const splitUrl = url.match(/^([^&]*?)\?(.*)$/)?.splice(1);
    return splitUrl ?? [url];
};

export const consumeQueryParam = (queryParams: URLSearchParams, name: string): string | null => {
    const param = queryParams.get(name);
    queryParams.delete(name);

    return param;
};

export const parseImgUrl = (url?: Source) => {
    const src = getSource(url);
    if (!src) return { src: undefined, width: null, height: null, quality: null };

    const [baseUrl, params] = splitUrlAtQueryParams(src);
    const urlSearchParams = new URLSearchParams(params);

    const width = consumeQueryParam(urlSearchParams, IMG_PARAMS.WIDTH);
    const height = consumeQueryParam(urlSearchParams, IMG_PARAMS.HEIGHT);
    const quality = consumeQueryParam(urlSearchParams, IMG_PARAMS.QUALITY);
    const source = `${baseUrl}?${urlSearchParams.toString()}`;
    return { src: source, width, height, quality };
};

export const isSvgUrl = (url: Source) => {
    const src = getSource(url);
    if (!src) return false;
    return src.endsWith('.svg');
};
