import { MenuItem } from '@24i/nxg-sdk-photon/src/models/menuItem';
import { PRODUCT_SCREENS, ACCESS_MODE } from '@24i/nxg-sdk-photon';
import {
    SottScreenConfigBuilders,
    ScreenConfBuilderContext,
    ScreenConfigBuilder,
    SottNavScreenConfs,
} from '../types';
import { isDisableResult } from '../config-builders-lib';

export const getScreenBuildersByProductScreen = ({
    buildAccountScreen,
    buildDetailsScreen,
    buildDynarowScreen,
    buildEpgScreen,
    buildExternalScreen,
    buildForgotPasswordScreen,
    buildGenreScreen,
    buildLandingScreen,
    buildManageProfilesScreen,
    buildWhoIsWatchingScreen,
    buildMoreScreen,
    buildMyContentScreen,
    buildMyListScreen,
    buildNotFoundScreen,
    buildPlaybackScreen,
    buildRecentlyWatchedScreen,
    buildSearchScreen,
    buildSettingsScreen,
    buildSignInScreen,
    buildSignUpScreen,
    buildWelcomeScreen,
    buildUpsertProfileScreen,
    buildCustomMenuScreen,
    buildMyPurchaseScreen,
    buildSubMenuScreen,
    buildHomeScreen,
    buildRecordingsScreen,
    buildTextPageScreen,
    buildManageDevicesScreen,
    buildRemoveDeviceScreen,
    buildSeeAllScreen,
    buildCreatePinScreen,
    buildTermsAndConditionsScreen,
    buildAddSubscriptionScreen,
    buildOnboardingIntroScreen,
    buildAdditionalInfoScreen,
    buildAccountDetailsScreen,
    buildAccountSubscriptionScreen,
    buildAccountParentalControlsScreen,
    buildMySubscriptionsScreen,
    buildSignOutScreen,
    buildFallbackScreen,
    buildPlaylistScreen,
    buildLanguageScreen,
    buildTechnicalInfoScreen,
    buildThemeScreen,
    buildServiceScreen,
    buildStreamingScreen,
    buildDeleteAccountSuccessScreen,
    buildDeleteAccountScreen,
    buildMvpdSignInScreen,
    buildSecondScreenActivationScreen,
    buildPaymentHistoryScreen,
    buildPaymentMethodScreen,
}) => ({
    [PRODUCT_SCREENS.ACCOUNT]: buildAccountScreen,
    [PRODUCT_SCREENS.DETAILS]: buildDetailsScreen,
    [PRODUCT_SCREENS.DYNAMIC_CONTENT]: buildDynarowScreen,
    [PRODUCT_SCREENS.EPG]: buildEpgScreen,
    [PRODUCT_SCREENS.EXTERNAL]: buildExternalScreen,
    [PRODUCT_SCREENS.FORGOT_PASS]: buildForgotPasswordScreen,
    [PRODUCT_SCREENS.GENRE]: buildGenreScreen,
    [PRODUCT_SCREENS.LANDING]: buildLandingScreen,
    [PRODUCT_SCREENS.MANAGE_PROFILES]: buildManageProfilesScreen,
    [PRODUCT_SCREENS.WHO_IS_WATCHING]: buildWhoIsWatchingScreen,
    [PRODUCT_SCREENS.MORE]: buildMoreScreen,
    [PRODUCT_SCREENS.MY_CONTENT]: buildMyContentScreen,
    [PRODUCT_SCREENS.MY_LIST]: buildMyListScreen,
    [PRODUCT_SCREENS.MY_PURCHASES]: buildMyPurchaseScreen,
    [PRODUCT_SCREENS.NOT_FOUND]: buildNotFoundScreen,
    [PRODUCT_SCREENS.PLAYBACK]: buildPlaybackScreen,
    [PRODUCT_SCREENS.RECENTLY_WATCHED]: buildRecentlyWatchedScreen,
    [PRODUCT_SCREENS.SEARCH]: buildSearchScreen,
    [PRODUCT_SCREENS.SETTINGS]: buildSettingsScreen,
    [PRODUCT_SCREENS.SIGN_IN]: buildSignInScreen,
    [PRODUCT_SCREENS.SIGN_UP]: buildSignUpScreen,
    [PRODUCT_SCREENS.SIGN_UP_QR]: buildSignUpScreen,
    [PRODUCT_SCREENS.WELCOME]: buildWelcomeScreen,
    [PRODUCT_SCREENS.UPSERT_PROFILE]: buildUpsertProfileScreen,
    [PRODUCT_SCREENS.CUSTOM]: buildCustomMenuScreen,
    [PRODUCT_SCREENS.SUB_MENU]: buildSubMenuScreen,
    [PRODUCT_SCREENS.HOME]: buildHomeScreen,
    [PRODUCT_SCREENS.RECORDINGS]: buildRecordingsScreen,
    [PRODUCT_SCREENS.TEXT_PAGE]: buildTextPageScreen,
    [PRODUCT_SCREENS.MANAGE_DEVICES]: buildManageDevicesScreen,
    [PRODUCT_SCREENS.REMOVE_DEVICE]: buildRemoveDeviceScreen,
    [PRODUCT_SCREENS.SEE_ALL]: buildSeeAllScreen,
    [PRODUCT_SCREENS.CREATE_PIN]: buildCreatePinScreen,
    [PRODUCT_SCREENS.ONBOARDING_INTRO]: buildOnboardingIntroScreen,
    [PRODUCT_SCREENS.ADDITIONAL_INFO]: buildAdditionalInfoScreen,
    [PRODUCT_SCREENS.TERMS_AND_CONDITIONS]: buildTermsAndConditionsScreen,
    [PRODUCT_SCREENS.ADD_SUBSCRIPTION]: buildAddSubscriptionScreen,
    [PRODUCT_SCREENS.ACCOUNT_DETAILS]: buildAccountDetailsScreen,
    [PRODUCT_SCREENS.ACCOUNT_SUBSCRIPTION]: buildAccountSubscriptionScreen,
    [PRODUCT_SCREENS.ACCOUNT_PARENTAL_CONTROLS]: buildAccountParentalControlsScreen,
    [PRODUCT_SCREENS.MY_SUBSCRIPTIONS]: buildMySubscriptionsScreen,
    [PRODUCT_SCREENS.SIGN_OUT]: buildSignOutScreen,
    [PRODUCT_SCREENS.FALLBACK]: buildFallbackScreen,
    [PRODUCT_SCREENS.PLAYLIST]: buildPlaylistScreen,
    [PRODUCT_SCREENS.LANGUAGE]: buildLanguageScreen,
    [PRODUCT_SCREENS.TECHNICAL_INFO]: buildTechnicalInfoScreen,
    [PRODUCT_SCREENS.THEME]: buildThemeScreen,
    [PRODUCT_SCREENS.SERVICE]: buildServiceScreen,
    [PRODUCT_SCREENS.STREAMING]: buildStreamingScreen,
    [PRODUCT_SCREENS.DELETE_ACCOUNT]: buildDeleteAccountScreen,
    [PRODUCT_SCREENS.DELETE_ACCOUNT_SUCCESS]: buildDeleteAccountSuccessScreen,
    [PRODUCT_SCREENS.MVPD_SIGNIN]: buildMvpdSignInScreen,
    [PRODUCT_SCREENS.SECOND_SCREEN_ACTIVATION]: buildSecondScreenActivationScreen,
    [PRODUCT_SCREENS.PAYMENT_HISTORY]: buildPaymentHistoryScreen,
    [PRODUCT_SCREENS.PAYMENT_METHOD]: buildPaymentMethodScreen,
});

const createScreenConfig = (
    menuItem: MenuItem,
    screenBuilderByProductScreen: Record<PRODUCT_SCREENS, ScreenConfigBuilder>,
    context: ScreenConfBuilderContext
): SottNavScreenConfs | null => {
    const { screenTypeToUse } = menuItem;

    const screenBuilder: ScreenConfigBuilder = screenBuilderByProductScreen[screenTypeToUse || ''];

    if (!screenBuilder) return null;

    // For each screen we first need to construct its embedded screens
    // After the embedded screens are built they are supplied to the root screen builder
    const embeddedScreens: (SottNavScreenConfs | null)[] =
        menuItem.embeddedMenuItems?.map((embeddedMenuItem) =>
            createScreenConfig(embeddedMenuItem, screenBuilderByProductScreen, context)
        ) ?? [];
    const onlyValidEmbeddedScreens = embeddedScreens.filter(Boolean) as SottNavScreenConfs[];

    const screen = screenBuilder(
        {
            ...menuItem,
            embeddedScreens: onlyValidEmbeddedScreens,
        },
        { ...context }
    );

    if (screen.accessMode === ACCESS_MODE.USER && !context.user) return null;

    // The screen has requested not to be built
    if (isDisableResult(screen)) return null;

    return {
        ...screen,
        // We are picking the menu placements here, this done mainly for convenience, so that you don't have to solve this inside the builder yourself
        placementNative: screen.placementNative.menu || [],
        placementWeb: screen.placementWeb.menu || [],
    };
};

/**
 * Notice that we are using screenBuilders for both Web and Mobile versions. What does it mean to "build a screen" for web?
 * This might be confusing. But it reduces the complexity. All platforms are handled similarly.
 * This is because the mobile versions are using react-navigation library which forces user to duplicate screens if they need to appear in different "menus", hence "screen builders"
 */
export const buildScreenConfsFromMenu = (
    menuItems: MenuItem[] = [],
    screenBuilders: SottScreenConfigBuilders,
    context: ScreenConfBuilderContext
): SottNavScreenConfs[] => {
    const screenBuilderByProductScreen = getScreenBuildersByProductScreen(screenBuilders);

    return (
        menuItems
            // @ts-ignore WARNING:missing 2 screens (modal and termsOfUse)
            .map((menuItem) => createScreenConfig(menuItem, screenBuilderByProductScreen, context))
            .filter(Boolean) as SottNavScreenConfs[]
    );
};
