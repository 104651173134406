import { getFont, getBorderRadius } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { ProfileItemStyle } from '../types';

const FALLBACK_BORDER_RADIUS = 12;

const styles = (theme: Theme, adjustedDimensions: number): ProfileItemStyle => ({
    profileItemContainer: {
        padding: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    profileAvatarContainer: {
        shadowColor: 'rgba(0,0,0, 0.23)',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowRadius: 2.62,
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: 'white',
        borderRadius: FALLBACK_BORDER_RADIUS,
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
        overflow: 'hidden',
    },
    newProfileHandlerIconContainer: {
        borderRadius: FALLBACK_BORDER_RADIUS,
        ...getBorderRadius(theme.elements, 'generalTile-cornerRadius'),
        backgroundColor: theme.color.lighter3,
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: adjustedDimensions,
        minWidth: adjustedDimensions,
    },
    newProfileAddIcon: {
        color: theme.color.textPrimary,
    },
    userName: {
        color: theme.color.textPrimary,
        textAlign: 'center',
        marginTop: 4,
        ...getFont(theme, 'h5'),
        width: '100%',
        fontWeight: '600',
    },
    profileImageContainerStyles: {
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: adjustedDimensions,
        minWidth: adjustedDimensions,
    },
    editIconContainer: {
        borderRadius: 25,
        width: 48,
        backgroundColor: theme.color.tileMainButton,
        height: 48,
        alignItems: 'center',
        justifyContent: 'center',
    },
    editIcon: {
        color: theme.color.textPrimary,
    },
    activeBorderWidth: {
        borderWidth: 3,
        borderColor: theme.color.contrast1,
    },
    adjustedDimensions: {
        width: adjustedDimensions,
        height: adjustedDimensions,
    },
    container: {
        width: adjustedDimensions,
    },
    focusedAvatarContainer: {
        borderColor: theme.color.contrast1,
        borderWidth: 3,
        transition: '0.1s ease-out',
        boxSizing: 'border-box',
    },
    unfocusedAvatarContainer: {
        borderWidth: 0,
    },
});

export default styles;
