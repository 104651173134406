import React from 'react';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useDimensions } from '@24i/nxg-sdk-quantum';
import TopBarPrimary from '@24i/nxg-sdk-gluons/src/components/navigation/TopBarPrimary';
import getOnboardingHeaderStyles from '../OnboardingWebHeader/styles';
import { OnboardingHeaderProps } from '../types';
import { withSignoutModal } from '../../../../components/SignOut';
import { OnboardingWebHeader } from '../OnboardingWebHeader';
import { shouldDisplayDesktopVersion } from '../../../../utils/styles/helpers';
import { OnboardingWebHeaderProps } from '../OnboardingWebHeader/types';

const OnboardingHeader = (props: OnboardingHeaderProps | OnboardingWebHeaderProps): JSX.Element => {
    const dimensions = useDimensions();
    const isDesktop = shouldDisplayDesktopVersion(dimensions);

    const { title, setSignoutModalVisible, hasBackButton, navigation } = props;

    if (isDesktop) return <OnboardingWebHeader {...(props as OnboardingWebHeaderProps)} />;

    return (
        <TopBarPrimary
            title={title}
            previous={hasBackButton}
            onClose={(): void => setSignoutModalVisible(true)}
            navigation={navigation}
        />
    );
};

export default withSignoutModal(overridable(OnboardingHeader, 'OnboardingHeader'));
export { getOnboardingHeaderStyles };
