import { getFont, getMargins, getBorderRadius } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { Breakpoint } from '../../../utils/styles/constants';
import { SearchScreenStyles } from '../types';

const PageSearch = (theme: Theme): SearchScreenStyles => ({
    app: {
        flex: 1,
        overflow: 'hidden',
    },
    holder: {
        flex: 1,
    },
    searchIcon: {
        marginTop: 16,
        marginRight: 18,
        marginBottom: 16,
        marginLeft: 24,
        overflow: 'visible',
        [`@media (max-width: ${Breakpoint.MD}px)`]: {
            marginTop: 11,
            marginRight: 14,
            marginBottom: 11,
            marginLeft: 16,
        },
    },
    clearIcon: {
        marginTop: 16,
        marginRight: 24,
        marginBottom: 16,
        marginLeft: 18,
        overflow: 'visible',
        [`@media (max-width: ${Breakpoint.MD}px)`]: {
            marginTop: 11,
            marginRight: 16,
            marginBottom: 11,
            marginLeft: 14,
        },
    },
    textInput: {
        backgroundColor: theme.color.lighter4,
        borderRadius: 32,
        ...getBorderRadius(theme.elements, 'searchField-cornerRadius'),
        width: '100%',
        maxWidth: 720,
        overflow: 'hidden',
        height: 64,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        alignSelf: 'center',
        transition: '0.35s linear',
        marginTop: 24,
        marginBottom: 48,
        [`@media (max-width: ${Breakpoint.MD}px)`]: {
            marginBottom: 24,
        },
    },
    dropdownContainer: {
        alignSelf: 'center',
        width: '100%',
        maxWidth: 720,
        zIndex: 99,
        [`@media (max-width: ${Breakpoint.MD}px)`]: {
            width: '90%',
        },
    },
    inputWrap: {
        paddingRight: 20,
        paddingLeft: 20,
        borderBottomWidth: 2,
        borderBottomColor: theme.color.lighter3,
        width: '100%',
        backgroundColor: theme.color.darkerBlur1?.color,
    },
    inputStyle: {
        ...getFont(theme, 'h3'),
        borderRadius: 0,
        ...getBorderRadius(theme.elements, 'searchField-cornerRadius'),
        color: theme.color.textPrimary,
        height: 64,
        width: '100%',
        backgroundColor: 'transparent',
        textAlign: 'left',
        border: 'none',
        [`@media (max-width: ${Breakpoint.MD}px)`]: {
            height: 46,
        },
    },
    logoHolder: {
        marginTop: 14,
        zIndex: 99,
        ...getMargins('padding'),
    },
    logoImage: {
        width: 72,
        height: 42,
    },
    suggestedSearchContainer: {
        flexDirection: 'row',
    },
    topContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.color.darkerBlur1?.color,
    },
    subheaderText: {
        color: theme.color.textPrimary,
        opacity: 0.6,
        ...getFont(theme, 'h5'),
        minWidth: 70,
        marginRight: 10,
    },
    suggestedSearchText: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'h5'),
        marginLeft: 5,
        marginRight: 5,
        maxWidth: 270,
    },
    verticalBarAfterSuggestedItem: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'h5'),
        marginLeft: 5,
        marginRight: 5,
    },
    indicator: {
        marginTop: 50,
    },
    searchWrapper: {
        overflow: 'hidden',
        zIndex: 99,
        backgroundColor: 'transparent',
        flex: 1,
    },
    headerContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginTop: 17,
        width: '95%',
        ...getMargins(),
        [`@media (max-width: ${Breakpoint.MD}px)`]: {
            display: 'none',
        },
    },
    headerText: {
        fontWeight: 'bold',
        color: theme.color.textPrimary,
        ...getFont(theme, 'h3'),
    },
    searchContentContainer: {
        flex: 1,
        marginBottom: 24,
    },
});

export default PageSearch;
