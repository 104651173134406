import React from 'react';
import StyleSheet from 'react-native-media-query';
import { fonts, registerIconFont } from './registerFont';
import { IconProps } from './types';

const Icon = ({ font, name, color, size, style, testID }: IconProps) => {
    const { ids, styles } = StyleSheet.process(style);
    let iconName = name;
    const iconFont = fonts?.[font] || {};

    const NativeIcon = iconFont?.component;
    if (!NativeIcon?.hasIcon(name)) {
        // throw new Error(`This icon name (${name}) is missing in glyphMap`);
        iconName = '';
    }

    if (!iconName) {
        return null;
    }

    return (
        <NativeIcon
            style={styles}
            dataSet={{ media: ids }}
            name={iconName}
            size={size}
            color={color}
            testID={testID}
        />
    );
};

export { Icon as default, registerIconFont };
