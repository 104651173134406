import { Theme } from '@24i/nxg-sdk-photon';
import { getFont } from '@24i/nxg-sdk-higgs';
import { CommonLayoutStyles } from '../../../types';

const getStyles = (theme: Theme, showingError?: boolean): CommonLayoutStyles => ({
    input: {
        marginTop: 40,
        width: 335,
        height: 48,
        backgroundColor: theme.color.lighter3,
        borderRadius: 24,
        paddingHorizontal: 24,
        color: `${theme.color.textPrimary}`,
        ...getFont(theme, 'body1'),
    },
    inputOnError: { borderWidth: 1, borderColor: theme.color.error },
    loader: { marginTop: 12, height: 52, justifyContent: 'center' },
    sendButtonContainer: {
        marginTop: showingError ? 15 : 40,
        marginLeft: 12,
        width: 335,
        height: 52,
    },
    instructionText: {
        marginTop: 24,
        marginBottom: 10,
        width: 335,
        height: 40,
        marginHorizontal: 24,
        alignItems: 'center',
        color: `${theme.color.textPrimary}99`,
        textAlign: 'center',
        ...getFont(theme, 'body2'),
    },
    errorText: { height: 17, marginTop: 8, color: theme.color.error, ...getFont(theme, 'h5') },
});

export default getStyles;
