import React, { useMemo } from 'react';
import EPGContainer from '@24i/nxg-sdk-epg/index.web';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { ActivityIndicator, View } from '@24i/nxg-sdk-quarks';
import { useTranslation } from 'react-i18next';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { dayText } from '@24i/nxg-core-utils';
import DropdownPicker from '@24i/nxg-sdk-gluons/src/components/inputs/DropdownPicker/';
import { defaultGetLandscapeStyles } from '../styles/landscape';
import config from '../config';
import { WebEpgViewProps } from './types';
import getEpgScreenStyles from '../styles';

// const ONE_DAY_IN_MS = 86400000;

const EpgScreen = (props: WebEpgViewProps) => {
    const {
        data,
        selectedDate,
        onScroll,
        scrollLeft,
        scrollTop,
        pickerDates,
        isLoading,
        isFetching,
        is12HourClock,
        shouldBeLocked,
        onSelectionPress,
        getLandscapeStyles = defaultGetLandscapeStyles,
        onCellPress,
        onWebViewportScroll,
        getStyles = getEpgScreenStyles,
    } = props;
    const { theme } = useTheme();
    const { t } = useTranslation();
    const styles = getStyles(theme);

    const getDatePickerText = (datePickerOption: string | string[]) => {
        const day = t(`date.${datePickerOption[0]}`).substring(0, 3);
        const month = t(`date.${datePickerOption[1]}`).substring(0, 3);
        const year = datePickerOption[2];

        return datePickerOption instanceof Array
            ? `${day}, ${month} ${year}`
            : t(`date.${datePickerOption}`);
    };

    // const handleDayPass = async () => {
    //     const selectedDay = selectedDate.date.getDate();
    //     const yesterday = new Date(Date.now() - ONE_DAY_IN_MS).getDate();
    //     if (yesterday === selectedDay) {
    //         setSelectedDate({
    //             ...selectedDate,
    //             date: new Date(Date.now()),
    //         });
    //     }
    // };

    // useEffect(() => {
    //     const tomorrow = new Date(Date.now() + ONE_DAY_IN_MS);
    //     tomorrow.setHours(0, 0, 1);
    //     const msToTomorrow = tomorrow.getTime() - Date.now();
    //     const timer = setTimeout(handleDayPass, msToTomorrow);
    //     return () => clearTimeout(timer);
    // }, []);

    const dates = useMemo(
        () =>
            pickerDates.map((day) => {
                const text = dayText(day);
                return getDatePickerText(text);
            }),
        [new Date().getDay(), pickerDates]
    );

    return (
        <View style={styles.container}>
            {data && (
                <>
                    <View style={styles.header}>
                        <DropdownPicker
                            options={dates}
                            selectedItem={getDatePickerText(selectedDate.name)}
                            onSelectionPress={onSelectionPress}
                            icon={theme.icons.epg.calendar}
                        />
                    </View>
                    <EPGContainer
                        selectedDate={selectedDate.date}
                        viewConfig={config}
                        data={data}
                        isLoading={isLoading}
                        isFetching={isFetching}
                        onScroll={onScroll}
                        scrollLeft={scrollLeft}
                        onWebViewportScroll={onWebViewportScroll}
                        scrollTop={scrollTop}
                        landscapeTheme={getLandscapeStyles(theme, props)}
                        shouldBeLocked={shouldBeLocked}
                        lockedContentTitle={t('asset.blocked.title')}
                        onPressCell={onCellPress}
                        is12HourClock={is12HourClock}
                        currentTimeLabelText={t('date.now')}
                        loadingIndicator={() => (
                            <ActivityIndicator
                                holderStyles={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flex: 1,
                                }}
                                color={theme.color.textPrimary}
                                size="large"
                            />
                        )}
                    />
                </>
            )}
        </View>
    );
};

export default overridable(EpgScreen, 'EpgScreen');
