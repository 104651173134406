import { useEffect } from 'react';
import { NavigationContainerRef } from '@react-navigation/native';
import { v4 as uuid } from 'uuid';
import { PlaybackEventType } from '@24i/nxg-sdk-photon/src/analytics/player';
import { StackParamList } from '@24i/nxg-sdk-smartott/src/navigation/types';
import { useMonitoring } from '@24i/nxg-sdk-smartott-shared/src/context/MonitoringData';
import { SOTT_DEFAULT_SCREENS } from '@24i/nxg-sdk-smartott/src/navigation/constants';
import { useScreenChangeListener } from '../../../hooks/useScreenChangeListener';

let playerSessionId: string | undefined;
let event: Omit<PlaybackEventType, 'type'> | undefined;

const setEvent = (eventToSet: Omit<PlaybackEventType, 'type'>) => {
    event = eventToSet;
};

export const usePlayerSessionId = (navigation?: NavigationContainerRef<StackParamList>) => {
    const { currentPage } = useScreenChangeListener(navigation);
    const { triggerMonitoringEvent } = useMonitoring();

    useEffect(() => {
        const page = currentPage?.name;
        if (page === SOTT_DEFAULT_SCREENS.PLAYBACK && event && !playerSessionId) {
            playerSessionId = uuid();
            triggerMonitoringEvent({ ...event, playerSessionId, type: 'SESSION_START' });
        } else if (page !== SOTT_DEFAULT_SCREENS.PLAYBACK && event && playerSessionId) {
            triggerMonitoringEvent({ ...event, playerSessionId, type: 'SESSION_CLOSE' });
            playerSessionId = undefined;
        }
    }, [currentPage?.name, !!event]);

    return { playerSessionId, setEvent };
};
