import React from 'react';
import Collection from 'react-virtualized/dist/commonjs/Collection';
import {
    TIME_24H_30_MIN_INTERVALS,
    TIME_12H_30_MIN_INTERVALS,
} from '@24i/nxg-core-utils/src/constants';
import { formattedTime } from '../../../../utils/DateHelpers';
import { View, Text } from '@24i/nxg-sdk-quarks';
import getStyles from './styles';
import { TimelineProps, CellRendererProps, CellGetterProps } from './types';
import IconButton from '@24i/nxg-sdk-gluons/src/components/buttons/IconButton';

const Timeline = (props: TimelineProps) => {
    const {
        currentTime = new Date(),
        scrollLeft = null,
        showCurrentTime = false,
        timeLabelPosition = 0,
        timelineRef,
        theme,
        width,
        currentTimeLabelText,
        onScrollLeftPress = () => {},
        onScrollRightPress = () => {},
        is12HourClock,
        showLeftArrow,
        showRightArrow,
    } = props;
    const styles = getStyles(theme);
    const hours = is12HourClock ? TIME_12H_30_MIN_INTERVALS : TIME_24H_30_MIN_INTERVALS;

    const cellRenderer = (cellProps: CellRendererProps) => {
        const { index, key, style } = cellProps;

        return index === hours.length ? (
            <div key={key}>
                <View
                    style={{
                        ...styles.currentTimeLabelContainer,
                        left:
                            timeLabelPosition -
                            theme.currentTime.label.width / 2 +
                            theme.currentTime.line.width / 2,
                    }}
                >
                    <Text style={{ ...styles.currentTimeLabelText }}>
                        {currentTimeLabelText || formattedTime(is12HourClock, currentTime)}
                    </Text>
                </View>
            </div>
        ) : (
            <View
                pointerEvents="none"
                key={key}
                style={{
                    ...style,
                    ...styles.columnContainer,
                }}
            >
                <Text
                    style={{
                        ...styles.text,
                        ...theme.timeline.text,
                    }}
                >
                    {hours[index]}
                </Text>
                <Text
                    style={{
                        ...styles.text,
                        ...theme.timeline.hoursLine,
                    }}
                >
                    |
                </Text>
            </View>
        );
    };

    const cellSizeAndPositionGetter = (cellProps: CellGetterProps) => {
        const { index } = cellProps;

        return index === hours.length
            ? {
                  height: 20,
                  width: 30,
                  x: timeLabelPosition,
                  y: 0,
              }
            : {
                  height: theme.timeline.container.height,
                  width: 30 * theme.PIXELS_PER_MIN,
                  x: index * 30 * theme.PIXELS_PER_MIN,
                  y: 0,
              };
    };

    return (
        <View>
            <Collection
                style={styles.collection}
                cellCount={showCurrentTime ? hours.length + 1 : hours.length}
                cellRenderer={cellRenderer}
                cellSizeAndPositionGetter={cellSizeAndPositionGetter}
                height={theme.timeline.container.height}
                width={width}
                timeLabelPosition={timeLabelPosition}
                scrollLeft={scrollLeft}
                verticalOverscanSize={0}
                horizontalOverscanSize={0}
                ref={timelineRef}
            />
            <View
                style={{
                    ...theme.divider,
                    width: width + theme.sidebar.container.width,
                }}
            ></View>
            <View style={{ ...styles.arrowsWrapper, width }}>
                {showLeftArrow && (
                    <IconButton
                        icon={{
                            iconFont: 'materialIcons',
                            iconName: 'chevron-left',
                        }}
                        additionalIconStyles={styles.arrows}
                        additionalContainerStyles={styles.leftArrowContainer}
                        onPress={onScrollLeftPress}
                        iconSize={30}
                    />
                )}
                {showRightArrow && (
                    <IconButton
                        icon={{
                            iconFont: 'materialIcons',
                            iconName: 'chevron-right',
                        }}
                        onPress={onScrollRightPress}
                        additionalIconStyles={styles.arrows}
                        additionalContainerStyles={styles.rightArrowContainer}
                        iconSize={30}
                    />
                )}
            </View>
        </View>
    );
};

export default Timeline;
