import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { getBorderRadius, getFont, Ratio, useTheme } from '@24i/nxg-sdk-higgs';
import { DeviceInfo } from '@24i/nxg-sdk-quantum';
import {
    FlatList,
    Icon,
    Image,
    Interactable,
    SafeAreaView,
    ScrollView,
    Text,
    TextInput,
    View,
} from '@24i/nxg-sdk-quarks';
import React, { useRef } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FlatGrid } from 'react-native-super-grid';
import { isFactorMobile, isPlatformWeb } from 'renative';
import { getBottomBarHeight } from '../../../utils';
import LoadingWrapper from '../../LoadingWrapper';
import getEditProfileStyles from '../styles';
import { CommonProps, Image as ImageType } from '../types';

const noop = () => {};

export interface NewProfileProps extends WithTranslation, CommonProps {
    image: ImageType | null;
    onBack: () => void;
    // It is undefined on imageId and null on age... I am not sure if this is intentional (I don't think so, but...) so I am leaving it
    onSavePress: (newName: string, imageId: string | undefined, age: number | null) => void;
}

const NewProfile = ({
    t,
    images,
    isLoading,
    categories,
    image = null,
    age = null,
    name = '',
    isFocused = false,
    isKidProfile = false,
    onBack = noop,
    styles: getStyles = getEditProfileStyles,
    onIsKidProfilePress = noop,
    onAgeCategoryPress = noop,
    onImagePress = noop,
    onNameChange = noop,
    onSavePress = noop,
    onTextInputFocus = noop,
}: NewProfileProps) => {
    const flatListRef = useRef<typeof FlatList>(null);

    const { theme } = useTheme();
    const styles = getStyles(theme);

    const goIndex = (wantedIndex) => {
        flatListRef.current?.scrollToIndex({
            animated: true,
            index: wantedIndex,
            viewPosition: 0.5,
        });
    };

    return (
        <View style={{ height: '100%' }}>
            <ScrollView
                style={{
                    backgroundColor: theme.color.background1,
                }}
                contentContainerStyle={[
                    (isFactorMobile || DeviceInfo.isTablet()) && {
                        paddingBottom: getBottomBarHeight(),
                    },
                    styles.scrollContainer,
                ]}
            >
                <SafeAreaView style={styles.screenContainer}>
                    <View style={styles.contentContainer}>
                        <View
                            style={{
                                backgroundColor: isFocused
                                    ? theme.color.lighter1
                                    : theme.color.lighter4,
                                borderRadius: Ratio(25),
                                ...getBorderRadius(theme.elements, 'textField-cornerRadius'),
                                paddingLeft: Ratio(24),
                            }}
                            onFocus={() => onTextInputFocus(true)}
                            onBlur={() => onTextInputFocus(false)}
                        >
                            <TextInput
                                style={styles.textInput}
                                placeholder={t('profiles.name.label') as string}
                                placeholderTextColor={`${theme.color.textPrimary}4D`}
                                selectionColor={`${theme.color.textPrimary}66`}
                                autoCapitalize="words"
                                autoCorrect={false}
                                onChangeText={(input) => onNameChange(input)}
                            />
                        </View>
                        <View style={styles.profileImageContainer}>
                            <Text style={styles.profileImageText}>
                                {t('profiles.avatar.label')}
                            </Text>
                            <FlatList
                                data={images}
                                horizontal
                                ref={flatListRef}
                                showsHorizontalScrollIndicator={false}
                                initialScrollIndex={0}
                                renderItem={({ item, index }) => (
                                    <Interactable
                                        onPress={() => {
                                            onImagePress(item);
                                            goIndex(index);
                                        }}
                                    >
                                        <Image
                                            resizeMode="contain"
                                            style={[
                                                {
                                                    marginRight:
                                                        images.length !== index + 1 ? Ratio(15) : 0,

                                                    borderWidth:
                                                        item?.id === image?.id ? Ratio(3) : 0,
                                                    borderColor: theme.color.textPrimary,
                                                },
                                                styles.additionalProfileImageContainer,
                                            ]}
                                            source={{
                                                uri: item.images.avatar[0].url,
                                            }}
                                        />
                                    </Interactable>
                                )}
                            />
                        </View>
                        <View>
                            <View style={styles.ageCategoryContainer}>
                                <Text style={styles.ageCategoryText}>
                                    {t('manageProfiles.kid')}
                                </Text>
                                <Interactable
                                    onPress={() => {
                                        onIsKidProfilePress(!isKidProfile);
                                        onAgeCategoryPress(
                                            isKidProfile
                                                ? categories[categories.length - 1].value
                                                : categories[0].value
                                        );
                                    }}
                                >
                                    <View
                                        style={[
                                            {
                                                backgroundColor: isKidProfile
                                                    ? theme.color.buttonPrimary
                                                    : 'transparent',
                                            },
                                            styles.additonalAgeCategory,
                                        ]}
                                    >
                                        {isKidProfile && (
                                            <Icon
                                                font={theme.icons.settings.check?.iconFont}
                                                name={theme.icons.settings.check.iconName}
                                                size={Ratio(20)}
                                                color={theme.color.textButtonPrimary}
                                            />
                                        )}
                                    </View>
                                </Interactable>
                            </View>
                            {isKidProfile && (
                                <>
                                    <View style={{ marginBottom: Ratio(48) }}>
                                        <Text style={styles.ageCategoryDescriptionText}>
                                            {t('manageProfiles.kidDescription')}
                                        </Text>

                                        <FlatGrid
                                            itemDimension={Ratio(400)}
                                            // eslint-disable-next-line react/prop-types
                                            data={categories.filter(
                                                (member, index) => index !== categories.length - 1
                                            )}
                                            spacing={0}
                                            contentContainerStyle={{
                                                marginTop: Ratio(15),
                                            }}
                                            renderItem={({ item }) => (
                                                <Interactable
                                                    onPress={() => {
                                                        // eslint-disable-next-line no-unused-expressions
                                                        if (isKidProfile)
                                                            onAgeCategoryPress(item.value);
                                                    }}
                                                    activeOpacity={!isKidProfile ? 1 : 0.2}
                                                >
                                                    <View
                                                        style={[
                                                            styles.selectionAgeCategory,
                                                            {
                                                                marginLeft: isPlatformWeb
                                                                    ? Ratio(15)
                                                                    : 0,
                                                                opacity:
                                                                    age === item.value ? 1 : 0.4,
                                                            },
                                                        ]}
                                                    >
                                                        <View
                                                            style={{
                                                                width: Ratio(22),
                                                                marginRight: Ratio(19),
                                                                marginLeft: isPlatformWeb
                                                                    ? Ratio(15)
                                                                    : 0,
                                                            }}
                                                        >
                                                            <Icon
                                                                font={
                                                                    theme.icons.settings.check
                                                                        ?.iconFont
                                                                }
                                                                name={
                                                                    theme.icons.settings.check
                                                                        .iconName
                                                                }
                                                                size={Ratio(22)}
                                                                color={theme.color.contrast1}
                                                                style={{
                                                                    opacity:
                                                                        age === item.value ? 1 : 0,
                                                                }}
                                                            />
                                                        </View>
                                                        <Text
                                                            style={[
                                                                {
                                                                    color: theme.color.textPrimary,
                                                                },
                                                                styles.additonalPreferenceOfAge,
                                                            ]}
                                                        >
                                                            {item.title}
                                                        </Text>
                                                        <View
                                                            style={[
                                                                {
                                                                    backgroundColor:
                                                                        theme.color.contrast3,
                                                                },
                                                                styles.additionalAgeValueContainer,
                                                            ]}
                                                        >
                                                            <Text
                                                                style={{
                                                                    color: theme.color
                                                                        .textSecondary,
                                                                }}
                                                            >
                                                                {item.age}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                </Interactable>
                                            )}
                                        />
                                    </View>
                                </>
                            )}
                        </View>
                    </View>

                    <LoadingWrapper
                        wrapperStyle={{
                            alignSelf: 'center',
                        }}
                        isLoading={isLoading}
                        loaderProps={{
                            holderStyles: styles.activityIndicatorContainer,
                        }}
                    >
                        <ActionButton
                            disabled={name === ''}
                            onPress={() => {
                                onSavePress(name, image?.id, age);
                            }}
                            additionalContainerStyles={[
                                styles.additionalSaveButtonContainer,
                                { opacity: name === '' ? 0.2 : 1 },
                            ]}
                            additionalTextStyles={{ marginLeft: 0 }}
                            title={t('common.save')}
                        />
                    </LoadingWrapper>

                    {isPlatformWeb && (
                        <Interactable
                            style={{
                                alignSelf: 'center',
                            }}
                            onPress={() => {
                                onBack();
                            }}
                        >
                            <Text
                                style={{
                                    color: theme.color.textPrimary,
                                    ...getFont(theme, 'tertiaryButton'),
                                }}
                            >
                                Go back
                            </Text>
                        </Interactable>
                    )}
                </SafeAreaView>
            </ScrollView>
        </View>
    );
};

const LocalizedNewProfile = withTranslation(['sott'])(NewProfile);

export default overridable(LocalizedNewProfile, 'NewProfile');
