import React from 'react';
import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { ActivityIndicator, ScrollView, Text, View } from '@24i/nxg-sdk-quarks';
import { useTranslation } from 'react-i18next';
import PinInput from '@24i/nxg-sdk-smartott/src/screens/SignupScreen/components/SignupInput';
import Wrapper from '@24i/nxg-sdk-smartott/src/screens/SignupScreen/View/Wrapper';
import getSignupScreenStyles from '@24i/nxg-sdk-smartott/src/screens/SignupScreen/styles';
import { isPlatformIos } from 'renative';
import { checkCorrectPin } from '@24i/nxg-core-utils';
import getCreatePinScreenStyles from '../styles';
import { CreatePinViewProps, CREATE_PIN_TEST_IDS } from './types';
import OnboardingHeader from '../../../navigation/components/OnboardingHeader';

const CreatePinScreen = ({
    pin = '',
    isPinValidated = false,
    pinLength = 4,
    isLoading = false,
    onPinChange = () => {},
    onConfirmPress,
    wrapperProps = {
        behavior: isPlatformIos ? 'padding' : undefined,
        keyboardVerticalOffset: 25,
    },
}: CreatePinViewProps) => {
    const { theme } = useTheme();
    const styles = {
        ...getSignupScreenStyles(theme),
        ...getCreatePinScreenStyles(theme),
    };
    const { t } = useTranslation(['sott']);
    const isNotEmpty = pin !== '';
    const disabledOpacity = theme.backgroundImage ? 0.7 : 0.4;
    const confirmButtonOpacity = !isPinValidated ? disabledOpacity : 1;

    return (
        <Wrapper styles={styles} theme={theme} wrapperProps={wrapperProps}>
            <ScrollView keyboardShouldPersistTaps="always" scrollEnabled={false}>
                <OnboardingHeader
                    title={t('pin.add.title')}
                    wrapperStyle={styles.onboardingHeaderWrapper}
                />
                <View style={styles.screenContent}>
                    <View>
                        <Text style={styles.description1} testID="createPinDescription1">
                            {t('pin.add.description')}
                        </Text>
                        <PinInput
                            testID={CREATE_PIN_TEST_IDS.PIN_INPUT}
                            additionalContainerStyles={[
                                styles.inputContainer,
                                styles.passwordInputContainer,
                            ]}
                            activeTextInputColor={theme.color.lighter1}
                            inactiveTextInputColor={theme.color.lighter3}
                            additionalTextInputStyles={styles.signupInputText}
                            secureTextStyles={isNotEmpty && styles.secureText}
                            placeholder={t('pin.manage.title')}
                            placeholderTextColor={`${theme.color.textPrimary}4D`}
                            selectionColor={`${theme.color.textPrimary}66`}
                            isPasswordField
                            addAdditionalAction
                            additionalActionContainerStyles={styles.actionContainer}
                            additionalActionTextStyles={styles.actionText}
                            value={pin}
                            onChangeText={(input) => onPinChange(input)}
                            maxPasswordLength={pinLength}
                            blurOnSubmit={false}
                            keyboardType="numeric"
                            onKeyPress={checkCorrectPin}
                            autoFocus
                        />
                        <Text style={styles.description2} testID="createPinDescription2">
                            {t('pin.add.formatValidation')}
                        </Text>
                        {isLoading ? (
                            <View style={styles.loading}>
                                <ActivityIndicator size="small" color={theme.color.textPrimary} />
                            </View>
                        ) : (
                            <ActionButton
                                testID={CREATE_PIN_TEST_IDS.CONFIRM_BUTTON}
                                title={t('common.confirm')}
                                onPress={() => onConfirmPress(pin)}
                                additionalContainerStyles={[
                                    {
                                        opacity: confirmButtonOpacity,
                                    },
                                    styles.signupButtonContainer,
                                ]}
                                additionalTextStyles={styles.signButtonText}
                                disabled={!isPinValidated}
                            />
                        )}
                    </View>
                </View>
            </ScrollView>
        </Wrapper>
    );
};

export default overridable(CreatePinScreen, 'CreatePinScreen');
