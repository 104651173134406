import React, { memo, useState, useEffect } from 'react';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Text, View } from '@24i/nxg-sdk-quarks';
import { Animated, Easing, Platform } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { CustomItemTitleProps } from './types';
import { PACKSHOT_TEST_IDS } from '../../test-utils';
import { getCustomItemTitleStyles } from './styles';
import { getNumberOfLines } from './utils';

const MARGINWIDTH = 3;
const MARGINHEIGHT = 1;

const animation = (element, toValue) =>
    Animated.timing(element, {
        toValue,
        duration: 150,
        delay: 100,
        useNativeDriver: true,
        easing: Easing.ease,
    });

const CustomItemTitle = (props: CustomItemTitleProps) => {
    const {
        styles: getStyles = getCustomItemTitleStyles,
        subtitle = '',
        textWidth,
        title = '',
        testID = '',
        infoTestId,
        isHighlighted,
        displayOver,
        orientation,
        imageStyles = { height: 0, width: 0 },
    } = props;

    const isWeb = Platform.OS === 'web';
    const [fadeAnim] = useState(new Animated.Value(Platform.isTV ? 0 : 1));
    useEffect(() => animation(fadeAnim, 1).start(), []);

    const { theme } = useTheme();
    const styles = getStyles(theme, textWidth);

    const titleContainerStyles = displayOver ? styles.textContainer : styles.textWrapper;
    const titleStyles = displayOver ? styles.titleOver : styles.titleBelow;
    const subtitleStyles = displayOver ? styles.customItemSubtitle : styles.itemTextSubs;

    const renderContent = () => (
        <Animated.View
            testID={testID}
            style={[
                titleContainerStyles,
                {
                    opacity: fadeAnim,
                    scaleY: fadeAnim,
                },
            ]}
        >
            {!!title && (
                <View style={styles.titleContainer}>
                    <Text
                        style={titleStyles}
                        allowFontScaling={false}
                        numberOfLines={getNumberOfLines(
                            orientation,
                            displayOver,
                            isHighlighted,
                            subtitle.length
                        )}
                        testID={`${infoTestId}_${PACKSHOT_TEST_IDS.TITLE}`}
                    >
                        {title}
                    </Text>
                </View>
            )}
            {!!subtitle && (
                <Text
                    testID={`${infoTestId}_${PACKSHOT_TEST_IDS.METADATA}`}
                    style={subtitleStyles}
                    numberOfLines={getNumberOfLines(
                        orientation,
                        displayOver,
                        isHighlighted,
                        subtitle.length
                    )}
                    allowFontScaling={false}
                >
                    {subtitle}
                </Text>
            )}
        </Animated.View>
    );

    if (isWeb || !displayOver) {
        return renderContent();
    }

    return (
        <LinearGradient
            colors={theme.color.gradient2.colors}
            style={[
                styles.gradientWrapper,
                {
                    margin: MARGINWIDTH,
                    width: imageStyles.width - MARGINWIDTH,
                    height: imageStyles.height + MARGINHEIGHT,
                },
            ]}
        >
            {renderContent()}
        </LinearGradient>
    );
};

export default memo(CustomItemTitle);
