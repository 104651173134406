import { Link } from '@24i/nxg-core-router/src';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Interactable } from '@24i/nxg-sdk-quarks';
import { InteractableProps } from '@24i/nxg-sdk-quarks/src/components/Interactable/types';
import React, { FC } from 'react';
import IconContent from '../components/IconContent';
import TextContent from '../components/TextContent';
import { defaultWebStyles } from '../styles';
import { IconButtonWebLinkProps, IconButtonWebProps } from '../types';

const withLink = (
    children: React.ReactNode,
    linkProps: IconButtonWebLinkProps,
    pressProps: InteractableProps
) => (
    <Link {...linkProps}>
        <Interactable {...pressProps}>{children}</Interactable>
    </Link>
);

const IconButton: FC<IconButtonWebProps> = ({
    gradientParams = null,
    isSelected = true,
    onPress,
    title = '',
    icon = {
        iconFont: 'materialIcons',
        iconName: null,
        iconImage: null,
        iconStyle: null,
    },
    styles: getStyles = defaultWebStyles,
    color,
    iconSize,
    disabled = false,
    isOptionSelected,
    subtitle,
    additionalTitleStyles,
    additionalSubtitleStyles,
    additionalContainerStyles,
    additionalTextStyles,
    additionalTextContainerStyles,
    titleNumberOfLines,
    subtitleNumberOfLines,
    additionalIconStyles,
    linkProps,
    priceTag,
    currentPlanLabel,
    isCurrentPlan,
    testID,
    ...props
}) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);

    const { iconName, iconImage } = icon;

    const withMainWrapper = (children: React.ReactNode) => (
        <Interactable
            testID={testID}
            disabled={disabled}
            onPress={onPress}
            // @ts-ignore: onMouseEnter and onMouseLeave props are not available in TouchableOpacity (Interactable uses)
            // onMouseEnter={onFocus}
            style={[
                styles.holder,
                (!iconName && !iconImage) || !isSelected ? styles.holderWithoutIcon : {},
                additionalContainerStyles,
                isOptionSelected ? styles.holderSelected : {},
                disabled ? styles.holderDisabled : {},
            ]}
            {...props}
        >
            {children}
        </Interactable>
    );

    const withGradient = (children: React.ReactNode) => children;

    const buttonContent = (
        <>
            <IconContent
                iconContainerStyle={{
                    ...styles.iconSelectedContainer,
                    ...styles.iconContainer,
                }}
                iconStyle={[styles.icon, additionalIconStyles]}
                iconColor={color || styles.text?.color || theme?.color?.textPrimary}
                isSelected={isSelected}
                iconSize={iconSize || 25}
                icon={icon}
            />
            {title && title.length > 0 ? (
                <TextContent
                    title={title}
                    titleNumberOfLines={titleNumberOfLines}
                    titleContainerStyle={[styles.innerHolder, additionalTextContainerStyles]}
                    titleStyle={[
                        styles.text,
                        additionalTextStyles,
                        additionalTitleStyles,
                        color ? { color } : {},
                        isOptionSelected && styles.textSelected,
                    ]}
                    subtitle={subtitle}
                    subtitleNumberOfLines={subtitleNumberOfLines}
                    subtitleStyle={[
                        styles.subtitle,
                        additionalSubtitleStyles,
                        color ? { color } : {},
                    ]}
                    priceTag={priceTag}
                    currentPlanLabel={currentPlanLabel}
                    isCurrentPlan={isCurrentPlan}
                />
            ) : null}
        </>
    );

    const btn = gradientParams
        ? withMainWrapper(withGradient(buttonContent))
        : withMainWrapper(buttonContent);

    return linkProps ? withLink(btn, linkProps, { onPress }) : btn;
};

export default overridable(IconButton, 'IconButton');
